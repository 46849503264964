import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "../../util/IntlMessages";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import { useAuth } from "../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const SubMenu = Menu.SubMenu;

const HorizontalNav = () => {
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ common }) => common.pathname);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];
  const { authUser, isLoadingUser } = useAuth();
  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";
    }
  };

  return (
    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal"
    >
      <SubMenu
        className={getNavStyleSubMenuClass(navStyle)}
        key="main"
        title="Main Section"
      >
        <Menu.Item key="main/dashboard">
          <Link to="/main/dashboard">
            <i className="icon icon-dasbhoard" />
            <IntlMessages id="sidebar.dashboard" />
          </Link>
        </Menu.Item>
        {authUser?.userInfo.role === UserGroupEnum.Admin ||
        authUser?.userInfo.role === UserGroupEnum.EditorInChief ? (
          <>
            <Menu.Item key="main/journalsMangamnet">
              <Link to="/main/journalsMangamnet">
                <i className="icon icon-feedback" />
                <IntlMessages id="sidebar.journalsManagment" />
              </Link>
            </Menu.Item>
          </>
        ) : null}

        {authUser?.userInfo.role === UserGroupEnum.Admin && (
          <>
            <Menu.Item key="main/contents/conference">
              <Link to="/main/contents/conference">
                <i className="icon icon-ckeditor" />
                <IntlMessages id="sidebar.conferences" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/contents/book">
              <Link to="/main/contents/book">
                <i className="icon icon-calendar" />
                <IntlMessages id="sidebar.books" />
              </Link>
            </Menu.Item>
            <Menu.Item key="main/contents/course">
              <Link to="/main/contents/course">
                <i className="icon icon-auth-screen" />
                <IntlMessages id="sidebar.courses" />
              </Link>
            </Menu.Item>
          </>
        )}
        {authUser?.userInfo.role === UserGroupEnum.ConferenceEditor && (
          <>
            <Menu.Item key="main/contents/conference">
              <Link to="/main/contents/conference">
                <i className="icon icon-ckeditor" />
                <IntlMessages id="sidebar.conferences" />
              </Link>
            </Menu.Item>
          </>
        )}
      </SubMenu>

      {authUser?.userInfo.role !== UserGroupEnum.ConferenceEditor && (
        <SubMenu
          className={getNavStyleSubMenuClass(navStyle)}
          key="PublishingManagement"
          title="Publishing Management"
        >
          {authUser?.userInfo.role === UserGroupEnum.Admin && (
            <Menu.Item key="article/articleType">
              <Link to="/article/articleType">
                <i className="icon icon-autocomplete" />
                <IntlMessages id="sidebar.articleType" />
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="article/articleList">
            <Link to="/article/articleList">
              <i className="icon icon-autocomplete" />
              <IntlMessages id="sidebar.articleList" />
            </Link>
          </Menu.Item>
          <Menu.Item key="article/authors">
            <Link to="/article/authors">
              <i className="icon icon-autocomplete" />
              <IntlMessages id="sidebar.authors" />
            </Link>
          </Menu.Item>
          {authUser?.userInfo.role === UserGroupEnum.Assistant && (
            <Menu.Item key="volumeAndIssue/volumes">
              <Link to="/volumeAndIssue/volumes">
                <i className="icon icon-user-o" />
                <IntlMessages id="sidebar.volume" />
              </Link>
            </Menu.Item>
          )}
        </SubMenu>
      )}
      {authUser?.userInfo.role === UserGroupEnum.Admin && (
        <SubMenu
          className={getNavStyleSubMenuClass(navStyle)}
          key="baseData"
          title="Base Data"
        >
          <Menu.Item key="baseData/subjects">
            <Link to="/baseData/subjects">
              <i className="icon icon-autocomplete" />
              <IntlMessages id="sidebar.subjects" />
            </Link>
          </Menu.Item>
          <Menu.Item key="baseData/indicators">
            <Link to="/baseData/indicators">
              <i className="icon icon-data-entry" />
              <IntlMessages id="sidebar.indicators" />
            </Link>
          </Menu.Item>
          <Menu.Item key="baseData/users">
            <Link to="/baseData/users">
              <i className="icon icon-user-o" />
              <IntlMessages id="sidebar.users" />
            </Link>
          </Menu.Item>
          <Menu.Item key="baseData/indexTypes">
            <Link to="/baseData/indexTypes">
              <i className="icon icon-product-grid" />
              <IntlMessages id="sidebar.indexType" />
            </Link>
          </Menu.Item>
          <Menu.Item key="baseData/setting">
            <Link to="/baseData/setting">
              <i className="icon icon-setting" />
              <IntlMessages id="sidebar.setting" />
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {authUser?.userInfo.role === UserGroupEnum.Admin && (
        <SubMenu
          className={getNavStyleSubMenuClass(navStyle)}
          key="websit"
          title="Website"
        >
          <Menu.Item key="website/sliders">
            <Link to="/website/sliders">
              <i className="icon icon-cards-list-view" />
              <IntlMessages id="sidebar.sliders" />
            </Link>
          </Menu.Item>
          <Menu.Item key="website/footerlinks">
            <Link to="/website/footerlinks">
              <i className="icon icon-plain-list-divider" />
              <IntlMessages id="sidebar.footerLinks" />
            </Link>
          </Menu.Item>
          <Menu.Item key="website/webhomedata">
            <Link to="/website/webhomedata">
              <i className="icon icon-product-list" />
              <IntlMessages id="sidebar.webHomeData" />
            </Link>
          </Menu.Item>
          <Menu.Item key="website/journalsListTopic">
            <Link to="/website/journalsListTopic">
              <i className="icon icon-product-list" />
              <IntlMessages id="sidebar.journalsListTopic" />
            </Link>
          </Menu.Item>

          <Menu.Item key="website/bookMenuList">
            <Link to="/website/bookMenuList">
              <i className="icon icon-product-list" />
              <IntlMessages id="sidebar.bookMenuList" />
            </Link>
          </Menu.Item>
          <Menu.Item key="baseData/news">
            <Link to="/baseData/news">
              <i className="icon icon-product-list" />
              <IntlMessages id="sidebar.news" />
            </Link>
          </Menu.Item>
          <Menu.Item key="website/contactUs">
            <Link to="/website/contactUs">
              <i className="icon icon-product-list" />
              <IntlMessages id="sidebar.contactUs" />
            </Link>
          </Menu.Item>
          <Menu.Item key="website/aboutPublisher">
            <Link to="/website/aboutPublisher">
              <i className="icon icon-product-list" />
              <IntlMessages id="sidebar.aboutPublisher" />
            </Link>
          </Menu.Item>
        </SubMenu>
      )}

      {authUser?.userInfo.role === UserGroupEnum.EditorInChief && (
        <SubMenu
          className={getNavStyleSubMenuClass(navStyle)}
          key="users"
          title="Users"
        >
          <Menu.Item key="baseData/users">
            <Link to="/baseData/users">
              <i className="icon icon-user-o" />
              <IntlMessages id="sidebar.assistaneUsers" />
            </Link>
          </Menu.Item>
        </SubMenu>
      )}
      {authUser?.userInfo.role === UserGroupEnum.Admin ||
      authUser?.userInfo.role === UserGroupEnum.Assistant ? (
        <SubMenu className={getNavStyleSubMenuClass(navStyle)} key="filses" title="Files">
          <Menu.Item key="baseData/files">
            <Link to="/baseData/files">
              <i className="icon icon-files" />
              <IntlMessages id="sidebar.files" />
            </Link>
          </Menu.Item>
        </SubMenu>
      ) : null}
    </Menu>
  );
};

HorizontalNav.propTypes = {};

export default HorizontalNav;
