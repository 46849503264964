import React from "react";
import { Route, Switch } from "react-router-dom";
import Main from "./main/index";
import BaseData from "./baseData/index";
import ArticleData from "./article/index";
import VolumeAndIssueData from "./volumeAndIssue/index";
import ProfileData from "./profile/index";
import WebsiteData from "./website/index";
import Messages from "./messages/index";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
    <Route path={`${match.url}main`} component={Main}/>    
    <Route path={`${match.url}baseData`} component={BaseData}/>
    <Route path={`${match.url}article`} component={ArticleData}/>
    <Route path={`${match.url}volumeAndIssue`} component={VolumeAndIssueData}/>
    <Route path={`${match.url}profile`} component={ProfileData} />
    <Route path={`${match.url}website`} component={WebsiteData} />
    <Route path={`${match.url}messages`} component={Messages} />
    </Switch>
  </div>
);

export default App;
