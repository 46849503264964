import React, { useState } from "react";
import { Switch, Table, Modal, Space, Avatar, Image } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useGetJournalIndexTypes } from "src/hooks/services/journals/journalIndexType/useGetJournalIndexTypes";
import { useDeleteJournalIndexType } from "src/hooks/services/journals/journalIndexType/useDeleteJournalIndexType";

const confirm = Modal.confirm;
const { Column } = Table;
const IndexTypeList = (props) => {

  const { data: menuData, isLoading } = useGetJournalIndexTypes(props.journalData.jid);
  const { mutate: onDeleteIndexTypeAssign } = useDeleteJournalIndexType(props.journalData.jid);



  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteIndexTypeAssign(data.journalIndexId, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err:any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() {},
    });
  };

  

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={menuData}
        rowKey="journalIndexId"
      >
        <Column
          title="Image"
          dataIndex="imageUrl"
          key="imageUrl"
          render={(text, record: any) => (
            <Avatar
              shape="square"
              size={80}
              src={<Image src={record.imageUrl} />}
            />
          )}
        />
        <Column title="Title" dataIndex="title" key="title" />

        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default IndexTypeList;
