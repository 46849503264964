import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { ArticlesTypeDto } from "src/data/models/articleDtos/articlesTypeDto.interface";

import { container } from "tsyringe";

export const useEditArticleType = () => {
    const articlesService = container.resolve(ArticlesService);
    const queryClient = useQueryClient();
    const toggleEdit = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<ArticlesTypeDto[]>([Api.Articles.getArticleTypes]);
            const findIndex = tempData.findIndex(x=>x.id === data.id) ;
            tempData[findIndex].name = data.name;
            queryClient.setQueryData([Api.Articles.getArticleTypes],tempData )
        },
        [],
    );

    return useMutation({
        mutationFn: (data: ArticlesTypeDto) => {
            return articlesService.editArticleType(data);
        },
        onSuccess: toggleEdit
    })

}
