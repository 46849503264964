import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { SubjectDto } from "src/data/models/subjectDtos/subjectDto.interface";
import BaseRepository from "../Base/BaseRepository";
import ISubjectsRepository from "./ISubjectsRepository";

export default class SubjectsRepository extends BaseRepository implements ISubjectsRepository {

  async getSubjects(): Promise<IGenericResponse<SubjectDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.Subjects.getSubjects)

    return await request
      .GetResultAsync()
  }

  async addSubjects(body: SubjectDto): Promise<IGenericResponse<SubjectDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Subjects.addSubjects)

    return await request
      .GetResultAsync()
  }

  async editSubjects(body: SubjectDto): Promise<IGenericResponse<SubjectDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Subjects.editSubjects)

    return await request
      .GetResultAsync()
  }
  async deleteSubjects(subjectId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.Subjects.deleteSubjects, { subjectId })

    return await request
      .GetResultAsync()
  }
}
