import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
import { useAuth } from "../../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
const TabPane = Tabs.TabPane;

const JournalsPageManagment = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [journalsEditData, setJournalsEditData] = useState(null);
  const [journalsEditFlag, setJournalsEditFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { authUser, isLoadingUser } = useAuth();
  const callback = (key, data = null, currentPage = 1) => {
    setActiveTab(key);
    if (data && key === "2") {
      setJournalsEditData(data);
      setJournalsEditFlag(true);
      setCurrentPage(currentPage);
    } else if (key === "2") {
      setJournalsEditFlag(false);
      setJournalsEditData(null);
    }
  };

  return (
    <Card className="gx-card" title="Journals list">
      {authUser?.userInfo.role === UserGroupEnum.Admin ? (
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="List" key="1">
            <List changeTabEvent={callback} role={authUser?.userInfo.role} />
          </TabPane>
          <TabPane tab="Add / Edit Journals" key="2">
            <Add
              fields={journalsEditData}
              editSection={journalsEditFlag}
              changeTabEvent={callback}
              currentPage={currentPage}
            />
          </TabPane>
        </Tabs>
      ) : (
        <List changeTabEvent={callback} />
      )}
    </Card>
  );
};

export default JournalsPageManagment;
