import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, Card } from "antd";
import { useAddIndicators } from "src/hooks/services/indicators/useAddIndicators";
import { useEditIndicators } from "src/hooks/services/indicators/useEditIndicators";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import Editor from 'src/components/Editor';

const Add = ({ fields, editSection = false ,changeTabEvent}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const [descContent, setDescContent] = useState("");
  const [descContentDefault, setDescContentDefault] = useState("");
  const { mutate: onAddIndicators, isLoading:loadingSave } = useAddIndicators();
  const { mutate: onEditIndicators, isLoading:loadingEdit } = useEditIndicators();

  useEffect(() => {
    if (fields) {
      const finalData = [];
      for (var key in fields) {
        if (fields.hasOwnProperty(key)) {
          let data = {
            name: [],
            value: "",
          };
          data.name.push(key);
          data.value = fields[key];
          finalData.push(data);
        }
      }
      setFormData(finalData);
      if (fields.description) {
        setDescContent(fields.description);
        setDescContentDefault(fields.description);
      }
    }
    return () => {
      form.resetFields();
      setFormData([]);
    };
  }, [fields, editSection]);

  const saveIndicator = (values) => {
    values.description = descContent ;
    if (editSection) {
      values.id = fields.id ;
      onEditIndicators(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      onAddIndicators(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setDescContent("");
          setDescContentDefault("");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveIndicator}
        autoComplete="off"
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description">
          <Editor value={descContentDefault} 
          onChange={ ( data, editor  ) => {
            setDescContent(data);
        } }/>
        </Form.Item>
        <Form.Item>
          <Button loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
