import { useMutation, useQueryClient } from "react-query";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { ArticleListDto } from "src/data/models/articleDtos/articleListDto.interface";

export const useEditArticles = ()  => {
    const articlesService = container.resolve(ArticlesService);
    return useMutation({
        mutationFn: (data: any) => {
            return articlesService.editArticle(data);
        },
    })

}
