import { useMutation } from "react-query";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { AddArticleStatusLogDto } from "src/data/models/articleDtos/addArticleStatusLogDto.interface";

export const useChangeArticleStatus = ()  => {
    const articlesService = container.resolve(ArticlesService);
    return useMutation({
        mutationFn: (data: AddArticleStatusLogDto) => {
            return articlesService.changeArticleStatus(data);
        },
    })

}
