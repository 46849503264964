import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAddIndexTypes } from "src/hooks/services/indexTypes/useAddIndexTypes";
import { useEditIndexTypes } from "src/hooks/services/indexTypes/useEditIndexTypes";

const Add = ({ fields, editSection = false, changeTabEvent }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddIndexTypes, isLoading:loadingSave } = useAddIndexTypes();
  const { mutate: onEditIndexTypes, isLoading:loadingEdit } = useEditIndexTypes();
  const [formData, setFormData] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  useEffect(() => {
    if (fields) {
      const finalData = [];
      for (var key in fields) {
        if (fields.hasOwnProperty(key)) {
          let data = {
            name: [],
            value: "",
          };
          data.name.push(key);
          data.value = fields[key];
          
          finalData.push(data);
        }
      }
      setFormData(finalData);
      var imageFile = [{
        uid: '-1',
        name: 'image',
        status: 'done',
        url: fields.imageUrl,
      }];
      setImageList(imageFile);
    }
    return () => {
      form.resetFields();
      setFormData([]);
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection,form]);

  const saveIndexTypes = (values) => {
    if(imageFile === null && !editSection) {
      return;
    }

    const formData = new FormData();
    formData.append("Image", imageFile);
    formData.append("Title", values.title);
    if (editSection) {
      formData.append("IndexTypeId", fields.id);
      onEditIndexTypes(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {

      onAddIndexTypes(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      var imageFile = [{
        uid: '-2',
        name: 'image',
        status: 'done',
        url: reader.result,
      }];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);

  }

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveIndexTypes"
        onFinish={saveIndexTypes}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input Title!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Image"
          required
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
             {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
