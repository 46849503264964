import { useMutation, useQuery } from "react-query";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { Api } from "src/apis/helpers/configs/api";

export const useGetEditorFiles = () => {
  const baseDataService = container.resolve(BaseDataService);
  return useMutation({
    mutationFn: (page: any) => {
      if (typeof page !== "number") {
        page = 1;
      }

      return baseDataService.getEditorFiles({
        pageNumber: page,
        pageSize: PageSize.tables,
      });
    },
  });
};
