import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, Card } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useEditSetting } from "src/hooks/services/baseData/useEditSetting";
import { useGetSetting } from "src/hooks/services/baseData/useGetSetting";


const Add = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onEditSetting, isLoading:loadingEdit } = useEditSetting();
  const { data: subjectsData, isLoading } = useGetSetting();


  useEffect(() => {
    if(!isLoading) {
      form.setFieldsValue(subjectsData);
    }

  },[subjectsData])

  const editSetting = (values) => {

      onEditSetting(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });

  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        onFinish={editSetting}
        autoComplete="off"
      >
        <Form.Item
          label="Instagram Account Link"
          name="InstagramAccountUsername"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Linkedin Account Link"
          name="LinkedinAccountUsername"
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button loading = {loadingEdit} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
