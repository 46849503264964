import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { ArticlesService } from "src/apis/services/article/articlesService";

export const useGetAuthors = ({page = 1 , fullName , email}) => {
    const articlesService = container.resolve(ArticlesService);
    return useQuery({
        queryKey: [Api.Articles.getAuthors,page,fullName,email],
        queryFn: () => {

            if (typeof page !== 'number') {
                page = 1;
            }

            return articlesService.getAuthors({
                pageNumber: page,
                fullName: fullName,
                email: email,
                pageSize: PageSize.tables
            });
        }
    })
}
