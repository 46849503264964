import { useEffect, useState } from "react";
import {
  Form,
  Button,
} from "antd";
import { toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";

import Editor from 'src/components/Editor';
import { useGetArticleContent } from "src/hooks/services/articles/useGetArticleContent";
import { useEditArticleContent } from "src/hooks/services/articles/useEditArticleContent";


const ArticleContent = ({
  articleId,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { data: articleContent, isLoading: isLoadingContent } = useGetArticleContent(articleId);
  const { mutate: onEditArticleContent, isLoading: loadingEdit } = useEditArticleContent(articleId);
  const [formData, setFormData] = useState([]);
  const [content, setContent] = useState(null);
  const [contentDefault, setContentDefault] = useState(null);

  useEffect(()=>{
    if(articleContent) {
      setContent(articleContent);
      setContentDefault(articleContent);
    }
  },[articleContent]);

  const saveArticleContent = (values) => {
    values.articleContent = content;
    values.articleId = articleId;
    onEditArticleContent(values, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />
        );
      },
      onError: (err) => {
        toast.error(
          <IntlMessages id="notification.errorMessage" />
        );
      },
    });
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;


  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveArticles"
        onFinish={saveArticleContent}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
      
        <Form.Item label="Content"  required>
            <Editor             
            value={contentDefault}
            onChange={ ( data, editor  ) => {
              setContent(data);
          } }/>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loadingEdit}
            type="primary"
            htmlType="submit"
          >
            save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default ArticleContent;
