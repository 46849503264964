import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import SentList from "./Sentlist/List";
import Add from "./add/Add";
import { useAuth } from "../../authentication";
const TabPane = Tabs.TabPane;

const Messages = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const { authUser, isLoadingUser } = useAuth();
  const callback = (key, data = null, currentPage = 1) => {
    setActiveTab(key);
  };

  return (
    <Card className="gx-card" title="Messages list">
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="Incoming Messages" key="1">
            <List changeTabEvent={callback} role={authUser?.userInfo.role} />
          </TabPane>
          <TabPane tab="Messages Sent" key="2">
            <SentList changeTabEvent={callback} role={authUser?.userInfo.role} />
          </TabPane>
          <TabPane tab="Send New Message" key="3">
            <Add
              changeTabEvent={callback}
              role={authUser?.userInfo.role}
            />
          </TabPane>
        </Tabs>
      
    </Card>
  );
};

export default Messages;
