import React from "react";
import { Divider, Table, Modal, Avatar, Image } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useGetSliders } from "src/hooks/services/baseData/useGetSliders";
import { useDeleteSlider } from "src/hooks/services/baseData/useDeleteSlider";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";

const confirm = Modal.confirm;

const List = (props) => {
  const { data: sliders, isLoading } = useGetSliders();

  const { mutate: onDeleteSlider } = useDeleteSlider();

  const columns = [
    {
      title: "Image",
      dataIndex: "urlImage",
      key: "urlImage",
      render: (text, record: any) => (
        <Avatar shape="square" size={80} src={<Image src={record.urlImage} />} />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Info",
      dataIndex: "sliderInfo",
      key: "sliderInfo",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Button Text",
      dataIndex: "buttonText",
      key: "buttonText",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",
      render: (text) => <span><a target={'_blank'} href={text}>{text}</a></span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <span onClick={() => editData(record)} className="gx-link">Edit</span>
          <Divider type="vertical" />
          <span onClick={() => showDeleteConfirm(record)} className="gx-link">
            Delete
          </span>
        </span>
      ),
    },
  ];

  const showDeleteConfirm = (data: SliderDto) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteSlider(data.sliderId, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err) => {
            toast.error(
              <IntlMessages id="notification.errorMessage" />
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const editData = (data) => {

    props.changeTabEvent("2" ,data ); // raftan be tab edit

  }

  return (
    <>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={sliders}
        rowKey="sliderId"
        pagination={false}
      />
       {/* <NotificationContainer /> */}
    </>
  );
};

export default List;
