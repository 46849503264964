import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { AboutPublisherDto } from "src/data/models/settingDtos/aboutPublisherDto.interface";

import { container } from "tsyringe";

export const useEditAboutPublisher = () => {
    const aboutPublisherService = container.resolve(BaseDataService);
    const queryClient = useQueryClient();
    const toggleEdit = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<string>([Api.BaseData.getAboutPublisher]);
            tempData = data;
            queryClient.setQueryData([Api.BaseData.getAboutPublisher],tempData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: AboutPublisherDto) => {
            return aboutPublisherService.editAboutPublisher(data);
        },
        onSuccess: toggleEdit
    })

}
