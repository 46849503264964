import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { ContentTypeEnum } from "src/data/enums/contentType.enum";
import { ContentListDto } from "src/data/models/contentsDtos/contentListDto.interface";
import { MenuListDto } from "src/data/models/contentsDtos/menuListDto.interface";
import { TabsListDto } from "src/data/models/contentsDtos/tabsListDto.interface";
import { TopicsListDto } from "src/data/models/contentsDtos/topicsListDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { contentsPaginationParams } from "src/data/models/params/contentsPaginationParams.interface";
import BaseRepository from "../Base/BaseRepository";
import IContentsRepository from "./IContentsRepository";
import { UserPermissionDto } from "src/data/models/usersDtos/userPermissionDto.interface";
import { AddUserPermissionDto } from "src/data/models/usersDtos/addUserPermissionDto.interface";

export default class ContentsRepository
  extends BaseRepository
  implements IContentsRepository
{
  async getContentList(
    params: contentsPaginationParams
  ): Promise<IGenericResponse<PaginatedList<ContentListDto>>> {
    const result = this.CreateRequestBuilder();

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value);
    }

    const request = result.setMethod("get").setUrl(Api.Contents.getContentList);

    return await request.GetResultAsync();
  }
  async addContents(
    body: ContentListDto
  ): Promise<IGenericResponse<ContentListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Contents.addContent);

    return await request.GetResultAsync();
  }
  async editContents(
    body: ContentListDto
  ): Promise<IGenericResponse<ContentListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Contents.editContent);

    return await request.GetResultAsync();
  }

  async toggleContentsStatus(newsId): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(newsId)
      .setUrl(Api.Contents.toggleContentStatus);

    return await request.GetResultAsync();
  }

  async deleteContents(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setUrl(Api.Contents.deleteContent, { id });

    return await request.GetResultAsync();
  }

  async getContentMenuList(
    id: number,
    isForContent: boolean,
    type: number
  ): Promise<IGenericResponse<MenuListDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .setUrl(Api.Contents.getContentMenuList)
      .addQueryParam("Id", id)
      .addQueryParam("IsForContent", isForContent)
      .addQueryParam("type", type);

    return await request.GetResultAsync();
  }

  async getContentTabList(
    id: number,
    isForContent: boolean
  ): Promise<IGenericResponse<TabsListDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .setUrl(Api.Contents.getContentTabList)
      .addQueryParam("Id", id)
      .addQueryParam("IsForContent", isForContent);

    return await request.GetResultAsync();
  }

  async getContentTopicsList(
    id: number,
    isForContent: boolean
  ): Promise<IGenericResponse<TopicsListDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .setUrl(Api.Contents.getContentTopicsList)
      .addQueryParam("Id", id)
      .addQueryParam("IsForContent", isForContent);

    return await request.GetResultAsync();
  }

  async addMenuList(body: MenuListDto): Promise<IGenericResponse<MenuListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Contents.addMenuList);

    return await request.GetResultAsync();
  }

  async addTabList(body: TabsListDto): Promise<IGenericResponse<TabsListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Contents.addTabList);

    return await request.GetResultAsync();
  }
  async addTopicList(
    body: TopicsListDto
  ): Promise<IGenericResponse<TopicsListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Contents.addTopicList);

    return await request.GetResultAsync();
  }

  async editMenuList(
    body: MenuListDto
  ): Promise<IGenericResponse<MenuListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Contents.editMenuList);

    return await request.GetResultAsync();
  }

  async editTabList(body: TabsListDto): Promise<IGenericResponse<TabsListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Contents.editTabList);

    return await request.GetResultAsync();
  }

  async editTopicList(
    body: TopicsListDto
  ): Promise<IGenericResponse<TopicsListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Contents.editTopicList);

    return await request.GetResultAsync();
  }

  async deleteMenuList(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setUrl(Api.Contents.deleteMenuList, { id });

    return await request.GetResultAsync();
  }

  async deleteTabList(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setUrl(Api.Contents.deleteTabList, { id });

    return await request.GetResultAsync();
  }

  async deleteTopicList(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setUrl(Api.Contents.deleteTopicList, { id });

    return await request.GetResultAsync();
  }

  async getAllContents(
    type: ContentTypeEnum
  ): Promise<IGenericResponse<ContentListDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .setUrl(Api.Contents.getAllContents)
      .addQueryParam("ContentType", type);

    return await request.GetResultAsync();
  }

  async getContentUserPermissions(
    contentId: number
  ): Promise<IGenericResponse<UserPermissionDto[]>> {
    const result = this.CreateRequestBuilder();

    const request = result
      .setMethod("get")
      .addQueryParam("ContentId", contentId)
      .setUrl(Api.Contents.getContentUserPermissions);

    return await request.GetResultAsync();
  }

  async addUserPermission(
    body: AddUserPermissionDto
  ): Promise<IGenericResponse<UserPermissionDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Contents.addUserPermission);

    return await request.GetResultAsync();
  }

  async deleteUserPermission(
    userPermissinId: number
  ): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setUrl(Api.Contents.deleteUserPermission, { userPermissinId });

    return await request.GetResultAsync();
  }
}
