import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { UsersService } from "src/apis/services/users/usersService";
import { PageSize } from "src/apis/helpers/configs/page-sizes";

export const useGetUsers = (page = 1) => {
    const usersService = container.resolve(UsersService);
    return useQuery({
        queryKey: [Api.Users.getUsers,page],
        queryFn: () => {

            if (typeof page !== 'number') {
                page = 1;
            }

            return usersService.getUsers({
                pageNumber: page,
                pageSize: PageSize.tables,
            });
        }
    })
}
