import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";

import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { JournalIndexTypeListDto } from "src/data/models/indexTypesDtos/journalIndexTypeListDto.interface";
import { AddJouranlIndexTypeDto } from "src/data/models/indexTypesDtos/addJouranlIndexTypeDto.interface";

export const useEditJournalIndexType = (id) => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();
    const toggleEdit = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<JournalIndexTypeListDto[]>([Api.Journals.getJournalIndexTypes,id]);
            const findIndex = tempData.findIndex(x=>x.journalIndexId === data.journalIndexId) ;
            tempData[findIndex].title = data.title;
            tempData[findIndex].imageUrl = data.imageUrl;
            queryClient.setQueryData([Api.Journals.getJournalIndexTypes,id],tempData )
        },
        [id],
    );
    return useMutation({
        mutationFn: (data: AddJouranlIndexTypeDto) => {
            return journalsService.editJournalIndexType(data);
        },
        onSuccess: toggleEdit
    })

}
