import { useMutation,useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { JournalsService } from "src/apis/services/journals/journalsService";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { JournalIndexTypeListDto } from "src/data/models/indexTypesDtos/journalIndexTypeListDto.interface";

export const useDeleteJournalIndexType = (id) => {
    const journalsService = container.resolve(JournalsService);

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (journalIndexTypeId) => {
            let tempObject = queryClient.getQueryData<JournalIndexTypeListDto[]>([Api.Journals.getJournalIndexTypes,id])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.journalIndexId !== journalIndexTypeId)
            queryClient.setQueryData([Api.Journals.getJournalIndexTypes,id], finalData)
        },
        [id],
    )

    return useMutation({
        mutationFn: (journalIndexId: number) => {
            return journalsService.deleteJournalIndexType(journalIndexId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}