import React, { useState } from "react";
import { Table, Modal, Space } from "antd";

import { ArticleStatusEnum } from "src/data/enums/articleStatusEnum";
import { useGetAllArticleStatusLogs } from "src/hooks/services/articles/useGetAllArticleStatusLogs";

const confirm = Modal.confirm;
const { Column,SELECTION_COLUMN } = Table;
const ArticleStatusList = (props) => {
  const { data: articleStatusLogData, isLoading } = useGetAllArticleStatusLogs(
    props.data.articleId
  );

  const showStatus = (status) => {
    switch (status) {
      case ArticleStatusEnum.Accepted:
        return "Accept";
      case ArticleStatusEnum.Rejected:
        return "Reject";
      case ArticleStatusEnum.Revise:
        return "Revise";
      case ArticleStatusEnum.NewArticle:
        return "Send To Editor";
      case ArticleStatusEnum.AssignedToVolume:
        return "Assigned To Volume";
      case ArticleStatusEnum.Deleted:
        return "Deleted";
      case ArticleStatusEnum.AddFromAuthor:
        return "Preliminary review";
      case ArticleStatusEnum.ReviseAuthorArticle:
        return "Returned to the author to make changes.";

      default:
        break;
    }
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={articleStatusLogData}
        rowKey="articleStatusLogId"
        expandable={{
          expandedRowRender: (record) => (
            <div
              dangerouslySetInnerHTML={{ __html: record.comment }}
              style={{ margin: 0 }}
            ></div>
          ),
        }}
      >
        <Column title="Comment"/>
        <Column title="User Name" dataIndex="userName" key="userName" />
        <Column
          title="Status"
          dataIndex="status"
          key="status"
          render={(text, record: any) => (
            <Space size="middle">{showStatus(record.status)}</Space>
          )}
        />
        <Column
          title="Entry DateTime"
          dataIndex="entryDateTime"
          key="entryDateTime"
          render={(text, record: any) => (
            <Space size="middle">
              {record.entryDateTime && record.entryDateTime.split("T")[0]}
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default ArticleStatusList;
