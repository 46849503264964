import { Route, Switch } from "react-router-dom";
import Dashboard from "./dashboard";
import Contents from "./contents";
import JournalsMangamnet from "./journals-page-managment";
import ProtectedRoute from "../../components/ProtectedRoute";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const Main = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/dashboard`} component={Dashboard} />
    <ProtectedRoute
      path={`${match.url}/contents/:type`}
      component={Contents}
      roles={[UserGroupEnum.Admin,UserGroupEnum.ConferenceEditor]}
    />
    <ProtectedRoute
      path={`${match.url}/journalsMangamnet`}
      component={JournalsMangamnet}
      roles={[UserGroupEnum.Admin, UserGroupEnum.EditorInChief]}
    />
  </Switch>
);

export default Main;
