import React, { useState } from "react";
import {
  Tooltip,
  Switch,
  Button,
  Table,
  Modal,
  Space,
  Avatar,
  Image,
  Typography,
} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useGetJournals } from "src/hooks/services/journals/useGetJournals";
import { useToggleJournalActivation } from "src/hooks/services/journals/useToggleJournalActivation";
import IndicatorAssign from "../journal-indicators";
import JournalIndexTypesPage from "../journal-indexType";
import MenuList from "../menu-list";
import TopicList from "../topic-list";
import TabList from "../tab-list";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
import JournalPermissionsPage from "../journal-permission";
const { Text, Title } = Typography;
const modalInfo = Modal.info;
const { Column } = Table;
const List = (props) => {
  const { data: journalsData, isLoading } = useGetJournals();
  const { mutate: onToggleJournalsStatus } = useToggleJournalActivation();

  const [selectedModalData, setSelectedModalData] = useState({
    type: "",
    value: null,
  });

  const editJournals = (data) => {
    props.changeTabEvent("2", data); // raftan be tab edit
  };

  const chaneActiveSwitch = (data) => {
    var finalData = {
      id: data.jid,
    };

    onToggleJournalsStatus(finalData, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />
        );
      },
      onError: (err) => {
        toast.error(
          <IntlMessages id="notification.errorMessage" />
        );
      },
    });
  };

  const openModal = (data, selectedType) => {
    if (data) {
      setSelectedModalData({
        type: selectedType,
        value: { ...data },
      });
    }
  };

  const topicHelp = {
    title: "The topic is shown in the details of the journal",
    content: (
      <>
        <Image width={200} src="/assets/images/topic.png" />
      </>
    ),
  };

  const closeModal = () => {
    setSelectedModalData({
      type: "",
      value: null,
    });
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={journalsData}
        rowKey="jid"
      >
        <Column
          title="Image"
          dataIndex="imageUrl"
          key="imageUrl"
          render={(text, record: any) => (
            <Avatar
              shape="square"
              size={80}
              src={<Image src={record.imageUrl} />}
            />
          )}
        />
        <Column
          title="Name"
          dataIndex="name"
          key="name"
          render={(text, record: any) => (
            <Space direction="vertical">
              <Text>{record.name}</Text>
              {record.pissn && <Text>pISSN : {record.pissn}</Text>}
              {record.eissn && <Text>eISSN : {record.eissn}</Text>}
            </Space>
          )}
        />
        <Column title="visit Count" dataIndex="visitCount" key="visitCount" />
        <Column title="priority" dataIndex="priority" key="priority" />
        {/* <Column
          title="has issue"
          dataIndex="hasIssue"
          key="hasIssue"
          render={(text, record: any) => (
            <span>{record.hasIssue ? "yes" : "no"}</span>
          )}
        /> */}
        <Column
          title="Journal Team"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "JournalTeam")}>
                Journal Team
              </a>
            </Space>
          )}
        />
        {/* 
        <Column
          title="Tabs"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "tab")}>tabs</a>
            </Space>
          )}
        /> */}
        <Column
          title={() => (
            <div className="space-align-container">
              <div className="space-align-block">
                <Space align="center">
                  <span>Topics</span>
                  <Tooltip title="help">
                    <Button
                      style={{ margin: 0 }}
                      onClick={() => modalInfo(topicHelp)}
                      shape="circle"
                      icon={<InfoCircleOutlined />}
                    />
                  </Tooltip>
                </Space>
              </div>
            </div>
          )}
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "topic")}>topics</a>
            </Space>
          )}
        />
        <Column
          title="Menus"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "menu")}>menus</a>
            </Space>
          )}
        />

        <Column
          title="Metrics"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "indicator")}>metrics</a>
            </Space>
          )}
        />
        <Column
          title="Index"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "indexType")}>index</a>
            </Space>
          )}
        />
        {props.role === UserGroupEnum.Admin ? (
          <>
            <Column
              title="Active"
              key="active"
              render={(text, record: any) => (
                <Space size="middle">
                  <Switch
                    defaultChecked={record?.active}
                    onChange={() => chaneActiveSwitch(record)}
                  />
                </Space>
              )}
            />
            <Column
              title="Edit"
              key="action"
              render={(text, record) => (
                <Space size="middle">
                  <a onClick={() => editJournals(record)}>Edit</a>
                </Space>
              )}
            />
          </>
        ) : null}
      </Table>
       {/* <NotificationContainer /> */}

      <Modal
        title={selectedModalData.value?.name +" Journal Team"}
        centered
        visible={selectedModalData.type === "JournalTeam"}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {selectedModalData.type === "JournalTeam" ? (
          <JournalPermissionsPage data={selectedModalData.value} />
        ) : null}
      </Modal>

      {/* journal indicators */}
      <Modal
        title={selectedModalData.value?.name + " Journal Metrics"}
        centered
        visible={selectedModalData.type === "indicator"}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {selectedModalData.type === "indicator" ? (
          <IndicatorAssign data={selectedModalData.value} />
        ) : null}
      </Modal>

      {/* journal indexType */}
      <Modal
        title={selectedModalData.value?.name + " Journal Index Type"}
        centered
        visible={selectedModalData.type === "indexType"}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {selectedModalData.type === "indexType" ? (
          <JournalIndexTypesPage data={selectedModalData.value} />
        ) : null}
      </Modal>

      {/* menu List */}
      <Modal
        title={selectedModalData.value?.name + " Journal Menu"}
        centered
        visible={selectedModalData.type === "menu"}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {selectedModalData.type === "menu" ? (
          <MenuList data={selectedModalData.value} />
        ) : null}
      </Modal>

      {/* topic List */}
      <Modal
        title={selectedModalData.value?.name + " Journal Topic"}
        centered
        visible={selectedModalData.type === "topic"}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {/* topic tab */}
        {selectedModalData.type === "topic" ? (
          <TopicList data={selectedModalData.value} />
        ) : null}
      </Modal>

      <Modal
        title={selectedModalData.value?.name + " Journal Tab"}
        centered
        visible={selectedModalData.type === "tab"}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {selectedModalData.type === "tab" ? (
          <TabList data={selectedModalData.value} />
        ) : null}
      </Modal>
    </>
  );
};

export default List;
