import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react";
import { ContentsService } from "src/apis/services/contents/contentsService";
import { ContentListDto } from "src/data/models/contentsDtos/contentListDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";

export const useToggleContentsStatus = (currentPage,contentType) => {
    const contentsService = container.resolve(ContentsService);
    
    const queryClient = useQueryClient()

    const toggleContentsStatus = useCallback(
        (targetContents) => {
            let lastData = queryClient.getQueryData<PaginatedList<ContentListDto>>([Api.Contents.getContentList , currentPage,contentType]);
            if (lastData === undefined) return

            const findIndex = lastData.items.findIndex(item => item.id === targetContents.id);
            lastData.items[findIndex].active = !lastData.items[findIndex].active ;
            queryClient.setQueryData([Api.Contents.getContentList,currentPage,contentType], lastData)
        },
        [currentPage,contentType],
    )

    return useMutation({
        mutationFn: (contentsId) => {
            return contentsService.toggleContentsStatus(contentsId);
        },
        onMutate: toggleContentsStatus,
        onError: (err) => {
        }
    })
}