import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, Card } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useEditAboutPublisher } from "src/hooks/services/baseData/useEditAboutPublisher";
import { useGetAboutPublisher } from "src/hooks/services/baseData/useGetAboutPublisher";
import Editor from "src/components/Editor";
const Add = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onEditAboutPublisher, isLoading: loadingEdit } =
    useEditAboutPublisher();
  const { data: aboutPublisherData, isLoading } = useGetAboutPublisher();
  const [about, setAbout] = useState("");
  const [aboutDefault, setAboutDefault] = useState("");

  const editAboutPublisher = (values) => {
    if (!about) {
      return;
    }

    let finalData = {
      htmlString: about,
    };

    onEditAboutPublisher(finalData, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />
        );
      },
      onError: (err) => {
        toast.error(
          <IntlMessages id="notification.errorMessage" />
        );
      },
    });
  };

  useEffect(() => {
    if (aboutPublisherData) {
      setAbout(aboutPublisherData);
      setAboutDefault(aboutPublisherData);
    }
  }, [aboutPublisherData]);

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        onFinish={editAboutPublisher}
        autoComplete="off"
      >
        <Form.Item label="About Publisher">
          <Editor
            value={aboutDefault}
            onChange={(data, editor) => {
              setAbout(data);
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button loading={loadingEdit} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
