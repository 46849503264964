import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react";
import { UsersService } from "src/apis/services/users/usersService";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";

export const useToggleUserStatus = (currentPage) => {
    const usersService = container.resolve(UsersService);
    

    const queryClient = useQueryClient()

    const toggleUserStatus = useCallback(
        (targetUser) => {
            let lastData = queryClient.getQueryData<PaginatedList<UsersDto>>([Api.Users.getUsers , currentPage]);
            if (lastData === undefined) return
            const findIndex = lastData.items.findIndex(item => item.userId === targetUser.userId);
            lastData.items[findIndex].active = !lastData.items[findIndex].active ;
            queryClient.setQueryData([Api.Users.getUsers,currentPage], lastData);
            let allTempData = queryClient.getQueryData<UsersDto[]>([Api.Users.getAllUsers]);
            if(allTempData) {
                const newAllData = [...allTempData] ;
                const findIndexWithAllUser = newAllData.findIndex(x=>x.userId === targetUser.userId) ;
                if(findIndexWithAllUser > 0) {
                    if(lastData.items[findIndex].active) {
                        newAllData[findIndexWithAllUser] =  lastData.items[findIndex];
                    } else {
                        newAllData.splice(findIndexWithAllUser,1);
                    }
                } else {
                    if(lastData.items[findIndex].active) {
                        newAllData.push(lastData.items[findIndex]);
                    }
                }
                queryClient.setQueryData([Api.Users.getAllUsers],newAllData);
            }
        },
        [currentPage],
    )

    return useMutation({
        mutationFn: (userId) => {
            return usersService.toggleUserStatus(userId);
        },
        onMutate: toggleUserStatus,
        onError: (err) => {
        }
    })
}