import BaseRepository from "../Base/BaseRepository";
import IAuthenticateRepository from "./IAuthenticateRepository";
import { Api } from "../../helpers/configs/api";
import { TokenRequestDto } from "../../../data/models/login/tokenRequestModel.interface";
import { TokenResponse, UserInfo } from "../../../data/models/login/tokenResponse.interface";
import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";

export default class AuthenticateRepository extends BaseRepository implements IAuthenticateRepository {

  async login(body: TokenRequestDto): Promise<IGenericResponse<TokenResponse>> {

    return await this.CreateRequestBuilder()
      .setMethod('post')
      .setUrl(Api.Auth.login)
      .setBody(body)
      .GetResultAsync();
  }
  
  async getBaseInfo(): Promise<IGenericResponse<UserInfo>> {

    return await this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.Auth.baseInfo)
      .GetResultAsync();
  }
}
