import { useMutation } from "react-query";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { container } from "tsyringe";
export const useDeleteEditorFiles = () => {
    const baseDataService = container.resolve(BaseDataService);
    return useMutation({
        mutationFn: (id: number) => {
            return baseDataService.deleteEditorFiles(id);
        },
    })
}