import { useMutation,useQueryClient } from "react-query";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";

export const useToggleRecommendedArticle = () => {
    const articlesService = container.resolve(ArticlesService);


    return useMutation({
        mutationFn: (articleId:any) => {
            return articlesService.toggleRecommendedArticle(articleId);
        },
        onError: (err) => {
        }
    })
}