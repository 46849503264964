import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react";
import { NewsService } from "src/apis/services/news/newsService";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";

export const useToggleNewsStatus = (currentPage) => {
    const newsService = container.resolve(NewsService);
    
    const queryClient = useQueryClient()

    const toggleNewsStatus = useCallback(
        (targetNews) => {
            let tempObject = queryClient.getQueryData<PaginatedList<NewsDto>>([Api.News.getNews,currentPage])

            if (tempObject === undefined) return

            const findIndex = tempObject.items.findIndex(item => item.newsId === targetNews.newsId);
            tempObject.items[findIndex].showInHome = !tempObject.items[findIndex].showInHome ;
            queryClient.setQueryData([Api.News.getNews,currentPage], tempObject)
        },
        [currentPage],
    )

    return useMutation({
        mutationFn: (newsId) => {
            return newsService.toggleNewsStatus(newsId);
        },
        onMutate: toggleNewsStatus,
        onError: (err) => {
        }
    })
}