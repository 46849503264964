import React , {useState} from "react";
import {Card, Tabs} from "antd";
import List from './list/List';
import Add from './add/Add';
const TabPane = Tabs.TabPane;


const IndexTypesPage = () => {
  const [activeTab , setActiveTab] = useState("1");
  const [indexTypesEditData , setIndexTypesEditData] = useState(null);
  const [indexTypesEditFlag , setIndexTypesEditFlag] = useState(false);
  const callback = (key , data = null) => {
    setActiveTab(key);
    if(data && key === "2") {
      setIndexTypesEditData(data);
      setIndexTypesEditFlag(true);
    } else if(key === "2") {
      setIndexTypesEditFlag(false);
      setIndexTypesEditData(null);
    }
  };

  return (
    <Card className="gx-card" title="Index Types">
      <Tabs activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
          <List changeTabEvent = {callback}/>
        </TabPane>
        <TabPane tab="Add / Edit Index Types" key="2">
        <Add fields = {indexTypesEditData} editSection = {indexTypesEditFlag} changeTabEvent = {callback} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default IndexTypesPage;
