import { AddArticleStatusLogDto } from "src/data/models/articleDtos/addArticleStatusLogDto.interface";
import { ArticleListDto } from "src/data/models/articleDtos/articleListDto.interface";
import { ArticleStatusLogDto } from "src/data/models/articleDtos/articleStatusLogDto.interface";
import { ArticlesTypeDto } from "src/data/models/articleDtos/articlesTypeDto.interface";
import { AuthorDto } from "src/data/models/articleDtos/authorDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { AuthorsPaginationParams } from "src/data/models/params/authorsPaginationParams.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { PaginationParamsWithFilter } from "src/data/models/params/paginationParamsWithFilter.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";
import { ArticleContentDto } from "src/data/models/articleDtos/articleContentDto.interface";

@singleton()
export class ArticlesService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getArticleTypes",
      "addArticleType",
      "editArticleType",
      "deleteArticleType",
      "deleteArticle",
      "getArticleList",
      "addArticle",
      "editArticle",
      "addAuthor",
      "editAuthor",
      "getAuthors",
      "getAllAuthors",
      "deleteAuthor",
      "EditArticleRecommended",
      "EditArticleAllowDownload",
      "EditArticleRightAndPermisson",
      "EditArticleContent",
      "GetArticleContent",
    ]);
    this.setMiddlware(this.handleToken, [
      "getArticleTypes",
      "addArticleType",
      "editArticleType",
      "deleteArticleType",
      "deleteArticle",
      "getArticleList",
      "addArticle",
      "editArticle",
      "addAuthor",
      "editAuthor",
      "getAuthors",
      "getAllAuthors",
      "deleteAuthor",
      "EditArticleRecommended",
      "EditArticleAllowDownload",
      "EditArticleRightAndPermisson",
      "EditArticleContent",
      "GetArticleContent",
    ]);
  }

  async toggleRecommendedArticle(id): Promise<number> {
    const result = await this.repositoryWrapper.Articles.toggleRecommendedArticle(
      id
    );
    return result.result;
  }

  async toggleAllowDownloadArticle(id): Promise<number> {
    const result = await this.repositoryWrapper.Articles.toggleAllowDownloadArticle(
      id
    );
    return result.result;
  }

  async changeRightAndPermissonArticle(articleId,rightsAndPermissions): Promise<number> {
    const result = await this.repositoryWrapper.Articles.changeRightAndPermissonArticle(
      articleId,rightsAndPermissions
    );
    return result.result;
  }

  async getArticleTypes(): Promise<ArticlesTypeDto[]> {
    const result = await this.repositoryWrapper.Articles.getArticleTypes();
    return result.result;
  }

  async getJournalArticleTypes(journalId): Promise<ArticlesTypeDto[]> {
    const result = await this.repositoryWrapper.Articles.getJournalArticleTypes(journalId);
    return result.result;
  }

  async addArticleType(data: ArticlesTypeDto): Promise<ArticlesTypeDto> {
    const result = await this.repositoryWrapper.Articles.addArticleType(data);
    return result.result;
  }

  async editArticleType(data: ArticlesTypeDto): Promise<ArticlesTypeDto> {
    const result = await this.repositoryWrapper.Articles.editArticleType(data);
    return result.result;
  }

  async deleteArticleType(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Articles.deleteArticleType(id);
    return result.result;
  }
  async deleteArticle(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Articles.deleteArticle(id);
    return result.result;
  }
  async getArticleList(params: PaginationParamsWithFilter): Promise<PaginatedList<ArticleListDto>> {
    const result = await this.repositoryWrapper.Articles.getArticleList(params);
    return result.result;
  }

  
  async addArticle(data: ArticleListDto): Promise<ArticleListDto> {
    const result = await this.repositoryWrapper.Articles.addArticle(data);
    return result.result;
  }

  async editArticle(data: ArticleListDto): Promise<ArticleListDto> {
    const result = await this.repositoryWrapper.Articles.editArticle(data);
    return result.result;
  }

  async changeArticleStatus(data: AddArticleStatusLogDto): Promise<ArticleListDto> {
    const result = await this.repositoryWrapper.Articles.changeArticleStatus(data);
    return result.result;
  }

  async getAllArticleStatusLogs(articleId: number): Promise<ArticleStatusLogDto[]> {
    const result = await this.repositoryWrapper.Articles.getAllArticleStatusLogs(articleId);
    return result.result;
  }


  async getAuthors(params: AuthorsPaginationParams): Promise<PaginatedList<AuthorDto>> {
    const result = await this.repositoryWrapper.Articles.getAuthors(params);
    return result.result;
  }

  async getAllAuthors(): Promise<AuthorDto[]> {
    const result = await this.repositoryWrapper.Articles.getAllAuthors();
    return result.result;
  }

  
  async addAuthor(data: AuthorDto): Promise<AuthorDto> {
    const result = await this.repositoryWrapper.Articles.addAuthor(data);
    return result.result;
  }

  async editAuthor(data: AuthorDto): Promise<AuthorDto> {
    const result = await this.repositoryWrapper.Articles.editAuthor(data);
    return result.result;
  }
  
  async deleteAuthor(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Articles.deleteAuthor(id);
    return result.result;
  }

  async editArticleContent(data: ArticleContentDto): Promise<boolean> {
    const result = await this.repositoryWrapper.Articles.editArticleContent(data);
    return result.result;
  }
  

  async getArticleContent(articleId): Promise<string> {
    const result = await this.repositoryWrapper.Articles.getArticleContent(articleId);
    return result.result;
  }

}
