import React from "react";
import { Route, Switch } from "react-router-dom";
import ArticleType from "./articleType";
import ArticleList from "./articleList";
import AuthorsList from "./authors";
import ProtectedRoute from "../../components/ProtectedRoute";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const ArticleData = ({ match }) => (
  <Switch>
    <ProtectedRoute
      roles={[UserGroupEnum.Admin]}
      path={`${match.url}/articleType`}
      component={ArticleType}
    />
    <Route path={`${match.url}/articleList`} component={ArticleList} />
    <Route path={`${match.url}/authors`} component={AuthorsList} />
  </Switch>
);

export default ArticleData;
