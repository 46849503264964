import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  InputNumber,
  TreeSelect,
} from "antd";
import { toast } from "react-toastify";
import IntlMessages from "../../../../util/IntlMessages";

import { UploadOutlined } from "@ant-design/icons";

import { useEditArticles } from "src/hooks/services/articles/useEditArticles";
import { useGetJournals } from "src/hooks/services/journals/useGetJournals";
import { ArticleStatusEnum } from "src/data/enums/articleStatusEnum";
import Editor from "src/components/Editor";
import { useGetAllIssues } from "src/hooks/services/journals/issue/useGetAllIssues";
import { useGetAllAuthors } from "src/hooks/services/articles/author/useGetAllAuthors";
import { useGetJournalArticleType } from "src/hooks/services/articles/type/useGetJournalArticleType";
const { Option } = Select;
const { TreeNode } = TreeSelect;
const Add = ({ fields, editSection = false, closeModal, sucessSubmit }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onEditArticles, isLoading: loadingEdit } = useEditArticles();
  const [formData, setFormData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [selectedAuthorList, setSelectedAuthorList] = useState([]);
  const [file, setFile] = useState(null);
  const [journalId, setJournalId] = useState(fields.journalId);
  const { data: journalsData, isLoading: isLoadingJournal } = useGetJournals();
  const { data: issueData, isLoading: isLoadingIssue } =
    useGetAllIssues(journalId);
  const { data: authorData, isLoading: isLoadingAuthors } = useGetAllAuthors();
  const [abstract, setAbstract] = useState("");
  const [abstractDefault, setAbstractDefault] = useState("");
  const { data: articleTypesData, isLoading: loadingType } =
    useGetJournalArticleType(journalId);

  useEffect(() => {
    if (fields) {
      if (fields.articleFile) {
        var file = [
          {
            uid: "-1",
            name: fields.articleFile.fileTitle,
            status: "done",
            url: fields.fileUrl,
          },
        ];
        setFileList(file);
        setFile(null);
      }
      if (fields.authors) {
        if (fields.authors.length > 0) {
          let data = [];
          let allData = [];
          fields.authors.forEach((author) => {
            data.push(author.id);
            allData.push(author);
            if (author.coAuthor) {
              fields.coAuthor = author.id;
            }
          });
          fields.authorsList = data;
          setSelectedAuthorList(allData);
        }
      }
      if (fields.keywords) {
        if (fields.keywords.length > 0) {
          let data = [];
          fields.keywords.forEach((keyword) => {
            data.push(keyword.keyword1);
          });
          fields.keywordsList = data;
        }
      }

      if (fields.articleTypeId === 0) {
        fields.articleTypeId = null;
      }

      if (fields.volume && !fields.issue) {
        fields.issueId = fields.volumeId + ",";
      }

      if (fields.issue) {
        fields.issueId = fields.volumeIssue.vid + "," + fields.issue.iid;
      }

      form.setFieldsValue(fields);
      setAbstract(fields.abstract);
      setAbstractDefault(fields.abstract);
    }
    return () => {
      form.resetFields();
    };
  }, [fields, editSection, form]);

  const saveArticles = (values) => {
    if ((!file && !editSection) || !abstract) {
      return;
    }
    if (values.keywordsList) {
      var allKeywords = [];
      values.keywordsList.forEach((element) => {
        var keywords = {
          kid: 0,
          keyword1: element,
          keywords: fields.articleId,
        };
        allKeywords.push(keywords);
      });
    }

    values.keywords = allKeywords;
    values.abstract = abstract;

    if (values.authorsList) {
      var allAuthors = [];
      values.authorsList.forEach((author) => {
        var articleAuthors = {
          id: 0,
          articleId: fields.articleId,
          authorId: author,
          coAuthor: values.coAuthor === author ? true : false,
        };
        allAuthors.push(articleAuthors);
      });
    }

    values.articleAuthors = allAuthors;

    if (values.issueId.split(",")) {
      const issueId = values.issueId.split(",")[1];
      if (!issueId) {
        values.volumeId = values.issueId.split(",")[0];
        values.issueId = null;
      } else {
        values.issueId = values.issueId.split(",")[1];
      }
    }

    const formData = new FormData();
    formData.append("File", file);
    if (editSection) {
      values.articleId = fields.articleId;
      values.status = ArticleStatusEnum.AssignedToVolume;
      formData.append("Article", JSON.stringify(values));
      onEditArticles(formData, {
        onSuccess: (data) => {
          toast.success(<IntlMessages id="notification.successMessage" />);
          form.resetFields();
          closeModal();
          sucessSubmit();
        },
        onError: (err) => {
          toast.error(<IntlMessages id="notification.errorMessage" />);
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (fileSelected) => {
    setFile(fileSelected);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var file = [
        {
          uid: "-2",
          name: fileSelected.name,
          status: "done",
          url: reader.result,
        },
      ];
      setFileList(file);
    });

    reader.readAsDataURL(fileSelected);
    return false;
  };

  const removeUpload = () => {
    setFileList([]);
  };

  const handlerSelectedAuthor = (authors) => {
    const selectedValue = [];
    for (let index = 0; index < authors.length; index++) {
      selectedValue.push(authorData.find((c) => c.id === authors[index]));
    }
    setSelectedAuthorList(selectedValue);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveArticles"
        onFinish={saveArticles}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="journalId"
          label="Journal"
          rules={[
            {
              required: true,
              message: "Please Select Journal!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Journal"
            optionFilterProp="children"
            disabled
          >
            {!isLoadingJournal &&
              journalsData.map((data) => {
                return (
                  <Option key={data.jid} value={data.jid}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input title",
            },
          ]}
        >
          <Input placeholder="title" />
        </Form.Item>

        <Form.Item
          name="articleTypeId"
          label="Article Type"
          rules={[
            {
              required: true,
              message: "Please Select Article Type!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Article Type"
            optionFilterProp="children"
          >
            {!loadingType &&
              articleTypesData.map((data) => {
                return (
                  <Option key={data.id} value={data.id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="Abstract" required>
          <Editor
            value={abstractDefault}
            onChange={(data, editor ) => {
              setAbstract(data);
            }}
          />
        </Form.Item>

        <Form.Item
          name="issueId"
          label="Volume / Issue"
          rules={[
            {
              required: true,
              message: "Please Select issue!",
            },
          ]}
        >
          <TreeSelect
            showSearch
            style={{ width: "100%" }}
            dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
            placeholder="Please select"
            allowClear
            treeDefaultExpandAll
            treeNodeFilterProp="title"
          >
            {!isLoadingIssue &&
              issueData.map((data) => {
                return (
                  <TreeNode
                    value={data.vid + ","}
                    key={data.vid + ","}
                    title={"volume " + data.volumeNumber}
                  >
                    {data.issues.map((issue) => {
                      return (
                        <TreeNode
                          value={data.vid + "," + issue.iid}
                          key={data.vid + "," + issue.iid}
                          title={"issue " + issue.issueNumber}
                        />
                      );
                    })}
                  </TreeNode>
                );
              })}
          </TreeSelect>
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', width: 'calc(55%)',paddingLeft:'140px' }} name="fromPage" label="From Page">
          <InputNumber style={{ width: 100 }} placeholder="from page" />
        </Form.Item>
        <Form.Item style={{ display: 'inline-block', width: 'calc(30%)' }} name="toPage" label="To Page">
          <InputNumber style={{ width: 100 }} placeholder="to page" />
        </Form.Item>

        <Form.Item
          name="doi"
          label="Doi"
          rules={[
            {
              required: true,
              message: "Please Input Doi!",
            },
          ]}
        >
          <Input addonBefore="https://doi.org/" placeholder="doi" />
        </Form.Item>

        {/* <Form.Item
          name="issn doi"
          label="issndoi"
        >
          <Input placeholder="issn doi" />
        </Form.Item> */}

        <Form.Item
          label="Author list"
          name="authorsList"
          rules={[
            {
              required: true,
              message: "Please select author!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Authors"
            optionFilterProp="children"
            mode="multiple"
            onChange={handlerSelectedAuthor}
            allowClear
          >
            {!isLoadingAuthors &&
              authorData.map((data) => {
                return (
                  <Option key={data.id} value={data.id}>
                    {data.fullName}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item label="Corresponding Author" name="coAuthor">
          <Select
            showSearch
            placeholder="Select Corresponding Author"
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            allowClear
          >
            {selectedAuthorList?.map((data) => {
              return (
                <Option key={data.id} value={data.id}>
                  {data.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Keywords" name="keywordsList">
          <Select mode="tags" style={{ width: "100%" }} open={false}></Select>
        </Form.Item>

        <Form.Item label="File" required>
          <Upload
            name="avatar"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={fileList}
            accept=".pdf, .doc, .docx"
            showUploadList={{ showPreviewIcon: false }}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button loading={loadingEdit} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>

      {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
