import { SubjectDto } from "src/data/models/subjectDtos/subjectDto.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class SubjectsService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getSubjects",
      "addSubjects",
      "editSubjects",
      "deleteSubjects",
    ]);
    this.setMiddlware(this.handleToken, [
      "getSubjects",
      "addSubjects",
      "editSubjects",
      "deleteSubjects",
    ]);
  }

  async getSubjects(): Promise<SubjectDto[]> {
    const result = await this.repositoryWrapper.Subjects.getSubjects();
    return result.result;
  }

  async addSubjects(data: SubjectDto): Promise<SubjectDto> {
    const result = await this.repositoryWrapper.Subjects.addSubjects(data);
    return result.result;
  }

  async editSubjects(data: SubjectDto): Promise<SubjectDto> {
    const result = await this.repositoryWrapper.Subjects.editSubjects(data);
    return result.result;
  }

  async deleteSubjects(indicatorId: number): Promise<number> {
    const result = await this.repositoryWrapper.Subjects.deleteSubjects(indicatorId);
    return result.result;
  }
}
