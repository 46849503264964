import { useMutation,useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { JournalsService } from "src/apis/services/journals/journalsService";
import { JournalIndicatorListDto } from "src/data/models/indicatorDtos/journalIndicatorListDto.interface";

export const useDeleteJournalIndicator = (id) => {
    const journalsService = container.resolve(JournalsService);

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (journalIndicatorId) => {
            let tempObject = queryClient.getQueryData<JournalIndicatorListDto[]>([Api.Journals.getJournalIndicators,id])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.id !== journalIndicatorId)
            queryClient.setQueryData([Api.Journals.getJournalIndicators,id], finalData)
        },
        [id],
    )

    return useMutation({
        mutationFn: (journalIndicatorId: number) => {
            return journalsService.deleteJournalIndicator(journalIndicatorId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}