import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Upload, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAddNews } from "src/hooks/services/news/useAddNews";
import { useEditNews } from "src/hooks/services/news/useEditNews";
import Editor from 'src/components/Editor';
const { TextArea } = Input;
const Add = ({ fields, editSection = false, changeTabEvent, currentPage }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddNews , isLoading:loadingSave} = useAddNews();
  const { mutate: onEditNews, isLoading:loadingEdit } = useEditNews(currentPage);
  const [formData, setFormData] = useState([]);
  const [newsContent, setNewsContent] = useState("");
  const [newsContentDefault, setNewsContentDefault] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  useEffect(() => {
    if (fields) {
      const finalData = [];
      for (var key in fields) {
        if (fields.hasOwnProperty(key)) {
          let data = {
            name: [],
            value: "",
          };
          data.name.push(key);
          if(key === "newsKeywords" && fields[key]) {
            data.value = fields[key].map(x=>x.keywords);
          } else {
            data.value = fields[key];
          }
          finalData.push(data);
        }
      }
      setFormData(finalData);
      setNewsContent(fields.newsContent);
      setNewsContentDefault(fields.newsContent);
      var imageFile = [{
        uid: '-1',
        name: 'image',
        status: 'done',
        url: fields.newsImageUrl,
      }];
      setImageList(imageFile);
    }
    return () => {
      form.resetFields();
      setFormData([]);
      setNewsContent("");
      setNewsContentDefault("");
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection,form]);

  const saveNews = (values) => {

    if(!newsContent) {
      return ;
    }

    if(imageFile === null && !editSection) {
      return;
    }
    values.newsContent = newsContent ;
    if(values.newsKeywords) {
      var allKeywords = [];
      values.newsKeywords.forEach(element => {
        var keywords = {
          newsKeywordId : 0,
          keywords : element
        }
        allKeywords.push(keywords);
      });
    }

    values.newsKeywords = allKeywords ;

    const formData = new FormData();
    formData.append("Image", imageFile);
    if (editSection) {
      values.newsId = fields.newsId;
      formData.append("News", JSON.stringify(values));
      onEditNews(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      formData.append("News", JSON.stringify(values));

      onAddNews(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setNewsContent("");
          setNewsContentDefault("");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      var imageFile = [{
        uid: '-2',
        name: 'image',
        status: 'done',
        url: reader.result,
      }];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);

  }

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveNews"
        onFinish={saveNews}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="newsTitle"
          label="News Headline"
          rules={[
            {
              required: true,
              message: "Please input news headline!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="newsLead"
          label="News Lead (for seo)"
          rules={[
            {
              required: true,
              message: "Please input news lead!",
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={150} />
        </Form.Item>

        <Form.Item
          label="News Content"
          required
        >
          <Editor             
            value={newsContentDefault}
            onChange={ ( data, editor  ) => {
              setNewsContent(data);
          } }/>
        </Form.Item>

        <Form.Item
          label="Keyword For Seo"
          name="newsKeywords"
        >
          <Select mode="tags" style={{ width: "100%" }} open={false}>

          </Select>
        </Form.Item>

        <Form.Item
          label="Image"
          required
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
             {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item name="showInHome" valuePropName="checked">
          <Checkbox>show In Home</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
