import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select,InputNumber } from "antd";

import { toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAssignIndexTypeToJournal } from "src/hooks/services/journals/journalIndexType/useAssignIndexTypeToJournal";
import { useEditJournalIndexType } from "src/hooks/services/journals/journalIndexType/useEditJournalIndexType";
import { useGetIndexTypes } from "src/hooks/services/indexTypes/useGetIndexTypes";

const { Option } = Select;

const IndexTypeAdd = ({
  fields,
  changeTabEvent,
  journalData,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const { mutate: onAddIndexTypeAssign , isLoading:loadingSave} = useAssignIndexTypeToJournal(
    journalData.jid
  );
  const { mutate: onEditIndexTypeAssign, isLoading:loadingEdit } = useEditJournalIndexType(
    journalData.jid
  );
  const { data: indexTypesData, isLoading:isIndexTypesLoading } = useGetIndexTypes();

  useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [fields]);

  const saveIndexTypeAssign = (values) => {
    values.journalId =  journalData.jid;

      onAddIndexTypeAssign(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveIndexTypeAssign}
        autoComplete="off"
      >
        <Form.Item
          name="indexId"
          label="IndexType"
          rules={[
            {
              required: true,
              message: "Please select index type !",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select index type"
            optionFilterProp="children"
          >
            {!isIndexTypesLoading ? indexTypesData.map((data) => {
              return <Option key={data.id} value={data.id}>{data.title}</Option>;
            }) : null}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button loading = {loadingSave} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default IndexTypeAdd;
