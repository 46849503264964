import React, { useEffect, useState } from "react";
import { Switch, Table, Modal, Space, Avatar, Image } from "antd";
import { ToastContainer, toast } from "react-toastify";
import IntlMessages from "../../../../util/IntlMessages";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetContents } from "src/hooks/services/contents/useGetContents";
import { useDeleteContents } from "src/hooks/services/contents/useDeleteContents";
import { useToggleContentsStatus } from "src/hooks/services/contents/useToggleContentsStatus";
import MenuList from "../menu-list";
import TabList from "../tab-list";
import TopicList from "../topic-list";
import { ContentTypeEnum } from "src/data/enums/contentType.enum";
import ConferencePermissionsPage from "../conf-permission";
import { useAuth } from "src/authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const confirm = Modal.confirm;
const { Column } = Table;
const List = (props) => {
  const [page, setPage] = useState(1);
  const { authUser } = useAuth();
  const { data: contentsData, isLoading } = useGetContents(
    page,
    props.contentType.id
  );
  const { mutate: onDeleteContents } = useDeleteContents(
    page,
    props.contentType.id
  );
  const { mutate: onToggleContentsStatus } = useToggleContentsStatus(
    page,
    props.contentType.id
  );

  const [visibleMenu, setVisibleMenu] = useState(false);
  const [visibleTopic, setVisibleTopic] = useState(false);
  const [visibleTab, setVisibleTab] = useState(false);
  const [visiblePermission, setVisiblePermission] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);

  const changePage = (page) => {
    setPage(page);
  };

  const editContents = (data) => {
    props.changeTabEvent("2", data, page); // raftan be tab edit
  };

  useEffect(() => {
    if (contentsData) {
      if (contentsData.items.length === 0 && page > 1) {
        setPage(page - 1);
      }
    }
  }, [contentsData]);

  const chaneActiveSwitch = (data) => {
    var finalData = {
      id: data.id,
    };

    onToggleContentsStatus(finalData, {
      onSuccess: (data) => {
        toast.success(<IntlMessages id="notification.successMessage" />);
      },
      onError: (err) => {
        toast.error(<IntlMessages id="notification.errorMessage" />);
      },
    });
  };

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteContents(data.id, {
          onSuccess: (data) => {
            toast.success(<IntlMessages id="notification.successMessage" />);
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      },
      onCancel() {},
    });
  };

  const openModal = (data, type) => {
    setSelectedModalData(data);

    switch (type) {
      case "menu":
        setVisibleMenu(true);
        break;
      case "topic":
        setVisibleTopic(true);
        break;
      case "tab":
        setVisibleTab(true);
        break;
      case "ConferenceTeam":
        setVisiblePermission(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={contentsData?.items}
        rowKey="id"
        pagination={{
          defaultPageSize: PageSize.tables,
          total: contentsData?.totalCount,
          onChange: changePage,
          showSizeChanger: false,
        }}
      >
        <Column
          title="Image"
          dataIndex="imageUrl"
          key="imageUrl"
          render={(text, record: any) => (
            <Avatar
              shape="square"
              size={80}
              src={<Image src={record.imageUrl} />}
            />
          )}
        />
        <Column title="Title" dataIndex="title" key="title" />
        <Column title="priority" dataIndex="priority" key="priority" />
        <Column
          title="Active"
          key="active"
          render={(text, record: any) => (
            <Space size="middle">
              <Switch
                defaultChecked={record?.active}
                onChange={() => chaneActiveSwitch(record)}
              />
            </Space>
          )}
        />

        <Column
          title="Tabs"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "tab")}>tabs</a>
            </Space>
          )}
        />
        <Column
          title="Topics"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "topic")}>topics</a>
            </Space>
          )}
        />
        <Column
          title="Menus"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => openModal(record, "menu")}>menus</a>
            </Space>
          )}
        />

        {props.contentType.id === ContentTypeEnum.Conference &&
          authUser?.userInfo.role ===
            UserGroupEnum.Admin && (
              <Column
                title="Conference Team"
                key="action"
                render={(text, record) => (
                  <Space size="middle">
                    <a onClick={() => openModal(record, "ConferenceTeam")}>
                      Conference Team
                    </a>
                  </Space>
                )}
              />
            )}

        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editContents(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
      {/* <NotificationContainer /> */}

      {/* menu List */}
      <Modal
        title="Content Menu"
        centered
        visible={visibleMenu}
        footer={null}
        onCancel={() => setVisibleMenu(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleMenu ? <MenuList data={selectedModalData} /> : null}
      </Modal>

      {/* topic List */}
      <Modal
        title="Content Topic"
        centered
        visible={visibleTopic}
        footer={null}
        onCancel={() => setVisibleTopic(false)}
        width={1000}
        destroyOnClose={true}
      >
        {/* topic tab */}
        {visibleTopic ? <TopicList data={selectedModalData} /> : null}
      </Modal>

      <Modal
        title="Content Tab"
        centered
        visible={visibleTab}
        footer={null}
        onCancel={() => setVisibleTab(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleTab ? <TabList data={selectedModalData} /> : null}
      </Modal>

      <Modal
        title={" Conference Team"}
        centered
        visible={visiblePermission}
        footer={null}
        onCancel={() => setVisiblePermission(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visiblePermission ? (
          <ConferencePermissionsPage data={selectedModalData} />
        ) : null}
      </Modal>
    </>
  );
};

export default List;
