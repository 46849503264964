import React, { useEffect, useState } from "react";
import { Form, Input, InputNumber,Checkbox, Button, DatePicker } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../../util/IntlMessages";
import { useAddIssues } from "src/hooks/services/journals/issue/useAddIssues";
import { useEditIssues } from "src/hooks/services/journals/issue/useEditIssues";
import moment from "moment";
const { TextArea } = Input;

const Add = ({
  fields,
  editSection = false,
  changeTabEvent,
  currentPage,
  journals,
  volumeId,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddIssues, isLoading: loadingSave } = useAddIssues(
    journals.jid,
    volumeId
  );
  const { mutate: onEditIssues, isLoading: loadingEdit } = useEditIssues(
    currentPage,
    journals.jid,
    volumeId
  );
  const [formData, setFormData] = useState([]);

  useEffect(() => {
    if (fields) {
      if (fields.publishedDate) {
        fields.publishedDate = moment(fields.publishedDate, "'YYYY-MM-DD'");
      }
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [fields, editSection]);

  const saveIssue = (values) => {
    if (values.publishedDate) {
      values.publishedDate = values.publishedDate.format("YYYY-MM-DD");
    }
    values.volumeId = volumeId;
    const formData = new FormData();
    if (editSection) {
      values.iid = fields.iid;
      formData.append("Journal", JSON.stringify(values));
      onEditIssues(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      formData.append("Journal", JSON.stringify(values));
      onAddIssues(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={saveIssue}
        fields={formData}
        scrollToFirstError
        initialValues={{ ["journalId"]: journals.name }}
        autoComplete="off"
      >
        <Form.Item
          name="journalId"
          label="Journal"
          rules={[
            {
              required: true,
              message: "Please Select Journal!",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input title",
            },
          ]}
        >
          <Input placeholder="title" />
        </Form.Item>

        <Form.Item name="description" label="description">
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item
          name="issueNumber"
          label="Issue Number"
          rules={[
            {
              required: true,
              message: "Please input issue Number",
            },
          ]}
        >
          <InputNumber style={{ width: 200 }} placeholder="issue Number" />
        </Form.Item>
        {/* <Form.Item
          name="pageFrom"
          label="page from"
          rules={[
            {
              required: true,
              message: "Please input page from!",
            },
          ]}
        >
          <InputNumber  style={{ width: 200 }} placeholder="page from"/>
        </Form.Item>
        <Form.Item
          name="pageTo"
          label="page to"
          rules={[
            {
              required: true,
              message: "Please input page to!",
            },
          ]}
        >
          <InputNumber  style={{ width: 200 }} placeholder="page to"/>
        </Form.Item>         */}

        <Form.Item name="publishedDate" label="Published Date">
          <DatePicker showTime={false} style={{ width: 200 }} />
        </Form.Item>
        <Form.Item name="isSpecialIssue" valuePropName="checked">
          <Checkbox>special issue</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
