import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber } from "antd";

import { toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import Editor from "src/components/Editor";
import { useAddMenu } from "src/hooks/services/contents/menu/useAddMenu";
import { useEditMenu } from "src/hooks/services/contents/menu/useEditMenu";
const MenuAdd = ({
  fields,
  editSection = false,
  changeTabEvent,
  journalData,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const [descContent, setDescContent] = useState("");
  const [descContentDefault, setDescContentDefault] = useState("");
  const { mutate: onAddMenu, isLoading: loadingSave } = useAddMenu(
    journalData.jid,
    false
  );
  const { mutate: onEditMenu, isLoading: loadingEdit } = useEditMenu(
    journalData.jid,
    false
  );

  useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
      if (fields.description) {
        setDescContent(fields.description);
        setDescContentDefault(fields.description);
      }
    }
    return () => {
      form.resetFields();
      setDescContent("");
      setDescContentDefault("");
    };
  }, [fields, editSection]);

  const saveMenu = (values) => {
    values.description = descContent;
    values.jouranlId = journalData.jid;
    if (editSection) {
      values.id = fields.id;
      onEditMenu(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      onAddMenu(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setDescContent("");
          setDescContentDefault("");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveMenu}
        autoComplete="off"
      >
        <Form.Item
          label="Menu Title"
          name="menuTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description">
          <Editor
            value={descContentDefault}
            onChange={(data, editor ) => {
              setDescContent(data);
            }}
          />
        </Form.Item>
        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: "Please input Priority!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default MenuAdd;
