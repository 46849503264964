import React from "react";
import {Route, Switch} from "react-router-dom";
import Edit from "./edit/Edit";
import ChangePassword from "./change-password/ChangePassword";


const BaseData = ({match}) => (
  <Switch>
    <Route path={`${match.url}/edit`} component={Edit}/>
    <Route path={`${match.url}/changepassword`} component={ChangePassword}/>
  </Switch>
);

export default BaseData;
