import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndexTypesService } from "src/apis/services/indexTypes/indexTypesService";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";


export const useAddIndexTypes = () => {
    const indexTypesService = container.resolve(IndexTypesService);
    const queryClient = useQueryClient();

    const toggleAddIndexTypes = useCallback(
        (data, variables, context) => {
            let lastData = queryClient.getQueryData<IndexTypesDto[]>([Api.IndexTypes.getIndexTypes ]);
            const finalData = [...lastData] ;
            finalData.push(data);
            queryClient.setQueryData([Api.IndexTypes.getIndexTypes],finalData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: IndexTypesDto) => {
            return indexTypesService.addIndexTypes(data);
        },
        onSuccess: toggleAddIndexTypes
    })

}
