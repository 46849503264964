import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { IssueDto } from "src/data/models/journalDtos/issueDto.interface";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";

export const useAddIssues = (journalId, volumeId) => {
  const journalsService = container.resolve(JournalsService);
  const queryClient = useQueryClient();

  const toggleAdd = useCallback((data, variables, context) => {
    let tempData = queryClient.getQueryData<PaginatedList<VolumeWithIssuesDto>>(
      [Api.Journals.GetJournalIssues, 1, journalId]
    );
    let lastData = queryClient.getQueryData<PaginatedList<VolumeWithIssuesDto>>(
      [Api.Journals.GetJournalIssues, tempData.totalPages, journalId]
    );
    const volumeIndex = lastData.items.findIndex((x) => x.vid === volumeId);
    const finalData = [...lastData.items[volumeIndex].issues];
    finalData.push(data);
    lastData.items[volumeIndex].issues = finalData;

    queryClient.setQueryData(
        [Api.Journals.GetJournalIssues, tempData.totalPages, journalId],
        lastData
      );


  }, [journalId, volumeId]);
  return useMutation({
    mutationFn: (data: IssueDto) => {
      return journalsService.addIssue(data);
    },
    onSuccess: toggleAdd,
  });
};
