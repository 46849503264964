import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Button,
  Table,
  Modal,
  Space,
  Typography,
  Avatar,
} from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { UserOutlined } from '@ant-design/icons';
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
import { useGetAuthors } from "src/hooks/services/articles/author/useGetAuthors";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useDeleteAuthor } from "src/hooks/services/articles/author/useDeleteAuthor";
import { useQuery } from "src/hooks/utils/useQuery";

const confirm = Modal.confirm;
const { Column } = Table;
const { Link } = Typography;
const List = (props) => {
  let query = useQuery();
  const authorEmail = query.get("email");
  const [searchForm] = Form.useForm();
  const [authorSearchData, setAuthorSearchData] = useState({
    page: 1,
    fullName: "",
    email: authorEmail ? authorEmail : "",
  });
  const { data: authorsData, isLoading } = useGetAuthors(authorSearchData);
  const { mutate: onDeleteAuthor } = useDeleteAuthor(authorSearchData);

  const editAuthors = (data) => {
    props.changeTabEvent("2", data, authorSearchData); // raftan be tab edit
  };
  const changePage = (pagination, pageSize) => {
    setAuthorSearchData((oldValues) => ({
      ...oldValues,
      page: pagination,
    }));
  };

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteAuthor(data.id, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      },
      onCancel() {},
    });
  };

  const searchAuthors = (values: any) => {
    const filterValue = {
      page: 1,
      fullName: "",
      email: "",
    };
    if (values.fullName) {
      filterValue.fullName = values.fullName;
    } else {
      filterValue.fullName = "";
    }
    if (values.email) {
      filterValue.email = values.email;
    } else {
      filterValue.email = "";
    }

    setAuthorSearchData(filterValue);
  };

  useEffect(() => {
    if (authorEmail) {
      searchForm.setFieldsValue({
        email: authorEmail,
      });
    }
  }, [authorEmail, searchForm]);

  return (
    <>
      <Form
        onFinish={searchAuthors}
        layout="inline"
        form={searchForm}
        className="ant-advanced-search-form"
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item label="Full Name" name="fullName">
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="Email" name="email">
              <Input />
            </Form.Item>
          </Col>

          <Col span={2}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Table
        className="gx-table-responsive"
        dataSource={authorsData?.items}
        rowKey="id"
        pagination={{
          defaultPageSize: PageSize.tables,
          total: authorsData?.totalCount,
          showSizeChanger: false,
          onChange: changePage,
        }}
      >
        <Column
          title="image"
          dataIndex="image"
          key="image"
          render={(text, record: any) => {
            if(record.imageUrl) {
              return (
                <Avatar src={record.imageUrl} size="large" />
              )
            } else {
              return (
                <Avatar size="large" icon={<UserOutlined />} />
              )
            }
            }}
        />
        <Column
          title="full Name"
          dataIndex="fullName"
          key="fullName"
          render={(text, record: any) => (
            <Link
              target="_blank"
              href={"/article/articleList?email=" + record.email}
            >
              {record.fullName}
            </Link>
          )}
        />
        <Column title="primary email" dataIndex="email" key="email" />
        <Column
          title="secondary email"
          dataIndex="secondaryEmail"
          key="secondaryEmail"
        />
        <Column
          title="orcid"
          dataIndex="idurl"
          key="idurl"
          render={(text, record: any) => (
            <a target="_blank" rel="noreferrer" href={record.idurl}>
              {record.idurl}
            </a>
          )}
        />
        <Column title="affiliation" dataIndex="affiliation" key="affiliation" />
        {props.role === UserGroupEnum.Assistant ? (
          <>
            <Column
              title="Edit"
              key="action"
              render={(text, record) => (
                <Space size="middle">
                  <a onClick={() => editAuthors(record)}>Edit</a>
                </Space>
              )}
            />
            <Column
              title="Delete"
              key="action"
              render={(text, record) => (
                <Space size="middle">
                  <a onClick={() => showDeleteConfirm(record)}>Delete</a>
                </Space>
              )}
            />
          </>
        ) : null}
      </Table>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default List;
