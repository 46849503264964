import React,{useState} from "react";
import {Card} from "antd";
import Add from './add/Add';


const SettingPage = () => {
 
  return (
    <Card className="gx-card" title="Setting">
        <Add />
    </Card>
  );
};

export default SettingPage;
