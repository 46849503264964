import { useMutation } from "react-query";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";

export const useChangeRightAndPermissonArticle = () => {
    const articlesService = container.resolve(ArticlesService);

    return useMutation({
        mutationFn: (data:any) => {
            return articlesService.changeRightAndPermissonArticle(data.articleId,data.rightsAndPermissions);
        },
        onError: (err) => {
        }
    })
}