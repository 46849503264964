import React, { useState } from "react";
import { Switch, Table, Modal, Space, Avatar, Image } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetNews } from "src/hooks/services/news/useGetNews";
import { useToggleNewsStatus } from "src/hooks/services/news/useToggleNewsStatus";
import { useDeleteNews } from "src/hooks/services/news/useDeleteNews";
const confirm = Modal.confirm;
const { Column } = Table;
const List = (props) => {
  const [page, setPage] = useState(1);
  const { data: newsData, isLoading } = useGetNews(page);
  const { mutate: onDeleteNews } = useDeleteNews(page);
  const { mutate: onToggleNewsStatus } = useToggleNewsStatus(page);

  const changePage = (page) => {
    setPage(page);
  };

  const editNews = (data) => {
    props.changeTabEvent("2", data, page); // raftan be tab edit
  };

  const chaneActiveSwitch = (data) => {
    var finalData = {
      newsId: data.newsId,
    };

    onToggleNewsStatus(finalData, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />
        );
      },
      onError: (err) => {
        toast.error(
          <IntlMessages id="notification.errorMessage" />
        );
      },
    });
  };

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure delete this news?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteNews(data.newsId, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err:any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() { },
    });
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={newsData?.items}
        pagination={{
          defaultPageSize: PageSize.tables,
          total: newsData?.totalCount,
          onChange: changePage,
          showSizeChanger:false
        }}
        rowKey="newsId"
      >
        <Column
          title="Image"
          dataIndex="newsImageUrl"
          key="newsImageUrl"
          render={(text, record: any) => (
            <Avatar shape="square" size={80} src={<Image src={record.newsImageUrl} />} />
          )}
        />
        <Column title="Title" dataIndex="newsTitle" key="newsTitle" />
        <Column title="News Lead" dataIndex="newsLead" key="newsLead" />
        <Column
          title="show In Home"
          key="showInHome"
          render={(text, record: any) => (
            <Space size="middle">
              <Switch
                defaultChecked={record?.showInHome}
                onChange={() => chaneActiveSwitch(record)}
              />
            </Space>
          )}
        />
        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editNews(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default List;
