import { Table, Modal, Space } from "antd";
import {
  toast,
} from "react-toastify";
import IntlMessages from "../../../../util/IntlMessages";
import { useGetJournalUserPermissions } from "src/hooks/services/journals/journalUserPermisson/useGetJournalUserPermissions";
import { useDeleteJournalUserPermission } from "src/hooks/services/journals/journalUserPermisson/useDeleteJournalUserPermission";

const confirm = Modal.confirm;
const { Column } = Table;
const PermissionList = (props) => {

  const { data: menuData, isLoading } = useGetJournalUserPermissions(props.journalData.jid);
  const { mutate: onDeletePermissionAssign } = useDeleteJournalUserPermission(props.journalData.jid);



  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeletePermissionAssign(data.id, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err:any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() {},
    });
  };


  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={menuData}
        rowKey="id"
      >

        <Column title="journal Title" dataIndex="journalTitle" key="journalTitle" />
        <Column title="userName" dataIndex="userName" key="userName" />
        <Column title="role Title" dataIndex="roleTitle" key="roleTitle" />

        <Column
          title="Delete"
          key="action"
          render={(text, record:any) => (
            <Space size="middle">
              {(props.userInfo.id !== record.userId) && (
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
              )}
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default PermissionList;
