import { ContentTypeEnum } from "src/data/enums/contentType.enum";
import { ContentListDto } from "src/data/models/contentsDtos/contentListDto.interface";
import { MenuListDto } from "src/data/models/contentsDtos/menuListDto.interface";
import { TabsListDto } from "src/data/models/contentsDtos/tabsListDto.interface";
import { TopicsListDto } from "src/data/models/contentsDtos/topicsListDto.interface";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { contentsPaginationParams } from "src/data/models/params/contentsPaginationParams.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";
import { UserPermissionDto } from "src/data/models/usersDtos/userPermissionDto.interface";
import { AddUserPermissionDto } from "src/data/models/usersDtos/addUserPermissionDto.interface";

@singleton()
export class ContentsService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getContentList",
      "addContents",
      "editContents",
      "deleteContents",
      "toggleContentsStatus",
      "getContentMenuList",
      "getContentTabList",
      "getContentTopicsList",
      "addMenuList",
      "addTabList",
      "addTopicList",
      "editMenuList",
      "editTabList",
      "editTopicList",
      "deleteMenuList",
      "deleteTabList",
      "deleteTopicList",
      "getContentUserPermissions",
      "addUserPermission",
      "deleteUserPermission",
    ]);
    this.setMiddlware(this.handleToken, [
      "getContentList",
      "addContents",
      "editContents",
      "deleteContents",
      "toggleContentsStatus",
      "getContentMenuList",
      "getContentTabList",
      "getContentTopicsList",
      "addMenuList",
      "addTabList",
      "editMenuList",
      "editTabList",
      "editTopicList",
      "deleteMenuList",
      "deleteTabList",
      "deleteTopicList",
      "getAllContents",
      "getContentUserPermissions",
      "addUserPermission",
      "deleteUserPermission",
    ]);
  }

  async getContentList(
    params: contentsPaginationParams
  ): Promise<PaginatedList<ContentListDto>> {
    const result = await this.repositoryWrapper.Contents.getContentList(params);
    return result.result;
  }

  async getContentMenuList(
    id: number,
    isForContent: boolean,
    type: number,
  ): Promise<MenuListDto[]> {
    const result = await this.repositoryWrapper.Contents.getContentMenuList(
      id,
      isForContent,
      type
    );
    return result.result;
  }

  async getContentTabList(
    id: number,
    isForContent: boolean
  ): Promise<TabsListDto[]> {
    const result = await this.repositoryWrapper.Contents.getContentTabList(
      id,
      isForContent
    );
    return result.result;
  }

  async getContentTopicsList(
    id: number,
    isForContent: boolean
  ): Promise<TopicsListDto[]> {
    const result = await this.repositoryWrapper.Contents.getContentTopicsList(
      id,
      isForContent
    );
    return result.result;
  }

  async addContents(data: ContentListDto): Promise<ContentListDto> {
    const result = await this.repositoryWrapper.Contents.addContents(data);
    return result.result;
  }

  async addMenuList(data: MenuListDto): Promise<MenuListDto> {
    const result = await this.repositoryWrapper.Contents.addMenuList(data);
    return result.result;
  }

  async addTabList(data: TabsListDto): Promise<TabsListDto> {
    const result = await this.repositoryWrapper.Contents.addTabList(data);
    return result.result;
  }

  async addTopicList(data: TopicsListDto): Promise<TopicsListDto> {
    const result = await this.repositoryWrapper.Contents.addTopicList(data);
    return result.result;
  }

  async editContents(data: ContentListDto): Promise<ContentListDto> {
    const result = await this.repositoryWrapper.Contents.editContents(data);
    return result.result;
  }

  async editMenuList(data: MenuListDto): Promise<MenuListDto> {
    const result = await this.repositoryWrapper.Contents.editMenuList(data);
    return result.result;
  }

  async editTabList(data: TabsListDto): Promise<TabsListDto> {
    const result = await this.repositoryWrapper.Contents.editTabList(data);
    return result.result;
  }

  async editTopicList(data: TopicsListDto): Promise<TopicsListDto> {
    const result = await this.repositoryWrapper.Contents.editTopicList(data);
    return result.result;
  }

  async toggleContentsStatus(userId): Promise<number> {
    const result = await this.repositoryWrapper.Contents.toggleContentsStatus(
      userId
    );
    return result.result;
  }
  async deleteContents(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Contents.deleteContents(id);
    return result.result;
  }

  async deleteMenuList(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Contents.deleteMenuList(id);
    return result.result;
  }
  async deleteTabList(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Contents.deleteTabList(id);
    return result.result;
  }
  async deleteTopicList(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Contents.deleteTopicList(
      id
    );
    return result.result;
  }

  async getAllContents(type: ContentTypeEnum): Promise<ContentListDto[]> {
    const result = await this.repositoryWrapper.Contents.getAllContents(type);
    return result.result;
  }

    
  async getContentUserPermissions(
    jouranlId: number
  ): Promise<UserPermissionDto[]> {
    const result = await this.repositoryWrapper.Contents.getContentUserPermissions(
      jouranlId
    );
    return result.result;
  }


  async addUserPermission(data: AddUserPermissionDto): Promise<UserPermissionDto> {
    const result = await this.repositoryWrapper.Contents.addUserPermission(data);
    return result.result;
  }
  async deleteUserPermission(userPermissinId: number): Promise<number> {
    const result = await this.repositoryWrapper.Contents.deleteUserPermission(userPermissinId);
    return result.result;
  }

}
