import React,{useState} from "react";
import {Tabs} from "antd";
import PermissionAdd from "./PermissionAdd";
import PermissionList from "./PermissionList";
import { useAuth } from "src/authentication";
const TabPane = Tabs.TabPane;


const JournalPermissionsPage = (props) => {
  const [activeTab , setActiveTab] = useState("1");
  const [journalPermissionsEditData , setJournalPermissionsEditData] = useState(null);
  const { authUser, isLoadingUser } = useAuth();
  const callback = (key , data = null) => {
    setActiveTab(key);
    if(data && key === "2") {
      setJournalPermissionsEditData(data);
    } else if(key === "2") {
      setJournalPermissionsEditData(null);
    }
  };

  return (
      <Tabs  activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
          <PermissionList userInfo = {authUser?.userInfo} changeTabEvent = {callback} journalData = {props.data}/>
        </TabPane>
        <TabPane  tab="Add Permission" key="2">
        <PermissionAdd  userInfo = {authUser?.userInfo} fields = {journalPermissionsEditData}  journalData = {props.data}/>
        </TabPane>
      </Tabs>
  );
};

export default JournalPermissionsPage;
