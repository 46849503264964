import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload,Checkbox } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAddWebHomeCollection } from "src/hooks/services/baseData/useAddWebHomeCollection";
import { useEditWebHomeCollection } from "src/hooks/services/baseData/useEditWebHomeCollection";

const Add = ({ fields, editSection = false, changeTabEvent }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddHomeData, isLoading:loadingSave  } = useAddWebHomeCollection();
  const { mutate: onEditHomeData, isLoading:loadingEdit  } = useEditWebHomeCollection();
  const [formData, setFormData] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  useEffect(() => {
    if (fields) {
      const finalData = [];
      for (var key in fields) {
        if (fields.hasOwnProperty(key)) {
          let data = {
            name: [],
            value: "",
          };
          data.name.push(key);
          data.value = fields[key];
          
          finalData.push(data);
        }
      }
      setFormData(finalData);
      if(fields.iconUrl) {
        var imageFile = [{
          uid: '-1',
          name: 'image',
          status: 'done',
          url: fields.iconUrl,
        }];
        setImageList(imageFile);
      }

    }
    return () => {
      form.resetFields();
      setFormData([]);
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection,form]);

  const saveHomeData = (values) => {
    const formData = new FormData();

    if(imageFile) {
      formData.append("Image", imageFile);
    }

    if (editSection) {
      values.webHomeDataCollectionId = fields.webHomeDataCollectionId;
      formData.append("HomeCollection", JSON.stringify(values));
      onEditHomeData(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      formData.append("HomeCollection", JSON.stringify(values));
      onAddHomeData(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      var imageFile = [{
        uid: '-2',
        name: 'image',
        status: 'done',
        url: reader.result,
      }];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);

  }

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveHomeData"
        onFinish={saveHomeData}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input Title!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Icon"
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
             {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item name="isActive" valuePropName="checked">
          <Checkbox>active</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
