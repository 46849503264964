import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndexTypesService } from "src/apis/services/indexTypes/indexTypesService";
import { container } from "tsyringe";

export const useGetIndexTypes = () => {
    const indexTypesService = container.resolve(IndexTypesService);

    return useQuery({
        queryKey: [Api.IndexTypes.getIndexTypes],
        queryFn: () => {
            return indexTypesService.getIndexTypes();
        }
    })
}
