import React, { useState } from "react";
import {
  Table,
  Modal,
  Space,
  Typography,
} from "antd";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetSentMessagesList } from "src/hooks/services/messages/useGetSentMessagesList";
const { Text } = Typography;
const { Column } = Table;
const List = (props) => {
  const [page, setPage] = useState(1);
  const { data: messagesListData, isLoading } = useGetSentMessagesList(page);

  const [selectedModalData, setSelectedModalData] = useState({
    value: null,
  });


  const changePage = (page) => {
    setPage(page);
  };

  const getSenderRole = (roleId) => {
    switch (roleId) {
      case UserGroupEnum.Admin:
        return "Admin";
      case UserGroupEnum.Assistant:
        return "Assistant";
      case UserGroupEnum.EditorInChief:
        return "Editor In Chief";
      default:
        return "";
    }
  }

  const readMessage = (data) => {
    if (data) {
      setSelectedModalData({
        value: { ...data },
      });
    }

  };

  const closeModal = () => {
    setSelectedModalData({
      value: null,
    });
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={messagesListData?.items}
        pagination={{
          defaultPageSize: PageSize.tables,
          total: messagesListData?.totalCount,
          onChange: changePage,
          showSizeChanger:false
        }}
        rowKey="messageId"
      >
        <Column
          title="Sender Email"
          dataIndex="senderEmail"
          key="senderEmail"
        />
        <Column title="Sender Full Name" dataIndex="senderFullName" key="senderFullName" />
        <Column title="Receiver Email" dataIndex="receiverEmail" key="receiverEmail" />
        <Column title="Subject" dataIndex="subject" key="subject" />
        <Column
          title="Action"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => readMessage(record)}>Read Message</a>
            </Space>
          )}
        />
      </Table>
       {/* <NotificationContainer /> */}

      <Modal
        title="message"
        centered
        visible={selectedModalData.value}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {selectedModalData.value ? (
          <div dangerouslySetInnerHTML={{__html: selectedModalData.value.text}}></div>
        ) : null}
      </Modal>
    </>
  );
};

export default List;
