import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { ArticlesTypeDto } from "src/data/models/articleDtos/articlesTypeDto.interface";
import { container } from "tsyringe";

export const useAddArticleType = () => {
    const articlesService = container.resolve(ArticlesService);
    const queryClient = useQueryClient();

    const toggleAdd = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<ArticlesTypeDto[]>([Api.Articles.getArticleTypes]);
            const finalData = [...tempData] ;
            finalData.push(data);
            queryClient.setQueryData([Api.Articles.getArticleTypes],finalData )
        },
        [],
    );

    return useMutation({
        mutationFn: (data: ArticlesTypeDto) => {
            return articlesService.addArticleType(data);
        },
        onSuccess:toggleAdd
    })

}
