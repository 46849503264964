import { useState } from "react";
import { Layout, Menu, message, Popover } from "antd";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import languageData from "../languageData";
import UserInfo from "components/UserInfo";
import HorizontalNav from "../HorizontalNav";
import { Link } from "react-router-dom";
import {
  switchLanguage,
  toggleCollapsedSideNav,
} from "../../../appRedux/actions";
import { useGetNewMessagesCount } from "src/hooks/services/messages/useGetNewMessagesCount";
import { TAB_SIZE } from "../../../constants/ThemeSetting";
import { useAuth } from "../../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
const { Header } = Layout;

const menu = (
  <Menu onClick={handleMenuClick}>
    <Menu.Item key="1">Products</Menu.Item>
    <Menu.Item key="2">Apps</Menu.Item>
    <Menu.Item key="3">Blogs</Menu.Item>
  </Menu>
);

function handleMenuClick(e) {
  message.info("Click on menu item.");
}

const InsideHeader = () => {
  const [searchText, setSearchText] = useState("");
  const { locale } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const dispatch = useDispatch();
  const { data: messageCount, isLoading: isLoadingCount } = useGetNewMessagesCount();
  const languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map((language) => (
          <li
            className="gx-media gx-pointer"
            key={JSON.stringify(language)}
            onClick={(e) => dispatch(switchLanguage(language))}
          >
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`} />
            <span className="gx-language-text">{language.name}</span>
          </li>
        ))}
      </ul>
    </CustomScrollbars>
  );

  const updateSearchChatUser = (evt) => {
    setSearchText(evt.target.value);
  };
  const { authUser, isLoadingUser } = useAuth();

  const userInfo = () => {
    switch (authUser?.userInfo.role) {
      case UserGroupEnum.Admin:
        return <span>Admin User</span>;
      case UserGroupEnum.Assistant:
        return (
          <span>
            {"Welcome " + authUser?.userInfo.name + " " + authUser?.userInfo.family + " ( Assistant ) "  }
          </span>
        );
      case UserGroupEnum.EditorInChief:
        return (
          <span>
            {"Welcome " + authUser?.userInfo.name + " " + authUser?.userInfo.family + " ( Editor In Chief ) " }
          </span>
        );
        case UserGroupEnum.ConferenceEditor:
          return (
            <span>
              {"Welcome " + authUser?.userInfo.name + " " + authUser?.userInfo.family + " ( Conference Editor ) " }
            </span>
          );
      default:
        break;
    }
  };

  return (
    <div className="gx-header-horizontal gx-header-horizontal-dark gx-inside-header-horizontal">
      <div className="gx-header-horizontal-top">
        <div className="gx-container">
          <div className="gx-header-horizontal-top-flex">
            <div className="gx-header-horizontal-top-left">
              <i className="icon icon-alert gx-mr-3" />
              <p className="gx-mb-0 gx-text-truncate">{userInfo()}</p>
            </div>
          </div>
        </div>
      </div>
      <Header className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
              <i
                className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />
            </div>
            <Link
              to="/"
              className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
            >
              <img alt="" src="/assets/images/logo.png" style={{width: "60px"}}/>
            </Link>
            <Link
              to="/"
              className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo gx-header-logo"
            >
              <img alt="" src="/assets/images/logo.png" />
            </Link>

            {width >= TAB_SIZE && (
              <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve" style={{width:"600px"}}>
                <HorizontalNav />
              </div>
            )}

            <ul className="gx-header-notifications gx-ml-auto">
              {/* <li className="gx-notify">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                  trigger="click"
                >
                  <span className="gx-pointer gx-d-block">
                    <i className="icon icon-notification" />
                  </span>
                </Popover>
              </li> */}

              <li className="gx-msg">
                <Link to="/messages">
                <Popover
                  overlayClassName="gx-popover-horizantal"
                  placement="bottomRight"
                >
                  <span className="gx-pointer gx-status-pos gx-d-block">
                    <i className="icon icon-chat-new" />
                    {messageCount > 0 && (
                      <span className="gx-status gx-status-rtl gx-small gx-orange" />
                    )}
                  </span>
                </Popover>
                </Link>

              </li>
              <li className="gx-user-nav">
                <UserInfo />
              </li>
            </ul>
          </div>
        </div>
      </Header>
    </div>
  );
};

export default InsideHeader;
