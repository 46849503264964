import { useMutation,useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { JournalsService } from "src/apis/services/journals/journalsService";
import { UserPermissionDto } from "src/data/models/usersDtos/userPermissionDto.interface";

export const useDeleteJournalUserPermission = (id) => {
    const journalsService = container.resolve(JournalsService);

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (journalUserPermissionId) => {
            let tempObject = queryClient.getQueryData<UserPermissionDto[]>([Api.Journals.getJournalUserPermissions,id])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.id !== journalUserPermissionId)
            queryClient.setQueryData([Api.Journals.getJournalUserPermissions,id], finalData)
        },
        [id],
    )

    return useMutation({
        mutationFn: (journalUserPermissionId: number) => {
            return journalsService.deleteUserPermission(journalUserPermissionId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}