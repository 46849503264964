import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { IndicatorDto } from "src/data/models/indicatorDtos/idicatorDto.interface";
import BaseRepository from "../Base/BaseRepository";
import IIndicatorsRepository from "./IIndicatorsRepository";

export default class IndicatorsRepository extends BaseRepository implements IIndicatorsRepository {

  async getIndicators(): Promise<IGenericResponse<IndicatorDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.Indicators.getIndicators)

    return await request
      .GetResultAsync()
  }

  async addIndicators(body: IndicatorDto): Promise<IGenericResponse<IndicatorDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Indicators.addIndicators)

    return await request
      .GetResultAsync()
  }

  async editIndicators(body: IndicatorDto): Promise<IGenericResponse<IndicatorDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Indicators.editIndicators)

    return await request
      .GetResultAsync()
  }
  async deleteIndicators(indicatorId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.Indicators.deleteIndicators, { indicatorId })

    return await request
      .GetResultAsync()
  }
}
