import { useQuery } from "react-query";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { Api } from "src/apis/helpers/configs/api";

export const useGetArticleContent = (articleId) => {
  const articlesService = container.resolve(ArticlesService);
  return useQuery({
    queryKey: [Api.Articles.getArticleContent,articleId],
    queryFn: () => {

        return articlesService.getArticleContent(articleId);
    }
})
};
