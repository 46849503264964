import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import BaseRepository from "../Base/BaseRepository";
import INewsRepository from "./INewsRepository";

export default class NewsRepository extends BaseRepository implements INewsRepository {

  async getNews(params: PaginationParams): Promise<IGenericResponse<PaginatedList<NewsDto>>> {

    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value)
    }

    const request = result
      .setMethod('get')
      .setUrl(Api.News.getNews)

    return await request
      .GetResultAsync();
  }
  async addNews(body: NewsDto): Promise<IGenericResponse<NewsDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.News.addNews)

    return await request
      .GetResultAsync()
  }
  async editNews(body: NewsDto): Promise<IGenericResponse<NewsDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.News.editNews)

    return await request
      .GetResultAsync();
  }

  async toggleNewsStatus(newsId): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(newsId)
      .setUrl(Api.News.toggleNewsStatus)

    return await request
      .GetResultAsync();
  }

  async deleteNews(newsId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.News.deleteNews, { newsId })

    return await request
      .GetResultAsync()
  }



}
