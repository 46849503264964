import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";

export const useDeleteSlider = () => {
    const baseDataService = container.resolve(BaseDataService);

    const queryClient = useQueryClient();

    const deleteSlider = useCallback(
        (targetSliderId) => {
            let tempObject = queryClient.getQueryData<SliderDto[]>([Api.BaseData.getSliders])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.sliderId !== targetSliderId)
            queryClient.setQueryData([Api.BaseData.getSliders], finalData)
        },
        [],
    )

    return useMutation({
        mutationFn: (sliderId: number) => {
            return baseDataService.deleteSlider(sliderId);
        },
        onSuccess: deleteSlider,

        onError: (err) => {
        }
    })
}
