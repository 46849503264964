import { useMutation } from "react-query";
import { container } from "tsyringe";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";

export const useAddEditorFiles = () => {
    const baseDataService = container.resolve(BaseDataService);
    return useMutation({
        mutationFn: (data: any) => {
            return baseDataService.addEditorFiles(data);
        },
    })

}
