import { MessagesDto } from "src/data/models/messagesDtos/MessagesDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class MessagesService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getMessagesList",
      "getNewMessagesList",
      "sendMessage",
      "readMessage",
      "getNewMessagesCount",
      "getSentMessagesList"
      ]);
    this.setMiddlware(this.handleToken, [
      "getMessagesList",
      "getNewMessagesList",
      "sendMessage",
      "readMessage",
      "getNewMessagesCount",
      "getSentMessagesList"
    ]);
  }

  async getMessagesList(params: PaginationParams): Promise<PaginatedList<MessagesDto>> {
    const result = await this.repositoryWrapper.Messages.getMessagesList(params);
    return result.result;
  }

  async getSentMessagesList(params: PaginationParams): Promise<PaginatedList<MessagesDto>> {
    const result = await this.repositoryWrapper.Messages.getSentMessagesList(params);
    return result.result;
  }


  async getNewMessagesList(): Promise<MessagesDto[]> {
    const result = await this.repositoryWrapper.Messages.getNewMessagesList();
    return result.result;
  }


  async getNewMessagesCount(): Promise<number> {
    const result = await this.repositoryWrapper.Messages.getNewMessagesCount();
    return result.result;
  }


  async sendMessage(data: MessagesDto): Promise<MessagesDto> {
    const result = await this.repositoryWrapper.Messages.sendMessage(data);
    return result.result;
  }


  async readMessage(messageId): Promise<number> {
    const result = await this.repositoryWrapper.Messages.readMessage(messageId);
    return result.result;
  }

}
