import React from "react";
import { Divider, Table, Modal, Avatar, Image } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useGetContactUs } from "src/hooks/services/baseData/useGetContactUs";

const confirm = Modal.confirm;

const List = (props) => {
  const { data: ContactUs, isLoading } = useGetContactUs();


  const columns = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Subject",
      dataIndex: "subject",
      key: "subject",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "DateTime",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (text) => <span>
        {text && text.split("T")[0]}
      </span>
    }
  ];

  return (
    <>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={ContactUs}
        rowKey="id"
        pagination={false}
      />
    </>
  );
};

export default List;
