import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndexTypesService } from "src/apis/services/indexTypes/indexTypesService";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";


export const useEditIndexTypes = () => {
    const indexTypesService = container.resolve(IndexTypesService);
    const queryClient = useQueryClient();
    const toggleEditIndexType = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<IndexTypesDto[]>([Api.IndexTypes.getIndexTypes]);
            const indexTypeData = [...tempData] ;
            const findIndex = indexTypeData.findIndex(x=>x.id === data.id) ;
            indexTypeData[findIndex] = data;
            queryClient.setQueryData([Api.IndexTypes.getIndexTypes],indexTypeData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: IndexTypesDto) => {
            return indexTypesService.editIndexTypes(data);
        },
        onSuccess: toggleEditIndexType
    })

}
