import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
import { useAuth } from "../../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
import { ContentTypeEnum } from "../../../data/enums/contentType.enum";
const TabPane = Tabs.TabPane;

const ContentsPage = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [contentsEditData, setContentsEditData] = useState(null);
  const [contentsEditFlag, setContentsEditFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [contentType, setContentType] = useState({});
  const { authUser, isLoadingUser } = useAuth();
  const callback = (key, data = null, currentPage = 1) => {
    setActiveTab(key);
    if (data && key === "2") {
      setContentsEditData(data);
      setContentsEditFlag(true);
      setCurrentPage(currentPage);
    } else if (key === "2") {
      setContentsEditFlag(false);
      setContentsEditData(null);
    }
  };

  useEffect(() => {
    let type = {
      id: 0,
      name: "",
    };

    switch (props.match.params.type) {
      case ContentTypeEnum[ContentTypeEnum.Book].toLowerCase():
        type = {
          id: ContentTypeEnum.Book,
          name: props.match.params.type + "s",
        };
        setContentType(type);
        break;
      case ContentTypeEnum[ContentTypeEnum.Course].toLowerCase():
        type = {
          id: ContentTypeEnum.Course,
          name: props.match.params.type + "s",
        };
        setContentType(type);
        break;
      case ContentTypeEnum[ContentTypeEnum.Conference].toLowerCase():
        type = {
          id: ContentTypeEnum.Conference,
          name: props.match.params.type + "s",
        };
        setContentType(type);
        break;

      default:
        props.history.push("/main/dashboard");
    }

    return () => {
      setContentsEditFlag(false);
      setContentsEditData(null);
      setActiveTab("1");
    };
  }, [props.match.params.type]);

  return (
    <Card className="gx-card" title={contentType.name + " list"}>
      {authUser?.userInfo.role === UserGroupEnum.Admin ? (
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="List" key="1">
            {contentType.id ? (
              <List changeTabEvent={callback} contentType={contentType} />
            ) : null}
          </TabPane>
          <TabPane tab={"Add / Edit " + contentType.name} key="2">
            {contentType.id ? (
              <Add
                fields={contentsEditData}
                editSection={contentsEditFlag}
                changeTabEvent={callback}
                currentPage={currentPage}
                contentType={contentType}
              />
            ) : null}
          </TabPane>
        </Tabs>
      ) : (
        <List changeTabEvent={callback} contentType={contentType} />
      )}
    </Card>
  );
};

export default ContentsPage;
