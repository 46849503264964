import { useMutation } from "react-query";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
export const useDeleteArticle = () => {
    const articlesService = container.resolve(ArticlesService);

    return useMutation({
        mutationFn: (id: number) => {
            return articlesService.deleteArticle(id);
        },
    })
}