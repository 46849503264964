import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Upload,
  Select,
  InputNumber,
  Radio,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";

import Editor from 'src/components/Editor';
import { useAddJournals } from "src/hooks/services/journals/useAddJournals";
import { useEditJournals } from "src/hooks/services/journals/useEditJournals";
import { useGetSubjects } from "src/hooks/services/subjects/useGetSubjects";
import { useGetArticleType } from "src/hooks/services/articles/type/useGetArticleType";
const { TextArea } = Input;
const { Option } = Select;
const Add = ({ fields, editSection = false, changeTabEvent }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { data: subjectsData, isLoading: isLoadingSubject } = useGetSubjects();
  const { mutate: onAddJournals, isLoading: loadingSave } = useAddJournals();
  const { mutate: onEditJournals, isLoading: loadingEdit } = useEditJournals();
  const [formData, setFormData] = useState([]);
  const [journalDescription, setJournalDescription] = useState("");
  const [journalDescriptionDefault, setJournalDescriptionDefault] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  const { data: articleTypesData, isLoading: loadingType } =
    useGetArticleType();
  const [count, setCount] = React.useState(0);

  useEffect(() => {
    if (fields) {
      if (fields.journalArticleTypes.length > 0) {
        fields.articleType = [];
        fields.journalArticleTypes.forEach((element) => {
          fields.articleType.push(element.fkArticleTypeId);
        });
      }
      form.setFieldsValue(fields);
      setJournalDescription(fields.description);
      setJournalDescriptionDefault(fields.description);
      var imageFile = [
        {
          uid: "-1",
          name: "image",
          status: "done",
          url: fields.imageUrl,
        },
      ];
      setImageList(imageFile);
    }
    return () => {
      form.resetFields();
      setJournalDescription("");
      setJournalDescriptionDefault("");
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection, form]);

  const saveJournals = (values) => {
    if (!journalDescription) {
      return;
    }

    if (imageFile === null && !editSection) {
      return;
    }

    if (values.articleType.length !== 0) {
      var allType = [];
      values.articleType.forEach((element) => {
        var type = {
          journalArticleTypeId: 0,
          fkJournalId: editSection ? fields.jid : 0,
          fkArticleTypeId: element,
        };
        allType.push(type);
      });
    }
    values.journalArticleTypes = allType;
    values.description = journalDescription;
    const formData = new FormData();
    formData.append("Image", imageFile);
    if (editSection) {
      values.jid = fields.jid;
      formData.append("Journal", JSON.stringify(values));
      onEditJournals(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err: any) => {
          toast.error(err?.response.data.message);
        },
      });
    } else {
      formData.append("Journal", JSON.stringify(values));

      onAddJournals(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setJournalDescription("");
          setJournalDescriptionDefault("");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err: any) => {
          toast.error(err?.response.data.message);
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var imageFile = [
        {
          uid: "-2",
          name: "image",
          status: "done",
          url: reader.result,
        },
      ];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveJournals"
        onFinish={saveJournals}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: "Please input Journal Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="shortInfo"
          label="Short Info"
          rules={[
            {
              required: true,
              message: "Please input short info!",
            },
          ]}
        >
          <TextArea
            showCount
            autoSize={{ minRows: 5, maxRows: 8 }}
            maxLength={150}
            onChange={(e) => setCount(e.target.value.length)}
          />
        </Form.Item>
        <Form.Item label="Description" required>

          <Editor value={journalDescriptionDefault} 
          onChange={ ( data, editor  ) => {
            setJournalDescription(data);
        } }/>

        </Form.Item>

        <Form.Item
          name="subjectId"
          label="Subject"
          rules={[
            {
              required: true,
              message: "Please Select Journal Subject!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Journal Subject"
            optionFilterProp="children"
          >
            {!isLoadingSubject
              ? subjectsData.map((data) => {
                  return (
                    <Option key={data.id} value={data.id}>
                      {data.name}
                    </Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>

        <Form.Item label="Submit Link" name="submitLink">
          <Input />
        </Form.Item>

        <Form.Item name="pissn" label="pISSN">
          <Input />
        </Form.Item>

        <Form.Item name="eissn" label="eISSN">
          <Input />
        </Form.Item>

        <Form.Item
          label="Article Type"
          name="articleType"
          rules={[
            {
              required: true,
              message: "Please Select Article Type!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Article Type"
            optionFilterProp="children"
            mode="multiple"
            allowClear
          >
            {!loadingType &&
              articleTypesData.map((data) => {
                return (
                  <Option key={data.id} value={data.id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: "Please input Priority!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Image" required>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
            {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item name="hasIssue" label="Select publishing format : ">
          <Radio.Group>
            <Radio value={false}>Volume</Radio>
            <Radio value={true}> Volume, Issue </Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>active</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
