import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { UsersService } from "src/apis/services/users/usersService";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";

export const useEditUsers = (currentPage) => {
    const usersService = container.resolve(UsersService);
    const queryClient = useQueryClient();
    const toggleEditUser = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<UsersDto>>([Api.Users.getUsers,currentPage]);
            for (let index = 1; index <= tempData?.totalPages; index++) {
                queryClient.removeQueries([Api.Users.getUsers , index]);
            }
        },
        [currentPage],
    );
    return useMutation({
        mutationFn: (data: UsersDto) => {
            return usersService.editUsers(data);
        },
        onSuccess: toggleEditUser
    })

}
