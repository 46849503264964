import React, { useState } from "react";
import { Card, Tabs } from "antd";
import List from './list/List';
import Add from './add/Add';
const TabPane = Tabs.TabPane;


const FooterLinkPage = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [articleTypeEditData, setArticleTypeEditData] = useState(null);
  const [articleTypeEditFlag, setArticleTypeEditFlag] = useState(false);
  const callback = (key, data = null) => {
    setActiveTab(key);
    if (data && key === "2") {
      setArticleTypeEditData(data);
      setArticleTypeEditFlag(true);
    } else if (key === "2") {
      setArticleTypeEditFlag(false);
      setArticleTypeEditData(null);
    }
  };

  return (
      <Tabs activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
          <List changeTabEvent={callback} footerLinksData = {props.data.footerLinks}/>
        </TabPane>
        <TabPane tab="Add / Edit Footer Link" key="2">
          <Add fields={articleTypeEditData} editSection={articleTypeEditFlag} changeTabEvent={callback} columnId = {props.data.id}/>
        </TabPane>
      </Tabs>
  
  );
};

export default FooterLinkPage;
