import React,{useState} from "react";
import {Tabs} from "antd";
import IndexTypeAdd from "./IndexTypeAdd";
import IndexTypeList from "./IndexTypeList";
const TabPane = Tabs.TabPane;


const JournalIndexTypesPage = (props) => {
  const [activeTab , setActiveTab] = useState("1");
  const [journalIndexTypesEditData , setJournalIndexTypesEditData] = useState(null);
  const callback = (key , data = null) => {
    setActiveTab(key);
    if(data && key === "2") {
      setJournalIndexTypesEditData(data);
    } else if(key === "2") {
      setJournalIndexTypesEditData(null);
    }
  };

  return (
      <Tabs  activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
          <IndexTypeList changeTabEvent = {callback} journalData = {props.data}/>
        </TabPane>
        <TabPane  tab="Add / Edit index type" key="2">
        <IndexTypeAdd fields = {journalIndexTypesEditData}  changeTabEvent = {callback} journalData = {props.data}/>
        </TabPane>
      </Tabs>
  );
};

export default JournalIndexTypesPage;
