import React, { useState } from "react";
import { Divider, Table, Modal, Form, Input, Button } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";

import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";
import { useGetFooterColumn } from "src/hooks/services/baseData/useGetFooterColumn";
import FooterLink from "../link";
import { useEditFooterColumn } from "src/hooks/services/baseData/useEditFooterColumn";

const confirm = Modal.confirm;

const List = (props) => {
  const { data: footerColumn, isLoading } = useGetFooterColumn();
  const { mutate: onEditFooterColumn, isLoading:loadingEdit } = useEditFooterColumn();
  const [visibleFooterLink, setFooterLink] = useState(false);
  const [visibleFooterEdit, setFooterEdit] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <span onClick={() => footerModal(record, "edit")} className="gx-link">
            Edit
          </span>
          <Divider type="vertical" />
          <span
            onClick={() => footerModal(record, "footerLink")}
            className="gx-link"
          >
            Add Footer Link
          </span>
        </span>
      ),
    },
  ];

  const footerModal = (data, type) => {
    setSelectedModalData(data);
    form.setFieldsValue(data);
    
    switch (type) {
      case "footerLink":
        setFooterLink(true);
        break;
      case "edit":
        setFooterEdit(true);
        break;
      default:
        break;
    }
  };

  const editData = (values) => {
    values.id = selectedModalData.id;
    onEditFooterColumn(values, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />
        );
        form.resetFields();
        setFooterEdit(false);
      },
      onError: (err) => {
        toast.error(
          <IntlMessages id="notification.errorMessage" />
        );
      },
    });
  };

  
  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={footerColumn}
        rowKey="id"
        pagination={false}
      />
       {/* <NotificationContainer /> */}

      <Modal
        title="Add Footer Link"
        centered
        visible={visibleFooterLink}
        footer={null}
        onCancel={() => setFooterLink(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleFooterLink ? <FooterLink data={selectedModalData} /> : null}
      </Modal>

      <Modal
        title="Edit"
        centered
        visible={visibleFooterEdit}
        footer={null}
        onCancel={() => setFooterEdit(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleFooterEdit ? (
          <Form
            {...formItemLayout}
            form={form}
            initialValues={{
              layout: formLayout
            }}
            fields={formData}
            onFinish={editData}
            autoComplete="off"
          >
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loadingEdit}
                type="primary"
                htmlType="submit"
              >
                Edit
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </Modal>
    </>
  );
};

export default List;
