import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { SubjectsService } from "src/apis/services/subjects/subjectsService";
import { SubjectDto } from "src/data/models/subjectDtos/subjectDto.interface";
import { container } from "tsyringe";

export const useAddSubjects = () => {
    const subjectsService = container.resolve(SubjectsService);
    const queryClient = useQueryClient();

    const toggleAddSubject = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<SubjectDto[]>([Api.Subjects.getSubjects]);
            const finalData = [...tempData] ;
            finalData.push(data);
            queryClient.setQueryData([Api.Subjects.getSubjects],finalData )
        },
        [],
    );

    return useMutation({
        mutationFn: (data: SubjectDto) => {
            return subjectsService.addSubjects(data);
        },
        onSuccess:toggleAddSubject
    })

}
