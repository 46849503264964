import { useQuery } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";

export const useGetJournalUserPermissions = (jouranlId) => {
    const journalsService = container.resolve(JournalsService);
    return useQuery({
        queryKey: [Api.Journals.getJournalUserPermissions,jouranlId],
        queryFn: () => {
            return journalsService.getJournalUserPermissions(jouranlId);
        }
    })
}
