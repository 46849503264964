import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { JournalDto } from "src/data/models/journalDtos/journalDto.interface";

export const useToggleJournalActivation = () => {
    const journalsService = container.resolve(JournalsService);
    
    const queryClient = useQueryClient()

    const toggleJournalsStatus = useCallback(
        (targetJournals) => {
            let lastData = queryClient.getQueryData<JournalDto[]>([Api.Journals.getJournalList]);
            if (lastData === undefined) return

            const findIndex = lastData.findIndex(item => item.jid === targetJournals.id);
            lastData[findIndex].active = !lastData[findIndex].active ;
            queryClient.setQueryData([Api.Journals.getJournalList], lastData)
        },
        [],
    )

    return useMutation({
        mutationFn: (journalsId) => {
            return journalsService.toggleJournalActivation(journalsId);
        },
        onMutate: toggleJournalsStatus,
        onError: (err) => {
        }
    })
}