import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Upload } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import Editor from "src/components/Editor";
import { useAddTopic } from "src/hooks/services/contents/topic/useAddTopic";
import { useEditTopic } from "src/hooks/services/contents/topic/useEditTopic";
import { useGetTopic } from "src/hooks/services/contents/topic/useGetTopic";
const { Option } = Select;
const TopicAdd = ({
  fields,
  editSection = false,
  changeTopicEvent,
  journalData,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const [descContent, setDescContent] = useState("");
  const [descContentDefault, setDescContentDefault] = useState("");
  const { mutate: onAddTopic, isLoading: loadingSave } = useAddTopic(
    journalData.jid,
    false
  );
  const { mutate: onEditTopic, isLoading: loadingEdit } = useEditTopic(
    journalData.jid,
    false
  );
  const { data: topicData, isLoading } = useGetTopic(journalData.jid, false);
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
      if (fields.topicDescription) {
        setDescContent(fields.topicDescription);
        setDescContentDefault(fields.topicDescription);
      }
      if (fields.topicImage) {
        var imageFile = [
          {
            uid: "-1",
            name: "image",
            status: "done",
            url: fields.topicImage,
          },
        ];
        setImageList(imageFile);
      }
    }
    return () => {
      form.resetFields();
      setDescContent("");
      setDescContentDefault("");
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection, form]);

  const saveTopic = (values) => {
    values.topicDescription = descContent;
    values.jouranlId = journalData.jid;
    const formData = new FormData();
    formData.append("Image", imageFile);
    if (editSection) {
      values.id = fields.id;
      formData.append("TopicsList", JSON.stringify(values));
      onEditTopic(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          changeTopicEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      formData.append("TopicsList", JSON.stringify(values));
      onAddTopic(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setDescContent("");
          setDescContentDefault("");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var imageFile = [
        {
          uid: "-2",
          name: "image",
          status: "done",
          url: reader.result,
        },
      ];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveTopic}
        autoComplete="off"
      >
        <Form.Item
          label="Topic Title"
          name="topicTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Topic Sub Title" name="topicSubTitle">
          <Input />
        </Form.Item>

        <Form.Item label="Description">
          <Editor
            value={descContentDefault}
            onChange={(data, editor ) => {
              setDescContent(data);
            }}
          />
        </Form.Item>
        <Form.Item label="Parent Topic" name="parentId">
          <Select
            placeholder="Select Parent Topic"
            optionFilterProp="children"
            allowClear
          >
            {topicData.map((data) => {
              return (
                <Option key={data.id} value={data.id}>
                  {data.topicTitle}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Image">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
            {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TopicAdd;
