import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Card } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../util/IntlMessages";
import { useGetProfile } from "src/hooks/services/users/useGetProfile";
import { useEditProfile } from "src/hooks/services/users/useEditProfile";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";

const Edit = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onEditProfile, isLoading:loadingEdit  } = useEditProfile();
  const [formData, setFormData] = useState([]);

  const { data: profileData, isLoading } = useGetProfile();


  useEffect(() => {
    if (!isLoading) {
      form.setFieldsValue(profileData);
    }
  }, [profileData]);


  const saveUser = (values: UsersDto) => {
    values.userId = profileData.userId;
    values.userName = profileData.userName;

    onEditProfile(values, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />
        );
      },
      onError: (err) => {
        toast.error(
          <IntlMessages id="notification.errorMessage" />
        );
      },
    });
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      }
      : null;

  return (
    <Card className="gx-card" title="Edit Profile">
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={saveUser}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="userName"
          label="E-mail"
          rules={[
            // {
            //   type: "email",
            //   message: "The input is not valid E-mail!",
            // },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First name"
          rules={[
            {
              required: true,
              message: "Please input your first name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last name"
          rules={[
            {
              required: true,
              message: "Please input your last name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item>
          <Button loading = {loadingEdit} type="primary" htmlType="submit">Edit</Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </Card>
  );
};

export default Edit;
