import IRepositoryWrapper from "./IRepositoryWrapper";
import { singleton } from "tsyringe";
import AuthenticateRepository from "../Authenticate/AuthenticateRepository";
import IAuthenticateRepository from "../Authenticate/IAuthenticateRepository";
import { environment } from "../../../environment/environment";
import IIndicatorsRepository from "../Indicators/IIndicatorsRepository";
import IndicatorsRepository from "../Indicators/IndicatorsRepository";
import IUsersRepository from "../Users/IUsersRepository";
import UsersRepository from "../Users/UsersRepository";
import INewsRepository from "../News/INewsRepository";
import NewsRepository from "../News/NewsRepository";
import IndexTypesRepository from "../IndexTypes/IndexTypesRepository";
import IIndexTypesRepository from "../IndexTypes/IInexTypesRepository";
import ContentsRepository from "../Contents/ContentsRepository";
import IContentsRepository from "../Contents/IContentsRepository";
import ISubjectsRepository from "../Subjects/ISubjectsRepository";
import SubjectsRepository from "../Subjects/SubjectsRepository";
import IBaseDataRepository from "../BaseData/IBaseDataRepository";
import BaseDataRepository from "../BaseData/BaseDataRepository";
import IJournalsRepository from "../Journals/IJournalsRepository";
import JournalsRepository from "../Journals/JournalsRepository";
import ArticlesRepository from "../Articles/ArticlesRepository";
import IArticlesRepository from "../Articles/IArticlesRepository";
import IMessagesRepository from "../Messages/IMessagesRepository";
import MessagesRepository from "../Messages/MessagesRepository";


@singleton()
// @ts-ignore
export default class RepositoryWrapper implements IRepositoryWrapper {
  private baseUrl = environment.baseUrl;
  private defaultHeaders: any = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };

  Authenticate: IAuthenticateRepository;
  Indicators: IIndicatorsRepository;
  Subjects: ISubjectsRepository;
  Users: IUsersRepository;
  News: INewsRepository;
  BaseData: IBaseDataRepository;
  IndexTypes: IIndexTypesRepository;
  Contents: IContentsRepository;
  Journals: IJournalsRepository;
  Articles: IArticlesRepository;
  Messages: IMessagesRepository;

  constructor() {
    this.Authenticate = new AuthenticateRepository(this.baseUrl, this.defaultHeaders, "Authenticate");
    this.Indicators = new IndicatorsRepository(this.baseUrl, this.defaultHeaders, "Indicators");
    this.Subjects = new SubjectsRepository(this.baseUrl, this.defaultHeaders, "Subjects");
    this.Users = new UsersRepository(this.baseUrl, this.defaultHeaders, "Users");
    this.News = new NewsRepository(this.baseUrl, this.defaultHeaders, "News");
    this.Messages = new MessagesRepository(this.baseUrl, this.defaultHeaders, "Messages");
    this.IndexTypes = new IndexTypesRepository(this.baseUrl, this.defaultHeaders, "IndexTypes");
    this.Contents = new ContentsRepository(this.baseUrl, this.defaultHeaders, "Contents");
    this.Journals = new JournalsRepository(this.baseUrl, this.defaultHeaders, "Journals");
    this.Articles = new ArticlesRepository(this.baseUrl, this.defaultHeaders, "Articles");
    this.BaseData = new BaseDataRepository(this.baseUrl, this.defaultHeaders, "Setting");
  }

  SetValueToHeader(key: string, value: string) {
    this.defaultHeaders[key] = value;
  }
}
