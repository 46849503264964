import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";

export const useDeleteWebHomeData = (currentPage,collectionId) => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const toggleDeleteNews = useCallback(
    (targetId) => {

      let tempObject = queryClient.getQueryData<PaginatedList<WebHomeDataDto>>([Api.BaseData.getWebHomeCollectionDataList , 1,collectionId]);
      for (let index = 1; index <= tempObject?.totalPages; index++) {
          queryClient.removeQueries([Api.BaseData.getWebHomeCollectionDataList, index, collectionId]);
          
      }
    },
    [collectionId],
  )

  return useMutation({
    mutationFn: (id: number) => {
      return baseDataService.deleteWebHomeData(id);
    },
    onSuccess: (data, targetId, context) => {
      toggleDeleteNews(targetId);
    }
  })
}
