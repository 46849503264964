import { useMutation,useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { UserPermissionDto } from "src/data/models/usersDtos/userPermissionDto.interface";
import { ContentsService } from "src/apis/services/contents/contentsService";

export const useDeleteUserPermission = (id) => {
    const contentsService = container.resolve(ContentsService);

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (userPermissionId) => {
            let tempObject = queryClient.getQueryData<UserPermissionDto[]>([Api.Contents.getContentUserPermissions,id])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.id !== userPermissionId)
            queryClient.setQueryData([Api.Contents.getContentUserPermissions,id], finalData)
        },
        [id],
    )

    return useMutation({
        mutationFn: (userPermissionId: number) => {
            return contentsService.deleteUserPermission(userPermissionId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}