import { useEffect, useState, FC } from 'react';
import {httpClient} from "../../../util/Api";
import {useLogin} from "../../../hooks/services/auth/useLogin";
import {useBaseQuery} from "../../../hooks/services/base/useBaseQuery";
import {useGetBaseInfo} from "../../../hooks/services/auth/useGetBaseInfo";
import { setInitUrl } from "../../../appRedux/actions";
import { useDispatch } from "react-redux";
export const useProvideAuth = () => {
  const { mutate: onLogin } = useLogin();
  const {mutate : onBaseInfo} = useGetBaseInfo();
  const [authUser, setAuthUser] = useState(null);
  const [error, setError] = useState('');
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const {clearQueries} = useBaseQuery();
  const fetchStart = () => {
    setLoading(true);
    setError('');
  };
  const dispatch = useDispatch();

  const fetchSuccess = () => {
    setLoading(false);
    setError('');
  };

  const fetchError = (error) => {
    setLoading(false);
    setError(error);
  };

  const userLogin = (user, callbackFun) => {
    fetchStart();
    clearQueries();
    onLogin(user, {
       onSuccess: (data) => {
        fetchSuccess();
        setAuthUser(data.result);
       },
       onError: (err) => {
        fetchError("Wrong username or password");
       }
    })

  };


  const renderSocialMediaLogin = () => null;

  const userSignOut = (callbackFun) => {
    dispatch(setInitUrl('/'));
    httpClient.defaults.headers.common['Authorization'] = '';
    localStorage.removeItem('token');
    setAuthUser(false);
  };

  useEffect(() => {
    onBaseInfo(null, {
      onSuccess: (data) => {
      setAuthUser(data.result);
       setLoadingUser(false);
      },
      onError: (err) => {
        setLoadingUser(false);
      }
   })

  }, []);

  // Return the user object and auth methods
  return {
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    userLogin,
    userSignOut,
    renderSocialMediaLogin
  };
};
