import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class IndexTypesService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getIndexTypes",
      "addIndexTypes",
      "editIndexTypes",
      "deleteIndexTypes"
    ]);
    this.setMiddlware(this.handleToken, [
      "getIndexTypes",
      "addIndexTypes",
      "editIndexTypes",
      "deleteIndexTypes"
    ]);
  }

  async getIndexTypes(): Promise<IndexTypesDto[]> {
    const result = await this.repositoryWrapper.IndexTypes.getIndexTypes();
    return result.result;
  }

  async addIndexTypes(data: IndexTypesDto): Promise<IndexTypesDto> {
    const result = await this.repositoryWrapper.IndexTypes.addIndexTypes(data);
    return result.result;
  }

  async editIndexTypes(data: IndexTypesDto): Promise<IndexTypesDto> {
    const result = await this.repositoryWrapper.IndexTypes.editIndexTypes(data);
    return result.result;
  }

  async deleteIndexTypes(indexTypeId: number): Promise<number> {
    const result = await this.repositoryWrapper.IndexTypes.deleteIndexTypes(indexTypeId);
    return result.result;
  }
}
