import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";
import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";

export const useDeleteFooterLink = () => {
  const baseDataService = container.resolve(BaseDataService);

  const queryClient = useQueryClient();

  const deleteFooterLink = useCallback(
    (data: FooterLinkDto) => {
      let tempObject = queryClient.getQueryData<FooterLinkDto[]>([Api.BaseData.getFooterLinks])

      if (tempObject === undefined) return

      const finalData = tempObject.filter(item => item.id !== data.id)
      queryClient.setQueryData([Api.BaseData.getFooterLinks], finalData)
    },
    [],
  )

  return useMutation({
    mutationFn: (id: number) => {
      return baseDataService.deleteFooterLink(id);
    },
    onSuccess: deleteFooterLink,

    onError: (err) => {
    }
  })
}
