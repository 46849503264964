import {useState,useEffect} from "react";
import { Button, Select, Form, Input, Menu } from "antd";
import { Link } from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../authentication";
import { ToastContainer, toast } from 'react-toastify';
const SignIn = () => {
  const { isLoading, error, userLogin } = useAuth();
  const [role , setRole] = useState(1);
  const onFinishFailed = (errorInfo) => {};
  let loading = false;
  const onFinish = (values) => {
    values.userGroupId = parseInt(role) ;
    userLogin(values);
  };

  const { Option } = Select;

  const handleChangeRoles = (value) => {
    if(value) {
      setRole(value);
    } else {
      setRole(1);
    }
  }

  useEffect(() => {
    if(error && !loading) {
      loading = true ;
      toast.error(
        error
      );
    }
  }, [error])


  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>

              <p>
                <IntlMessages id="app.userAuth.getAccount" />
              </p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src="/assets/images/logo.png" />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="gx-signin-form gx-form-row0"
            >
              <Form.Item
                initialValue="demo@example.com"
                rules={[
                  { required: true, message: "The input is not valid Username!" },
                ]}
                name="username"
              >
                <Input placeholder="Username" />
              </Form.Item>
              <Form.Item
                initialValue="demo#123"
                rules={[
                  { required: true, message: "Please input your Password!" },
                ]}
                name="password"
              >
                <Input type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item>
                <Select
                 placeholder="Select a role"
                 name="userGroupId"
                 onChange={handleChangeRoles}
                 allowClear={true}
                >
                  <Option value="2">
                  <IntlMessages id="app.userRole.EditorInChief" />
                  </Option>
                  <Option value="3">
                  <IntlMessages id="app.userRole.Assistant" />
                  </Option>
                  <Option value="5">
                  <IntlMessages id="app.userRole.ConferenceEditor" />
                  </Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button loading={isLoading} type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </Form.Item>
            </Form>
          </div>
           {/* <ToastContainer /> */}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
