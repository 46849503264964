import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
} from "antd";
import {PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";

import { useAddAuthor } from "src/hooks/services/articles/author/useAddAuthor";
import { useEditAuthor } from "src/hooks/services/articles/author/useEditAuthor";
const { TextArea } = Input;

const Add = ({
  fields,
  editSection = false,
  changeTabEvent,
  searchData,
  articleSection = false,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddAuthors, isLoading: loadingSave } =
    useAddAuthor(articleSection);
  const { mutate: onEditAuthors, isLoading: loadingEdit } =
    useEditAuthor(searchData);
  const [formData, setFormData] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
      if (fields.imageUrl) {
        var imageFile = [
          {
            uid: "-1",
            name: "image",
            status: "done",
            url: fields.imageUrl,
          },
        ];
        setImageList(imageFile);
        setImageFile(null);
      }
    }
    return () => {
      form.resetFields();
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection,form]);

  const saveAuthors = (values) => {
    const formData = new FormData();
    if (imageFile) {
      formData.append("Image", imageFile);
    }
    if (editSection) {
      values.id = fields.id;
      formData.append("Author", JSON.stringify(values));
      onEditAuthors(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err:any) => {
          toast.error(
            err.message
          );
        },
      });
    } else {
      formData.append("Author", JSON.stringify(values));

      onAddAuthors(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setImageList([]);
          setImageFile(null);
          if (articleSection) {
            changeTabEvent(data);
          }
        },
        onError: (err:any) => {
          toast.error(
            err.message
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var imageFile = [
        {
          uid: "-2",
          name: "image",
          status: "done",
          url: reader.result,
        },
      ];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveAuthors"
        onFinish={saveAuthors}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="fullName"
          label="Full Name"
          rules={[
            {
              required: true,
              message: "Please input full Name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Primary Email"
          rules={[
            {
              type: "email",
              required: true,
              message: "Please input primary email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="secondaryEmail"
          label="Secondary Email"
          rules={[
            {
              type: "email",
              message: "Please input secondary email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="affiliation"
          label="Affiliation"
          rules={[
            {
              required: true,
              message: "Please input Affiliation!",
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={150} />
        </Form.Item>
        <Form.Item name="address" label="Address">
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={150} />
        </Form.Item>
        <Form.Item name="bio" label="Bio">
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>

        <Form.Item label="Orcid" name="idurl" >
          <Input placeholder="https://orcid.org/0000"/>
        </Form.Item>

        <Form.Item label="Phone" name="mobile">
          <Input />
        </Form.Item>

        <Form.Item label="Image">
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
            {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

    </>
  );
};

export default Add;
