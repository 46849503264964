import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { IndexTypesService } from "src/apis/services/indexTypes/indexTypesService";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";

export const useDeleteIndexTypes = () => {
    const indexTypesService = container.resolve(IndexTypesService);
    

    const queryClient = useQueryClient();

    const toggleDeleteIndexTypes = useCallback(
        (targetIndexTypesId) => {
            let tempData = queryClient.getQueryData<IndexTypesDto[]>([Api.IndexTypes.getIndexTypes]);

            if (tempData === undefined) return

            tempData = tempData.filter(item => item.id !== targetIndexTypesId);
             
            queryClient.setQueryData([Api.IndexTypes.getIndexTypes], tempData)
        },
        [],
    )

    return useMutation({
        mutationFn: (indexTypesId: number) => {
            return indexTypesService.deleteIndexTypes(indexTypesId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDeleteIndexTypes(targetId);
        }
    })
}