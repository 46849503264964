import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { JournalDto } from "src/data/models/journalDtos/journalDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";

export const useEditJournals = () => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();
    const toggleEditJournal = useCallback(
        (data, variables, context) => {
            queryClient.removeQueries([Api.Journals.getJournalList]);
        },
        [],
    );
    return useMutation({
        mutationFn: (data: JournalDto) => {
            return journalsService.editJournals(data);
        },
        onSuccess: toggleEditJournal
    })

}
