import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";

export const useGetFooterColumn = () => {
    const baseDataService = container.resolve(BaseDataService);
    return useQuery({
        queryKey: [Api.BaseData.getFooterColumn],
        queryFn: () => {
            return baseDataService.getFooterColumn();
        }
    })
}
