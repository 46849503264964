import { MiddlewareManager } from 'js-middleware';
import { AxiosError } from 'axios';
import { container } from 'tsyringe';
import IRepositoryWrapper from 'src/apis/repository/Base/IRepositoryWrapper';
import RepositoryWrapper from 'src/apis/repository/Base/RepositoryWrapper';
import { IKeyValue } from 'src/apis/helpers/baseModels/IKeyValue';
import { IErrorResult } from 'src/apis/helpers/baseModels/IErrorMessageHandler';
// import { IErrorResult } from 'src/apis/helpers/baseModels/IErrorMessageHandler';


export default class BaseService {
  private middlewareManager: typeof MiddlewareManager;
  repositoryWrapper: IRepositoryWrapper;
  
  constructor(
  ) {
    this.repositoryWrapper = container.resolve(RepositoryWrapper);
    this.middlewareManager = new MiddlewareManager(this);
  }

  protected setMiddlware(middleware: any, funcs: string[]) {
    funcs.forEach((item) => {
      this.middlewareManager.use(item, middleware);
    });
  }

  protected errorCatcher = (target: any) => (
    next: (...arg: any[]) => Promise<any>,
  ) => async (...args: any[]) => {


    let result: any;
    try {
      result = await next(...args);
    } catch (e: any) {

      if (e?.isAxiosError && e?.response?.status === 400) {
        throw {
          message: e.response?.data?.message,
          statusCode: e?.response?.status
        }
      }
      else {
        throw {
          // TODO translate
          message: 'خطای ناگهانی',
          result: e.response?.data?.result,
          statusCode: e?.response?.status
        }
      }
    }

    return result;
  }

  protected handleToken = (target: any) => (
    next: (...arg: any[]) => Promise<any>,
  ) => async (...args: any[]) => {
    let result: any;

    try {
      // Todo: ???/
      const token = localStorage.getItem('token');

      if (token) {
          this.repositoryWrapper.SetValueToHeader(
              'Authorization',
              `Bearer ${token}`,
          );
      }

      result = await next(...args);
    } catch (e: any) {
      if (e?.isAxiosError && e?.response?.status == 401) {
        await this.updateToken();
        result = await next(...args);
      } else {
        throw e;
      }
    }
    return result;
  };

  private async updateToken() {
    try {
      // const token = await this.getNewToken();

      // if (token) {
      //     this.repositoryWrapper?.SetValueToHeader(
      //         'Authorization',
      //         `Bearer ${token}`,
      //     );
      // }
    } catch (e) {
      throw e;
    }
  }

  private async getNewToken() {
    try {

      // const token = this.cookieService?.read('access-token');

      // if (!token) {
      //     throw 'don\'t have token';
      // } else {
      //     const result = await this.repositoryWrapper.Authenticate.refreshToken();

      //     this.cookieService?.write('access-token', result.result.jwtToken);

      //     return result.result.jwtToken;

      // }

    } catch (e) {
      throw e;
    }
  }

  protected convertObjToArray<T>(object: T): IKeyValue[] {
    const filters: IKeyValue[] = []

    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        filters.push({
          key,
          value: (object[(key as keyof typeof object)] as any)?.toString() ?? ''
        })
      }
    }

    return filters;
  }

  protected errorMessageCatcher(e: any): IErrorResult {
    if (e?.isAxiosError && e?.response?.status === 400)
      return {
        message: e.response?.data?.message,
        statusCode: e?.response?.status
      }
    else
      return e;
  }

}
