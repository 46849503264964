import { useContext, useEffect } from "react";
import { useMutation } from "react-query";
import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { AuthService } from "src/apis/services/auth/auth";
import { TokenRequestDto } from "src/data/models/login/tokenRequestModel.interface";
import { TokenResponse } from "src/data/models/login/tokenResponse.interface";
import { container } from "tsyringe";

export const useLogin = () => {

    const authService = container.resolve(AuthService);

    return useMutation({
        mutationFn: (data: TokenRequestDto) => {
            return authService.login(data);
        },
        onSuccess: (data, variables, context) => {
            // httpClient.defaults.headers.common['Authorization'] = 'Bearer ' + data.data.result.jwtToken;
            localStorage.setItem('token', data.result.jwtToken);
            // localStorage.setItem('userinfo', data.result.userInfo);

        }
    })
}
