import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, Card, Upload } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAddSlider } from "src/hooks/services/baseData/useAddSlider";
import TextArea from "antd/lib/input/TextArea";
import { PlusOutlined } from "@ant-design/icons";
import { useEditSlider } from "src/hooks/services/baseData/useEditSlider";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";


const Add = ({ fields, editSection = false, changeTabEvent }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const { mutate: onAddSlider, isLoading:loadingSave  } = useAddSlider();
  const { mutate: onEditSlider, isLoading:loadingEdit  } = useEditSlider();
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (fields) {

      form.setFieldsValue(fields);

      var imageFile = [{
        uid: '-1',
        name: 'image',
        status: 'done',
        url: fields.urlImage,
      }];
      setImageList(imageFile);
    }
    return () => {
      form.resetFields();
      setFormData([]);
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection,form]);

  const saveSlider = (values: SliderDto) => {
    if (imageFile === null && !editSection) {
      return;
    }

    const formData = new FormData();
    if (imageFile)
      formData.append("Image", imageFile);

    if (editSection) {
      values.sliderId = fields.sliderId;

      formData.append("Slider", JSON.stringify(values));

      onEditSlider(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageFile(null);
          setImageList([]);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      formData.append("Slider", JSON.stringify(values));

      onAddSlider(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      }
      : null;

  // uploads section
  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      var imageFile = [{
        uid: '-2',
        name: 'image',
        status: 'done',
        url: reader.result,
      }];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);

  }

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveSlider}
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="Slider Title"
          rules={[
            {
              required: true,
              message: "Please enter slider title!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="sliderInfo"
          label="Slider Info"
          rules={[
            {
              required: true,
              message: "Please Enter slider info!",
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={200} />
        </Form.Item>

        <Form.Item
          name="buttonText"
          label="Button Text"
          rules={[
            // {
            //   required: true,
            //   message: "Please input news Title!",
            // },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="link"
          label="Slider Link"
          rules={[
            // {
            //   required: true,
            //   message: "Please input news Title!",
            // },
            { type: 'url' }
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Image"
          required
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
            {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>


        <Form.Item>
          <Button loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
