import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class NewsService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getNews",
      "addNews",
      "editNews",
      "deleteNews",
      "toggleNewsStatus",
    ]);
    this.setMiddlware(this.handleToken, [
      "getNews",
      "addNews",
      "editNews",
      "deleteNews",
      "toggleNewsStatus",
    ]);
  }

  async getNews(params: PaginationParams): Promise<PaginatedList<NewsDto>> {
    const result = await this.repositoryWrapper.News.getNews(params);
    return result.result;
  }

  async addNews(data: NewsDto): Promise<NewsDto> {
    const result = await this.repositoryWrapper.News.addNews(data);
    return result.result;
  }

  async editNews(data: NewsDto): Promise<NewsDto> {
    const result = await this.repositoryWrapper.News.editNews(data);
    return result.result;
  }

  async toggleNewsStatus(userId): Promise<number> {
    const result = await this.repositoryWrapper.News.toggleNewsStatus(userId);
    return result.result;
  }
  async deleteNews(newsId: number): Promise<number> {
    const result = await this.repositoryWrapper.News.deleteNews(newsId);
    return result.result;
  }
}
