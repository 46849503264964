import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { AuthorDto } from "src/data/models/articleDtos/authorDto.interface";

export const useEditAuthor = ({page , fullName , email})  => {
    const articlesService = container.resolve(ArticlesService);
    const queryClient = useQueryClient();
    const toggleEdit = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<AuthorDto>>([Api.Articles.getAuthors , page , fullName , email]);
            const newData = [...tempData.items] ;
            const findIndex = newData.findIndex(x=>x.id === data.id) ;
            newData[findIndex] = data;
            tempData.items = newData;
            queryClient.setQueryData([Api.Articles.getAuthors , page , fullName , email],tempData );

            // baraye data hay bedone search
            let AllData = queryClient.getQueryData<PaginatedList<AuthorDto>>([Api.Articles.getAuthors , page , "" , ""]);
            if(AllData) {
                const newAllData = [...AllData.items] ;
                const findIndexAllData = newAllData.findIndex(x=>x.id === data.id) ;
                newAllData[findIndexAllData] = data;
                AllData.items = newAllData;
                queryClient.setQueryData([Api.Articles.getAuthors , page , "" , ""],AllData );
    
            }

            // baraye hamaeye nevisandeha
            let AllDataAuthors = queryClient.getQueryData<AuthorDto[]>([Api.Articles.getAllAuthors]);
            if(AllDataAuthors) {
                const newAllDataAuthors = [...AllDataAuthors] ;
                const findIndexAllDataAuthors = newAllDataAuthors.findIndex(x=>x.id === data.id) ;
                newAllDataAuthors[findIndexAllDataAuthors] = data;
                AllDataAuthors = newAllDataAuthors;
                queryClient.setQueryData([Api.Articles.getAllAuthors ],AllDataAuthors );
            }


        },
        [page , fullName , email],
    );
    return useMutation({
        mutationFn: (data: AuthorDto) => {
            return articlesService.editAuthor(data);
        },
        onSuccess: toggleEdit
    })

}
