import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { ArticleListDto } from "src/data/models/articleDtos/articleListDto.interface";
import { PageSize } from "src/apis/helpers/configs/page-sizes";

export const useAddArticles = () => {
    const articlesService = container.resolve(ArticlesService);
    return useMutation({
        mutationFn: (data: any) => {
            return articlesService.addArticle(data);
        },
    })

}
