import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { UsersService } from "src/apis/services/users/usersService";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";

export const useEditProfile = () => {
    const usersService = container.resolve(UsersService);

    return useMutation({
        mutationFn: (data: UsersDto) => {
            return usersService.editProfile(data);
        },
    })

}
