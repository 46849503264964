
import { AddJouranlIndexTypeDto } from "src/data/models/indexTypesDtos/addJouranlIndexTypeDto.interface";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { JournalIndexTypeListDto } from "src/data/models/indexTypesDtos/journalIndexTypeListDto.interface";
import { JournalIndicatorListDto } from "src/data/models/indicatorDtos/journalIndicatorListDto.interface";
import { IssueDto } from "src/data/models/journalDtos/issueDto.interface";
import { JournalDto } from "src/data/models/journalDtos/journalDto.interface";
import { VolumeDto } from "src/data/models/journalDtos/volumeDto.interface";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { journalsPaginationParams } from "src/data/models/params/journalsPaginationParams.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { AddUserPermissionDto } from "src/data/models/usersDtos/addUserPermissionDto.interface";
import { UserPermissionDto } from "src/data/models/usersDtos/userPermissionDto.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class JournalsService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getJournalIndicators",
      "assignIndicatorToJournal",
      "deleteJournalIndicator",
      "editJournalIndicator",
      "getJournalList",
      "addJournal",
      "editJournal",
      "toggleJournalActivation",
      "getUserPermissions",
      "getJournalIndexTypes",
      "assignIndexTypeToJournal",
      "editJournalIndexType",
      "deleteJournalIndexType",
      "getUserUserPermissions",
      "getJournalUserPermissions",
      "addUserPermission",
      "deleteUserPermission",
      "getJournalVolumes",
      "getJournalIssues",
      "getJournalAllIssues",
      "addVolume",
      "editVolume",
      "deleteVolume",
      "addIssue",
      "editIssue",
      "deleteIssue",
    ]);
    this.setMiddlware(this.handleToken, [
      "getJournalIndicators",
      "assignIndicatorToJournal",
      "deleteJournalIndicator",
      "editJournalIndicator",
      "getJournalList",
      "addJournal",
      "editJournal",
      "toggleJournalActivation",
      "getUserPermissions",
      "getJournalIndexTypes",
      "assignIndexTypeToJournal",
      "editJournalIndexType",
      "deleteJournalIndexType",
      "getUserUserPermissions",
      "getJournalUserPermissions",
      "addUserPermission",
      "deleteUserPermission",
      "getJournalVolumes",
      "getJournalIssues",
      "getJournalAllIssues",
      "addVolume",
      "editVolume",
      "deleteVolume",
      "addIssue",
      "editIssue",
      "deleteIssue"
    ]);
  }

  async getJournalList(): Promise<JournalDto[]> {
    const result = await this.repositoryWrapper.Journals.getJournalList();
    return result.result;
  }

  async getJournalIndicators(
    jouranlId: number
  ): Promise<JournalIndicatorListDto[]> {
    const result = await this.repositoryWrapper.Journals.getJournalIndicators(
      jouranlId
    );
    return result.result;
  }

  async addJournals(data: JournalDto): Promise<JournalDto> {
    const result = await this.repositoryWrapper.Journals.addJournals(data);
    return result.result;
  }


  async editJournals(data: JournalDto): Promise<JournalDto> {
    const result = await this.repositoryWrapper.Journals.editJournals(data);
    return result.result;
  }

  async editJournalIndicator(data: JournalIndicatorListDto): Promise<JournalIndicatorListDto> {
    const result = await this.repositoryWrapper.Journals.editJournalIndicator(data);
    return result.result;
  }

  async editJournalIndexType(data: AddJouranlIndexTypeDto): Promise<JournalIndexTypeListDto> {
    const result = await this.repositoryWrapper.Journals.editJournalIndexType(data);
    return result.result;
  }

  async assignIndicatorToJournal(data: JournalIndicatorListDto): Promise<JournalIndicatorListDto> {
    const result = await this.repositoryWrapper.Journals.assignIndicatorToJournal(data);
    return result.result;
  }

  async toggleJournalActivation(id): Promise<number> {
    const result = await this.repositoryWrapper.Journals.toggleJournalActivation(
      id
    );
    return result.result;
  }
  async deleteJournalIndicator(journalIndicatorId: number): Promise<number> {
    const result = await this.repositoryWrapper.Journals.deleteJournalIndicator(journalIndicatorId);
    return result.result;
  }

  async deleteUserPermission(userPermissinId: number): Promise<number> {
    const result = await this.repositoryWrapper.Journals.deleteUserPermission(userPermissinId);
    return result.result;
  }

  
  async deleteJournalIndexType(journalIndexId: number): Promise<number> {
    const result = await this.repositoryWrapper.Journals.deleteJournalIndexType(journalIndexId);
    return result.result;
  }

  async assignIndexTypeToJournal(data: AddJouranlIndexTypeDto): Promise<JournalIndexTypeListDto> {
    const result = await this.repositoryWrapper.Journals.assignIndexTypeToJournal(data);
    return result.result;
  }

  
  async getJournalIndexTypes(
    jouranlId: number
  ): Promise<JournalIndexTypeListDto[]> {
    const result = await this.repositoryWrapper.Journals.getJournalIndexTypes(
      jouranlId
    );
    return result.result;
  }
  
  async getJournalUserPermissions(
    jouranlId: number
  ): Promise<UserPermissionDto[]> {
    const result = await this.repositoryWrapper.Journals.getJournalUserPermissions(
      jouranlId
    );
    return result.result;
  }


  async addUserPermission(data: AddUserPermissionDto): Promise<UserPermissionDto> {
    const result = await this.repositoryWrapper.Journals.addUserPermission(data);
    return result.result;
  }
  
  async getJournalVolumes(params: journalsPaginationParams): Promise<PaginatedList<VolumeDto>> {
    const result = await this.repositoryWrapper.Journals.getJournalVolumes(params);
    return result.result;
  }
  
  async getJournalIssues(params: journalsPaginationParams): Promise<PaginatedList<VolumeWithIssuesDto>> {
    const result = await this.repositoryWrapper.Journals.getJournalIssues(params);
    return result.result;
  }
  
  async getJournalAllIssues(journalId): Promise<VolumeWithIssuesDto[]> {
    const result = await this.repositoryWrapper.Journals.getJournalAllIssues(journalId);
    return result.result;
  }

  async addVolume(data: VolumeDto): Promise<VolumeDto> {
    const result = await this.repositoryWrapper.Journals.addVolume(data);
    return result.result;
  }

  async addIssue(data: IssueDto): Promise<IssueDto> {
    const result = await this.repositoryWrapper.Journals.addIssue(data);
    return result.result;
  }

  async editVolume(data: VolumeDto): Promise<VolumeDto> {
    const result = await this.repositoryWrapper.Journals.editVolume(data);
    return result.result;
  }

  async editIssue(data: IssueDto): Promise<IssueDto> {
    const result = await this.repositoryWrapper.Journals.editIssue(data);
    return result.result;
  }

  async deleteVolume(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Journals.deleteVolume(id);
    return result.result;
  }


  async deleteIssue(id: number): Promise<number> {
    const result = await this.repositoryWrapper.Journals.deleteIssue(id);
    return result.result;
  }


}
