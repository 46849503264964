import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { container } from "tsyringe";

export const useGetArticleType = () => {
    const articlesService = container.resolve(ArticlesService);

    return useQuery({
        queryKey: [Api.Articles.getArticleTypes],
        queryFn: () => {
            return articlesService.getArticleTypes();
        }
    })
}
