import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { ArticleListDto } from "src/data/models/articleDtos/articleListDto.interface";
import { AuthorDto } from "src/data/models/articleDtos/authorDto.interface";

export const useAddAuthor = (articleSection = false) => {
    const articlesService = container.resolve(ArticlesService);
    const queryClient = useQueryClient();

    const toggleAdd = useCallback(
        (data, variables, context) => {
            if(articleSection) {
                let tempData = queryClient.getQueryData<AuthorDto[]>([Api.Articles.getAllAuthors]);
                const finalData = [...tempData] ;
                finalData.push(data);
                queryClient.setQueryData([Api.Articles.getAllAuthors],finalData )
            }

            // baraye qesmate pagination


            let tempData = queryClient.getQueryData<PaginatedList<ArticleListDto>>([Api.Articles.getAuthors , 1,"",""]);
            if(tempData) {
                for (let index = 1; index <= tempData?.totalPages; index++) {
                    queryClient.removeQueries([Api.Articles.getAuthors ,  1,"",""]);
                }
            }



        },
        [articleSection],
    );
    return useMutation({
        mutationFn: (data: AuthorDto) => {
            return articlesService.addAuthor(data);
        },
        onSuccess: toggleAdd
    })

}
