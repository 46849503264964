import { Space, Table } from "antd";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const { Column } = Table;
const JournalList = (props) => {
  const getRole = (roleId) => {
    switch (roleId) {
      case UserGroupEnum.Admin:
        return "Admin";
      case UserGroupEnum.Assistant:
        return "Assistant";
      case UserGroupEnum.EditorInChief:
        return "Editor In Chief";
      default:
        return "";
    }
  };
  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={props?.data}
        rowKey="id"
      >
        <Column title="Journal Name" dataIndex="name" key="name" />
        <Column
          title="Role"
          dataIndex="role"
          key="role"
          render={(text, record: any) => (
            <Space size="middle">{getRole(record.role)}</Space>
          )}
        />
      </Table>
    </>
  );
};

export default JournalList;
