import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";
import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";
import { FooterColumnDto } from "src/data/models/footerDtos/footerColumnDto.interface";

export const useAddFooterLink = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const addFooterLink = useCallback(
    (data:FooterLinkDto, variables, context) => {
      let tempData = queryClient.getQueryData<FooterColumnDto[]>([Api.BaseData.getFooterColumn]);
      var column = tempData.findIndex(x=>x.id === data.fkFooterColumnId);
      const finalData = [...tempData[column].footerLinks];
      finalData.push(data);
      tempData[column].footerLinks = finalData ;
      queryClient.setQueryData([Api.BaseData.getFooterColumn], tempData)
    },
    [],
  );
  return useMutation({
    mutationFn: (data: FooterLinkDto) => {
      return baseDataService.addFooterLink(data);
    },
    onSuccess: addFooterLink
  })

}
