import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import Editor from "src/components/Editor";
import { useAddTab } from "src/hooks/services/contents/tab/useAddTab";
import { useEditTab } from "src/hooks/services/contents/tab/useEditTab";

const TabAdd = ({
  fields,
  editSection = false,
  changeTabEvent,
  contentData,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const [descContent, setDescContent] = useState("");
  const [descContentDefault, setDescContentDefault] = useState("");
  const { mutate: onAddTab, isLoading: loadingSave } = useAddTab(
    contentData.id,
    true
  );
  const { mutate: onEditTab, isLoading: loadingEdit } = useEditTab(
    contentData.id,
    true
  );

  useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
      if (fields.description) {
        setDescContent(fields.description);
        setDescContentDefault(fields.description);
      }
    }
    return () => {
      form.resetFields();
      setDescContent("");
      setDescContentDefault("");
    };
  }, [fields, editSection]);

  const saveTab = (values) => {
    values.description = descContent;
    values.contentId = contentData.id;
    if (editSection) {
      values.id = fields.id;
      onEditTab(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      onAddTab(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setDescContent("");
          setDescContentDefault("");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveTab}
        autoComplete="off"
      >
        <Form.Item
          label="Tab Title"
          name="tabTitle"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description">
          <Editor
            value={descContentDefault}
            onChange={(data, editor ) => {
              setDescContent(data);
            }}
          />
        </Form.Item>

        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: "Please input Priority!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>

        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default TabAdd;
