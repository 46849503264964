interface IEnvironment {
  appName: string;
  clientId: string;
  baseUrl: string;
  domain: string;
}

export const environment: Readonly<IEnvironment> = {
  appName: "Eurokd",
  clientId: "clientId",
  baseUrl: "https://api.eurokd.com/api/panel/",
  domain: "https://api.eurokd.com/"
  // baseUrl: "https://localhost:5000/api/panel/",
  // domain:  "https://localhost:5000/"
};
