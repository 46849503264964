import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { ContactUsDto } from "src/data/models/contactUsDtos/contactUsDto.interface";
import { FooterColumnDto } from "src/data/models/footerDtos/footerColumnDto.interface";
import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { HomeDataPaginationParams } from "src/data/models/params/homeDataPaginationParams.interface";
import { AboutPublisherDto } from "src/data/models/settingDtos/aboutPublisherDto.interface";
import { SettingDto } from "src/data/models/settingDtos/settingDto.interface";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";
import { webHomeDataCollectionDto } from "src/data/models/webHomeDataDtos/webHomeDataCollectionDto.interface";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";
import BaseRepository from "../Base/BaseRepository";
import IBaseDataRepository from "./IBaseDataRepository";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { editorFilesDto } from "src/data/models/editorFiles/editorFilesDto.interface";

export default class BaseDataRepository extends BaseRepository implements IBaseDataRepository {

  async getSetting(): Promise<IGenericResponse<SettingDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.BaseData.getSetting)

    return await request
      .GetResultAsync()
  }

  async editSetting(body: SettingDto): Promise<IGenericResponse<SettingDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.BaseData.editSetting)

    return await request
      .GetResultAsync()
  }

  async getAboutPublisher(): Promise<IGenericResponse<string>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.BaseData.getAboutPublisher)

    return await request
      .GetResultAsync()
  }

  async editAboutPublisher(body: AboutPublisherDto): Promise<IGenericResponse<AboutPublisherDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.BaseData.editAboutPublisher)

    return await request
      .GetResultAsync()
  }

  async getSliders(): Promise<IGenericResponse<SliderDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.BaseData.getSliders)

    return await request
      .GetResultAsync()
  }

  async getContactUs(): Promise<IGenericResponse<ContactUsDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.BaseData.getContactUs)

    return await request
      .GetResultAsync()
  }

  async getSlider(id: any): Promise<IGenericResponse<SliderDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .addQueryParam('id', id)
      .setUrl(Api.BaseData.getSlider)

    return await request
      .GetResultAsync()
  }

  async addSlider(body: SliderDto): Promise<IGenericResponse<SliderDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.BaseData.addSlider)

    return await request
      .GetResultAsync()
  }

  async editSlider(body: SliderDto): Promise<IGenericResponse<SliderDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.BaseData.editSlider)

    return await request
      .GetResultAsync()
  }

  async deleteSlider(sliderId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.BaseData.deleteSlider, { sliderId })

    return await request
      .GetResultAsync()
  }

  //
  async getFooterLinks(): Promise<IGenericResponse<FooterLinkDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.BaseData.getFooterLinks)

    return await request
      .GetResultAsync()
  }
  //
  async getFooterColumn(): Promise<IGenericResponse<FooterColumnDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod('get')
      .setUrl(Api.BaseData.getFooterColumn)

    return await request
      .GetResultAsync()
  }

  async addFooterLink(body: FooterLinkDto): Promise<IGenericResponse<FooterLinkDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.BaseData.addFooterLink)

    return await request
      .GetResultAsync()
  }

  async editFooterLink(body: FooterLinkDto): Promise<IGenericResponse<FooterLinkDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.BaseData.editFooterLink)

    return await request
      .GetResultAsync()
  }
  async editFooterColumn(body: FooterColumnDto): Promise<IGenericResponse<FooterColumnDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.BaseData.editFooterColumn)

    return await request
      .GetResultAsync()
  }

  async deleteFooterLink(id: number): Promise<IGenericResponse<FooterLinkDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.BaseData.deleteFooterLink, { id })

    return await request
      .GetResultAsync()
  }

  //
  async getWebHomeCollectionDataList(params: HomeDataPaginationParams): Promise<IGenericResponse<PaginatedList<WebHomeDataDto>>> {
    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value)
    }

    const request = result
      .setMethod('get')
      .setUrl(Api.BaseData.getWebHomeCollectionDataList)

    return await request
      .GetResultAsync();
  }

  async addWebHomeData(body: WebHomeDataDto): Promise<IGenericResponse<WebHomeDataDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.BaseData.addWebHomeData)

    return await request
      .GetResultAsync()
  }

  async editWebHomeData(body: WebHomeDataDto): Promise<IGenericResponse<WebHomeDataDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.BaseData.editWebHomeData)

    return await request
      .GetResultAsync()
  }

  async deleteWebHomeData(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.BaseData.deleteWebHomeData, { id })

    return await request
      .GetResultAsync()
  }


  async getWebHomeCollections(): Promise<IGenericResponse<webHomeDataCollectionDto[]>> {
    const result = this.CreateRequestBuilder()
    const request = result
      .setMethod('get')
      .setUrl(Api.BaseData.getWebHomeCollections)
    return await request
      .GetResultAsync();
  }

  async addWebHomeCollection(body: webHomeDataCollectionDto): Promise<IGenericResponse<webHomeDataCollectionDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.BaseData.addWebHomeCollection)

    return await request
      .GetResultAsync()
  }

  async editWebHomeCollection(body: webHomeDataCollectionDto): Promise<IGenericResponse<webHomeDataCollectionDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.BaseData.editWebHomeCollection)

    return await request
      .GetResultAsync()
  }

  async deleteWebHomeCollection(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.BaseData.deleteWebHomeCollection, { id })

    return await request
      .GetResultAsync()
  }

  async toggleWebHomeCollectionStatus(id): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(id)
      .setUrl(Api.BaseData.toggleWebHomeCollectionStatus)

    return await request
      .GetResultAsync() ;
  }

  async getEditorFiles(params: PaginationParams): Promise<IGenericResponse<PaginatedList<editorFilesDto>>> {
    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value)
    }

    const request = result
      .setMethod('get')
      .setUrl(Api.BaseData.getEditorFiles)

    return await request
      .GetResultAsync();
  }

  async addEditorFiles(body: editorFilesDto): Promise<IGenericResponse<editorFilesDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.BaseData.addEditorFiles)

    return await request
      .GetResultAsync()
  }

  async deleteEditorFiles(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.BaseData.deleteEditorFiles, { id })

    return await request
      .GetResultAsync()
  }


}
