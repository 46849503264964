import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { ContentsService } from "../../../../apis/services/contents/contentsService";
import { TabsListDto } from "../../../../data/models/contentsDtos/tabsListDto.interface";
import { container } from "tsyringe";

export const useAddTab = (id,isForContent) => {
    const contentsService = container.resolve(ContentsService);
    const queryClient = useQueryClient();

    const toggleAddTab = useCallback(
        (data, variables, context) => {
            queryClient.removeQueries([Api.Contents.getContentTabList,id,isForContent]);
        },
        [isForContent],
    );

    return useMutation({
        mutationFn: (data: TabsListDto) => {
            return contentsService.addTabList(data);
        },
        onSuccess:toggleAddTab
    })

}
