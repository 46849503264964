import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";

export const useAddSlider = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const addSlider = useCallback(
    (data, variables, context) => {
      let tempData = queryClient.getQueryData<SliderDto[]>([Api.BaseData.getSliders]);
      const finalData = [...tempData];
      finalData.push(data);
      queryClient.setQueryData([Api.BaseData.getSliders], finalData)
    },
    [],
  );
  return useMutation({
    mutationFn: (data: SliderDto) => {
      return baseDataService.addSlider(data);
    },
    onSuccess: addSlider
  })

}
