import React,{useState} from "react";
import {Tabs} from "antd";
import IndicatorAssignAdd from "./IndicatorAssignAdd";
import IndicatorAssignList from "./IndicatorAssignList";
const TabPane = Tabs.TabPane;


const JournalIndicatorsPage = (props) => {
  const [activeTab , setActiveTab] = useState("1");
  const [journalIndicatorsEditData , setJournalIndicatorsEditData] = useState(null);
  const [journalIndicatorsEditFlag , setJournalIndicatorsEditFlag] = useState(false);
  const callback = (key , data = null) => {
    setActiveTab(key);
    if(data && key === "2") {
      setJournalIndicatorsEditData(data);
      setJournalIndicatorsEditFlag(true);
    } else if(key === "2") {
      setJournalIndicatorsEditFlag(false);
      setJournalIndicatorsEditData(null);
    }
  };

  return (
      <Tabs  activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
          <IndicatorAssignList changeTabEvent = {callback} journalData = {props.data}/>
        </TabPane>
        <TabPane  tab="Add / Edit Journal Metrics" key="2">
        <IndicatorAssignAdd fields = {journalIndicatorsEditData} editSection = {journalIndicatorsEditFlag} changeTabEvent = {callback} journalData = {props.data}/>
        </TabPane>
      </Tabs>
  );
};

export default JournalIndicatorsPage;
