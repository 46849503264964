export enum ArticleStatusEnum {
  NewArticle = 0,
  Revise = 1,
  Rejected = 2,
  Accepted = 3,
  AssignedToVolume = 4,
  Deleted = -1,
  AddFromAuthor = -2,
  ReviseAuthorArticle = -3,
}
