import { environment } from "../../environment/environment";
import { useRef, useEffect, memo } from "react";
import { Editor } from "@tinymce/tinymce-react";

const Editors = memo<any>((props) => {
  const editorRef = useRef(null);

  const example_image_upload_handler = (blobInfo, progress) =>
    new Promise<string>((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.withCredentials = false;
      xhr.open("POST", environment.baseUrl + "BaseData/uploadEditorImage");

      xhr.upload.onprogress = (e) => {
        progress((e.loaded / e.total) * 100);
      };

      xhr.onload = () => {
        if (xhr.status === 403) {
          reject({ message: "HTTP Error: " + xhr.status, remove: true });
          return;
        }

        if (xhr.status < 200 || xhr.status >= 300) {
          reject("HTTP Error: " + xhr.status);
          return;
        }

        resolve(xhr.responseText);
      };

      xhr.onerror = () => {
        reject(
          "Image upload failed due to a XHR Transport error. Code: " +
            xhr.status
        );
      };

      const formData = new FormData();
      formData.append("files[]", blobInfo.blob());

      xhr.send(formData);
    });

  useEffect(() => {
    editorRef.current.currentContent = '';
  }, [props.value]);

  return (
    <Editor
      apiKey="6tb16fi2h4sc1iwybzrxrz1n71upu4g8ajrsvxa238oqtnzz"
      onEditorChange={props.onChange}
      ref={editorRef}
      initialValue={props.value}
      onInit={(_evt, editor) => (editorRef.current = editor)}
      init={{
        inline: false,
       toolbar_mode: 'wrap',
        menubar: true,
        toolbar:
          "bold italic fontsize fontfamily link | paste copy cut forecolor backcolor | alignleft alignjustify aligncenter alignright bullist numlist | blockquote quickimage quicktable",
        skin: "oxide",
        content_css: "default",
        height: 500,
        contextmenu:
          "undo redo | inserttable | cell row column deletetable | help",
        plugins: [
          "autolink",
          "codesample",
          "link",
          "lists",
          "media",
          "table",
          "image",
          "codesample",
          "quickbars",
          "help",
        ],
        file_picker_types: "file image media",
        images_upload_handler: example_image_upload_handler,
      }}
    />
  );
});


export default Editors;
