import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useAddUsers } from "src/hooks/services/users/useAddUsers";
import { useEditUsers } from "src/hooks/services/users/useEditUsers";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";

const Add = ({ fields, editSection = false ,changeTabEvent,currentPage})  => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddUsers, isLoading:loadingSave } = useAddUsers();
  const { mutate: onEditUsers, isLoading:loadingEdit } = useEditUsers(currentPage);
  const [formData, setFormData] = useState([]);


    useEffect(() => {
    if (fields) {
      const finalData = [];
      for (var key in fields) {
        if (fields.hasOwnProperty(key)) {
          let data = {
            name: [],
            value: "",
          };
          data.name.push(key);
          data.value = fields[key];
          finalData.push(data);
        }
      }
      setFormData(finalData);
    }
    return () => {
      form.resetFields();
      setFormData([]);
    };
  }, [fields, editSection]);


  const saveUser = (values) => {
    if (editSection) {
      values.userId = fields.userId ;
      onEditUsers(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
    else {
      onAddUsers(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
        },
        onError: (err:any) => {
          toast.error(
            err.message
          );
        },
      });
    }

  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={saveUser}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="userName"
          label="E-mail"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="firstName"
          label="First Name"
          rules={[
            {
              required: true,
              message: "Please input your first name!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="lastName"
          label="Last Name"
          rules={[
            {
              required: true,
              message: "Please input your last name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: !editSection ? true : false,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirm"
          label="Confirm Password"
          dependencies={["password"]}
          hasFeedback
          rules={[
            {
              required: !editSection ? true : false,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="active"
          valuePropName="checked"
        >
          <Checkbox>
            active
          </Checkbox>
        </Form.Item>
        <Form.Item>
          <Button  loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
          {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
