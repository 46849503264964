import React, { useEffect, useRef, useState } from "react";
import {
  Table,
  Space,
  Form,
  Input,
  Button,
  Select,
  Modal,
  Descriptions,
  Tag,
  TreeSelect,
  Row,
  Col,
  Switch,
  Popover,
  Typography,
} from "antd";
import { ToastContainer, toast } from "react-toastify";
import Editor from "src/components/Editor";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetArticles } from "src/hooks/services/articles/useGetArticles";
import ChangeArticleStatus from "../articleStatus/changeArticleStatus";
import ArticleStatusList from "../articleStatus/articleStatusList";
import FinalSubmit from "../final-submit/Add";
import { ArticleStatusEnum } from "src/data/enums/articleStatusEnum";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
import { useGetAllIssues } from "src/hooks/services/journals/issue/useGetAllIssues";
import { useGetJournals } from "src/hooks/services/journals/useGetJournals";
import { useToggleRecommendedArticle } from "src/hooks/services/articles/useToggleRecommendedArticle";
import IntlMessages from "../../../../util/IntlMessages";
import { useDeleteArticle } from "src/hooks/services/articles/useDeleteArticle";
import { useQuery } from "src/hooks/utils/useQuery";
import { useToggleAllowDownloadArticle } from "src/hooks/services/articles/useToggleAllowDownloadArticle";
import { useChangeRightAndPermissonArticle } from "src/hooks/services/articles/useChangeRightAndPermissonArticle";
import ArticleContent from "../articleContent/ArticleContent";
const { Column } = Table;
const { Option } = Select;
const { TreeNode } = TreeSelect;
const confirm = Modal.confirm;
const { Text, Link } = Typography;
const List = (props) => {
  let query = useQuery();
  const authorEmail = query.get("email");
  const journalRef = useRef(null);
  const [visibleStatus, setVisibleStatus] = useState(false);
  const [visibleLog, setVisibleLog] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [visibleSubmit, setVisibleSubmit] = useState(false);
  const [visibleArticleContent, setVisibleArticleContent] = useState(false);
  const [visibleAuthorList, setVisibleAuthorList] = useState(false);
  const [visibleRightAndPermisson, setVisibleRightAndPermisson] =
    useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);
  const [selectedJournal, setSelectedJournal] = useState(0);
  const [rightAndPermissonData, setRightAndPermissonData] = useState(null);
  const [rightAndPermissonDataDefault, setRightAndPermissonDataDefault] = useState(null);

  const [articleSearchData, setArticleSearchData] = useState({
    page: 1,
    status: "",
    filter: "",
    authorEmail: authorEmail ? authorEmail : "",
    isDeleted: false,
    issueId: "",
    volumeId: "",
    journalId: "",
  });

  const { mutate: onToggleRecommendedArticle } = useToggleRecommendedArticle();
  const { mutate: onToggleAllowDownloadArticle } =
    useToggleAllowDownloadArticle();
  const {
    mutate: changeRightAndPermissonArticle,
    isLoading: loadingSaveRightAndPermisson,
  } = useChangeRightAndPermissonArticle();
  const { mutate: deleteArticles } = useDeleteArticle();

  const { data: articlesData, mutate: getArticles } = useGetArticles();
  const [form] = Form.useForm();
  const { data: journalsData, isLoading: isLoadingJournal } = useGetJournals();
  const { data: issueData, isLoading: isLoadingIssue } =
    useGetAllIssues(selectedJournal);
  const changePage = (page) => {
    setArticleSearchData((oldValues) => ({
      ...oldValues,
      page: page,
    }));
  };

  useEffect(() => {
    if (props.activeTab === "1") {
      getArticles(articleSearchData);
    }
  }, [props.activeTab, articleSearchData]);

  const editArticle = (data) => {
    props.changeTabEvent("5", data, articleSearchData); // raftan be tab edit
  };

  const searchArticle = (values: any) => {
    const filterValue = {
      page: 1,
      status: "",
      filter: "",
      authorEmail: "",
      isDeleted: false,
      issueId: "",
      volumeId: "",
      journalId: "",
    };
    if (values.filter) {
      filterValue.filter = values.filter;
    } else {
      filterValue.filter = "";
    }
    if (values.authorEmail) {
      filterValue.authorEmail = values.authorEmail;
    } else {
      filterValue.authorEmail = "";
    }
    if (values.status != null || values.status != undefined) {
      filterValue.status = values.status;
      if (filterValue.status == ArticleStatusEnum.Deleted.toString()) {
        filterValue.isDeleted = true;
      }
    } else {
      filterValue.status = "";
    }

    if (values.issueId) {
      if (values.issueId.split(",")) {
        const issueId = values.issueId.split(",")[1];
        if (!issueId) {
          filterValue.volumeId = values.issueId.split(",")[0];
          filterValue.issueId = "";
        } else {
          filterValue.issueId = values.issueId.split(",")[1];
          filterValue.volumeId = "";
        }
      }
    } else {
      filterValue.volumeId = "";
      filterValue.issueId = "";
    }

    if (selectedJournal !== 0) {
      filterValue.journalId = selectedJournal.toString();
    } else {
      filterValue.journalId = "";
    }
    setArticleSearchData(filterValue);
  };

  const openModal = (data, type) => {
    setSelectedModalData(data);

    switch (type) {
      case "status":
        setVisibleStatus(true);
        break;
      case "log":
        setVisibleLog(true);
        break;
      case "info":
        setVisibleInfo(true);
        break;
      case "Submit":
        setVisibleSubmit(true);
        break;
      case "ArticleContent":
        setVisibleArticleContent(true);
        break;
      case "AuthorList":
        setVisibleAuthorList(true);
        break;
      case "rightsAndPermissons":
        setRightAndPermissonData(data.rightsAndPermissions);
        setRightAndPermissonDataDefault(data.rightsAndPermissions);
        setVisibleRightAndPermisson(true);
        break;
      default:
        break;
    }
  };

  const listStatus = (status) => {
    switch (status) {
      case ArticleStatusEnum.NewArticle:
        return <Tag color="blue">Under review</Tag>;
      case ArticleStatusEnum.AddFromAuthor:
        return <Tag color="blue">Preliminary review</Tag>;
      case ArticleStatusEnum.ReviseAuthorArticle:
        return <Tag color="brown">Returned to the author</Tag>;
      case ArticleStatusEnum.Revise:
        return <Tag color="brown">Waiting for Revision </Tag>;
      case ArticleStatusEnum.Rejected:
        return <Tag color="red">rejected</Tag>;
      case ArticleStatusEnum.Accepted:
        return <Tag color="green"> Waiting for Assignment </Tag>;
      case ArticleStatusEnum.AssignedToVolume:
        return <Tag color="purple">Assigned to Volume</Tag>;
      case ArticleStatusEnum.Deleted:
        return <Tag color="red">deleted</Tag>;
      default:
        return "";
    }
  };

  const deleteArticle = (data) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteArticles(data.articleId, {
          onSuccess: (data) => {
            toast.success(<IntlMessages id="notification.successMessage" />);
            getArticles(articleSearchData);
          },
          onError: (err) => {
            toast.error(<IntlMessages id="notification.errorMessage" />);
          },
        });
      },
      onCancel() {},
    });
  };

  const actionList = (record) => {
    return (
      <div className="article-action-list">
        <ul>
          <li>
            <a target="_blank" href={record?.articleFile?.fileUrl}>
              Download File
            </a>
          </li>

          <li>
            <a onClick={() => openModal(record, "rightsAndPermissons")}>
              Rights And Permissons
            </a>
          </li>
          {record.status === ArticleStatusEnum.Accepted &&
            props.userRole === UserGroupEnum.Assistant && (
              <li>
                <a onClick={() => openModal(record, "Submit")}>assign volume</a>
              </li>
            )}

          {record.status === ArticleStatusEnum.AssignedToVolume &&
            props.userRole === UserGroupEnum.Assistant && (
              <li>
                <a onClick={() => openModal(record, "Submit")}>Edit</a>
              </li>
            )}
          <li>
            <a onClick={() => openModal(record, "ArticleContent")}>
              Article full text
            </a>
          </li>

          {(((record.status === ArticleStatusEnum.NewArticle ||
            record.status === ArticleStatusEnum.Deleted) &&
            props.userRole === UserGroupEnum.EditorInChief) ||
            (record.status === ArticleStatusEnum.AddFromAuthor &&
              props.userRole === UserGroupEnum.Assistant)) && (
            <li>
              <a onClick={() => openModal(record, "status")}>change status</a>
            </li>
          )}

          <li>
            <a onClick={() => openModal(record, "log")}>status log</a>
          </li>
          <li>
            <a onClick={() => openModal(record, "info")}>Article Info</a>
          </li>

          {(record.status === ArticleStatusEnum.Revise ||
            record.status === ArticleStatusEnum.NewArticle ||
            record.status === ArticleStatusEnum.ReviseAuthorArticle) &&
            props.userRole === UserGroupEnum.Assistant && (
              <li>
                <a onClick={() => editArticle(record)}>Edit</a>
              </li>
            )}
          {record.status !== ArticleStatusEnum.Deleted && (
            <li>
              <a onClick={() => deleteArticle(record)}>Delete</a>
            </li>
          )}
        </ul>
      </div>
    );
  };

  const searchVolumes = (values) => {
    if (values) {
      setSelectedJournal(values);
      const element = journalRef.current.querySelector(".ant-select-selector");
      if (element) {
        element.style.border = "1px solid #d9d9d9";
      }
    } else {
      setSelectedJournal(0);
      form.setFieldsValue({ issueId: null });
    }
  };

  const changeRecommendedSwitch = (data) => {
    var finalData = {
      articleId: data.articleId,
    };

    onToggleRecommendedArticle(finalData, {
      onSuccess: (data) => {
        toast.success(<IntlMessages id="notification.successMessage" />);
        getArticles(articleSearchData);
      },
      onError: (err) => {
        toast.error(<IntlMessages id="notification.errorMessage" />);
      },
    });
  };

  const changeAllowDownloadSwitch = (data) => {
    var finalData = {
      articleId: data.articleId,
    };

    onToggleAllowDownloadArticle(finalData, {
      onSuccess: (data) => {
        toast.success(<IntlMessages id="notification.successMessage" />);
        getArticles(articleSearchData);
      },
      onError: (err) => {
        toast.error(<IntlMessages id="notification.errorMessage" />);
      },
    });
  };

  const changeRightAndPermisson = () => {
    var finalData = {
      articleId: selectedModalData.articleId,
      rightsAndPermissions: rightAndPermissonData,
    };

    changeRightAndPermissonArticle(finalData, {
      onSuccess: (data) => {
        toast.success(<IntlMessages id="notification.successMessage" />);
        setVisibleRightAndPermisson(false);
        getArticles(articleSearchData);
      },
      onError: (err) => {
        toast.error(<IntlMessages id="notification.errorMessage" />);
      },
    });
  };

  const renderAuthorList = (articleData) => {
    if (articleData.authors.length > 2) {
      return (
        <>
          <Link
            href={"/article/authors?email=" + articleData.authors[0].email}
            target="_blank"
          >
            {articleData.authors[0].fullName} ,{" "}
          </Link>
          <Link
            href={"/article/authors?email=" + articleData.authors[1].email}
            target="_blank"
          >
            {articleData.authors[1].fullName} ,{" "}
          </Link>
          <Link
            style={{ fontWeight: 500 }}
            onClick={() => openModal(articleData, "AuthorList")}
          >
            See All Authors
          </Link>
        </>
      );
    } else {
      return articleData.authors.map((author, index) => (
        <div key={author.id}>
          <Link href={"/article/authors?email=" + author.email} target="_blank">
            {author.fullName}
          </Link>
          {index !== articleData.authors.length - 1 && <Text> , </Text>}
        </div>
      ));
    }
  };

  useEffect(() => {
    if (authorEmail) {
      form.setFieldsValue({
        authorEmail: authorEmail,
      });
    }
  }, [authorEmail, form]);

  const onClickVolumeTree = (e) => {
    if (issueData.length === 0) {
      const element = journalRef.current.querySelector(".ant-select-selector");
      if (element) {
        element.style.border = "1px solid red";
      }
    }
  };

  return (
    <>
      <Form
        onFinish={searchArticle}
        layout="inline"
        form={form}
        initialValues={{ layout: "inline" }}
        className="ant-advanced-search-form"
        autoComplete="off"
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item name="journalId" label="Journal">
              <div ref={journalRef}>
                <Select
                  onChange={searchVolumes}
                  placeholder="Select Journal "
                  optionFilterProp="children"
                  allowClear
                >
                  {!isLoadingJournal &&
                    journalsData?.map((data) => {
                      return (
                        <Option key={data.jid} value={data.jid}>
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </div>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="issueId" label="Volume / Issue">
              <TreeSelect
                showSearch
                style={{ width: "100%" }}
                dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                placeholder="Please select"
                allowClear
                onClick={onClickVolumeTree}
                treeDefaultExpandAll
                treeNodeFilterProp="title"
              >
                {!isLoadingIssue &&
                  issueData &&
                  issueData?.map((data) => {
                    return (
                      <TreeNode
                        value={data.vid + ","}
                        key={data.vid + ","}
                        title={"volume " + data.volumeNumber}
                      >
                        {data.issues.map((issue) => {
                          return (
                            <TreeNode
                              value={data.vid + "," + issue.iid}
                              key={data.vid + "," + issue.iid}
                              title={"issue " + issue.issueNumber}
                            />
                          );
                        })}
                      </TreeNode>
                    );
                  })}
              </TreeSelect>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item label="Title" name="filter">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="status" label="status">
              <Select
                placeholder="Select Article Status "
                optionFilterProp="children"
                allowClear
              >
                <Option value={ArticleStatusEnum.AddFromAuthor}>
                  Preliminary review
                </Option>
                <Option value={ArticleStatusEnum.NewArticle}>
                  Under Review
                </Option>
                <Option value={ArticleStatusEnum.Revise}>Needs Revision</Option>
                <Option value={ArticleStatusEnum.ReviseAuthorArticle}>
                  Returned to the author
                </Option>
                <Option value={ArticleStatusEnum.Rejected}>rejected</Option>
                <Option value={ArticleStatusEnum.Accepted}>
                  Waiting for Assignment
                </Option>
                <Option value={ArticleStatusEnum.AssignedToVolume}>
                  Assigned to Volume
                </Option>
                <Option value={ArticleStatusEnum.Deleted}>Deleted</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Author Email" name="authorEmail">
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Table
        className="gx-table-responsive"
        dataSource={articlesData?.items}
        pagination={{
          defaultPageSize: PageSize.tables,
          total: articlesData?.totalCount,
          onChange: changePage,
          showSizeChanger: false,
        }}
        rowKey="articleId"
      >
        <Column width={350} title="Title" dataIndex="title" key="title" />

        <Column
          title="Status"
          dataIndex="status"
          key="status"
          width={150}
          render={(text, record: any) => (
            <Space size="middle">{listStatus(record.status)}</Space>
          )}
        />

        <Column
          title="Journal Name"
          dataIndex="journalName"
          key="journalName"
          width={200}
        />
        <Column
          title="Author List"
          dataIndex="authors"
          key="authors"
          render={(text, record: any) => <div>{renderAuthorList(record)}</div>}
        />
        <Column
          title="Submitted Date"
          dataIndex="submittedDate"
          key="submittedDate"
          render={(text, record: any) => (
            <Space size="middle">
              {record.submittedDate && record.submittedDate.split("T")[0]}
            </Space>
          )}
        />

        {props.userRole === UserGroupEnum.EditorInChief ? (
          <>
            <Column
              title="recommend"
              key="isRecommended"
              render={(text, record: any) => (
                <Space size="middle">
                  <Switch
                    defaultChecked={record?.isRecommended}
                    onChange={() => changeRecommendedSwitch(record)}
                  />
                </Space>
              )}
            />
            <Column
              title="Allow Download"
              key="allowDownload"
              render={(text, record: any) => (
                <Space size="middle">
                  <Switch
                    defaultChecked={record?.allowDownload}
                    onChange={() => changeAllowDownloadSwitch(record)}
                  />
                </Space>
              )}
            />
          </>
        ) : null}

        <Column
          title="Actions"
          dataIndex="articleId"
          key="articleId"
          render={(text, record: any) => (
            <Popover
              content={() => actionList(record)}
              title="Article Action"
              trigger="hover"
            >
              <a>Actions</a>
            </Popover>
          )}
        />
      </Table>
      {/* <NotificationContainer /> */}

      {props.userRole === UserGroupEnum.Assistant && (
        <Modal
          title="Final Submit"
          centered
          visible={visibleSubmit}
          footer={null}
          onCancel={() => setVisibleSubmit(false)}
          width={1000}
          destroyOnClose={true}
        >
          {visibleSubmit ? (
            <FinalSubmit
              fields={selectedModalData}
              editSection={true}
              closeModal={() => setVisibleSubmit(false)}
              sucessSubmit={() => getArticles(articleSearchData)}
            />
          ) : null}
        </Modal>
      )}
      {(props.userRole === UserGroupEnum.EditorInChief ||
        props.userRole === UserGroupEnum.Assistant) && (
        <Modal
          title="Change Article Status"
          centered
          visible={visibleStatus}
          footer={null}
          onCancel={() => setVisibleStatus(false)}
          width={1000}
          destroyOnClose={true}
        >
          {visibleStatus ? (
            <ChangeArticleStatus
              data={selectedModalData}
              userRole={props.userRole}
              closeModal={() => setVisibleStatus(false)}
              sucessSubmit={() => getArticles(articleSearchData)}
            />
          ) : null}
        </Modal>
      )}

      <Modal
        title="Article Status Log"
        centered
        visible={visibleLog}
        footer={null}
        onCancel={() => setVisibleLog(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleLog ? <ArticleStatusList data={selectedModalData} /> : null}
      </Modal>

      <Modal
        title={selectedModalData?.title + " full text"}
        centered
        visible={visibleArticleContent}
        footer={null}
        onCancel={() => setVisibleArticleContent(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleArticleContent ? (
          <ArticleContent articleId={selectedModalData.articleId} />
        ) : null}
      </Modal>

      <Modal
        title={selectedModalData?.title + " Rights And Permissions"}
        centered
        visible={visibleRightAndPermisson}
        footer={[
          <Button
            key="submit"
            type="primary"
            loading={loadingSaveRightAndPermisson}
            onClick={changeRightAndPermisson}
          >
            Save
          </Button>,
        ]}
        onCancel={() => setVisibleRightAndPermisson(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleRightAndPermisson && (
          <>
            <Editor
              value={rightAndPermissonDataDefault}
              onChange={(data, editor ) => {
                setRightAndPermissonData(data);
              }}
            />
          </>
        )}
      </Modal>

      {/* author List */}

      <Modal
        title={"Author List Of " + selectedModalData?.title}
        centered
        visible={visibleAuthorList}
        footer={null}
        onCancel={() => setVisibleAuthorList(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleAuthorList && (
          <Table
            className="gx-table-responsive"
            dataSource={selectedModalData.authors}
            rowKey="id"
          >
            <Column title="Full Name" dataIndex="fullName" key="fullName" />
            <Column
              title="Email"
              dataIndex="email"
              key="email"
              render={(text, record: any) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={"/article/authors?email=" + record.email}
                >
                  {record.email}
                </a>
              )}
            />
            <Column
              title="Orcid"
              dataIndex="idurl"
              key="idurl"
              render={(text, record: any) => (
                <a target="_blank" rel="noreferrer" href={record.idurl}>
                  {record.idurl}
                </a>
              )}
            />
            <Column
              title="Affiliation"
              dataIndex="affiliation"
              key="affiliation"
            />
          </Table>
        )}
      </Modal>

      {/* info article */}

      <Modal
        title=""
        centered
        visible={visibleInfo}
        footer={null}
        onCancel={() => setVisibleInfo(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleInfo && (
          <Descriptions
            title="Article Info"
            bordered
            column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
          >
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              span={2}
              label="Title "
            >
              {selectedModalData.title}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              span={2}
              label="Journal Name "
            >
              {" "}
              {selectedModalData.journalName}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              span={2}
              label="Authors "
            >
              {selectedModalData.authors.map((author, index) => {
                return (
                  <div key={author.id}>
                    <span>{author.fullName} </span>
                    {author.coAuthor && <span>( Corresponding Author )</span>}
                    {selectedModalData.authors.length !== index + 1 && (
                      <span>&nbsp; , &nbsp;</span>
                    )}
                  </div>
                );
              })}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="Keywords "
              span={2}
            >
              {selectedModalData.keywords.map((keyword, index) => {
                return (
                  <>
                    <span key={keyword.kid}>{keyword.keyword1}</span>
                    {selectedModalData.keywords.length !== index + 1 && (
                      <span>&nbsp; , &nbsp;</span>
                    )}
                  </>
                );
              })}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="Abstract "
              span={4}
            >
              <div
                dangerouslySetInnerHTML={{ __html: selectedModalData.abstract }}
              ></div>
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="Assistant Comment"
              span={4}
            >
              {selectedModalData.description}
            </Descriptions.Item>

            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="VOL/NO"
            >
              {selectedModalData.volume && (
                <span>volume : {selectedModalData.volume.volumeNumber}</span>
              )}
              {selectedModalData.volume && selectedModalData.issue && (
                <span>&nbsp; , &nbsp;</span>
              )}
              {selectedModalData.issue && (
                <span>issue : {selectedModalData.issue.issueNumber}</span>
              )}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="Visit Count"
            >
              {selectedModalData.visitCoun ? selectedModalData.visitCoun : 0}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="From Page"
            >
              {selectedModalData.fromPage ? selectedModalData.fromPage : 0}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="To Page"
            >
              {selectedModalData.toPage ? selectedModalData.toPage : 0}
            </Descriptions.Item>
            <Descriptions.Item
              span={2}
              labelStyle={{ fontWeight: "600" }}
              label="doi"
            >
              {selectedModalData.doi ? (
                <a
                  target="_blank"
                  href={"https://doi.org/" + selectedModalData.doi}
                  rel="noreferrer"
                >
                  {"https://doi.org/" + selectedModalData.doi}
                </a>
              ) : (
                ""
              )}
            </Descriptions.Item>
            {/* <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="Issn doi"
            >
              {selectedModalData.issndoi ? selectedModalData.issndoi : ""}
            </Descriptions.Item> */}
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="Allow Download"
            >
              {selectedModalData.allowDownload ? "yes" : "no"}
            </Descriptions.Item>
            <Descriptions.Item
              labelStyle={{ fontWeight: "600" }}
              label="Download Count"
            >
              {selectedModalData.downloadCount
                ? selectedModalData.downloadCount
                : 0}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Modal>
    </>
  );
};

export default List;
