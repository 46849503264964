import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { ContentsService } from "src/apis/services/contents/contentsService";
import { ContentTypeEnum } from "src/data/enums/contentType.enum";

export const useGetAllContents = (contentType: ContentTypeEnum) => {
  const contentsService = container.resolve(ContentsService);
  return useQuery({
    queryKey: [Api.Contents.getAllContents, contentType],
    queryFn: () => {
      return contentsService.getAllContents(contentType);
    }
  })
}
