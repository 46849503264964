import React, { useState } from "react";
import { Switch, Table, Modal, Space, Avatar, Image } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../../util/IntlMessages";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useDeleteWebHomeData } from "src/hooks/services/baseData/useDeleteWebHomeData";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";

import { useGetWebHomeCollectionDataList } from "src/hooks/services/baseData/useGetWebHomeCollectionDataList";
import { ContentTypeEnum } from "src/data/enums/contentType.enum";
const confirm = Modal.confirm;
const { Column } = Table;

const List = (props) => {
  const [page, setPage] = useState(1);
  const { data: webHomeData, isLoading } = useGetWebHomeCollectionDataList(page,props.data.webHomeDataCollectionId);
  const { mutate: onDeleteWebHomeData } = useDeleteWebHomeData(page,props.data.webHomeDataCollectionId);


  const changePage = (page) => {
    setPage(page);
  };

  const editNews = (data) => {
    props.changeTabEvent("2", data, page); // raftan be tab edit
  };

  const showDeleteConfirm = (data: WebHomeDataDto) => {
    confirm({
      title: "Are you sure?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteWebHomeData(data.webHomeDataId, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err: any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() { },
    });
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={webHomeData?.items}
        pagination={{
          defaultPageSize: PageSize.tables,
          total: webHomeData?.totalCount,
          onChange: changePage,
          showSizeChanger:false
        }}
        rowKey="webHomeDataId"
      >
        <Column
          title="Image"
          dataIndex="imageUrl"
          key="imageUrl"
          render={(text, record: any) => (
            <Avatar shape="square" size={80} src={<Image src={record.imageUrl} />} />
          )}
        />
        <Column title="Title" dataIndex="title" key="title" />
        <Column
          title="Connected To Journal"
          key="fkJournalId"
          render={(val, record: WebHomeDataDto) => (
            <Space size="middle">
              {record.fkJournalId == null ? "No" : "Yes"}
            </Space>
          )}
        />
        <Column
          title="Connected To Content"
          key="fkContentId"
          render={(val, record: WebHomeDataDto) => (
            <Space size="middle">
              {record.fkContentId == null ? "No" : "Yes"}
              {record.fkContentId != null && (
                `(${record.contentTypeId == ContentTypeEnum.Book ? 'Book' : record.contentTypeId == ContentTypeEnum.Conference ? 'Conference' : 'Course'})`
              )}
            </Space>
          )}
        />
        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editNews(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record: any) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>



    </>
  );
};

export default List;
