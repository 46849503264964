import { useQuery } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { container } from "tsyringe";
import { ContentsService } from "../../../../apis/services/contents/contentsService";

export const useGetTab = (id,isForContent) => {
    const contentsService = container.resolve(ContentsService);
    return useQuery({
        queryKey: [Api.Contents.getContentTabList,id,isForContent],
        queryFn: () => {
            return contentsService.getContentTabList(id , isForContent);
        }
    })
}
