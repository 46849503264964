import React from "react";
import {Route, Switch} from "react-router-dom";
import Slider from "./slider/index";
import FooterLink from "./footerLink/index";
import WebHomeData from "./webHomeData/index";
import journalsListTopic from "./journalsTopicList/index";
import bookMenuList from "./bookMenuList/index";
import contactUs from "./contactUs/index";
import aboutPublisher from "./aboutPublisher/index";
import ProtectedRoute from "../../components/ProtectedRoute";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const WebsiteData = ({match}) => (
  <Switch>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]}  path={`${match.url}/sliders`} component={Slider}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/footerlinks`} component={FooterLink}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/webhomedata`} component={WebHomeData}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/journalsListTopic`} component={journalsListTopic}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/bookMenuList`} component={bookMenuList}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/contactUs`} component={contactUs}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/aboutPublisher`} component={aboutPublisher}/>
  </Switch>
);

export default WebsiteData;
