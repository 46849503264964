import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";

import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { JournalIndicatorListDto } from "src/data/models/indicatorDtos/journalIndicatorListDto.interface";

export const useEditJournalIndicator = (id) => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();
    const toggleEdit = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<JournalIndicatorListDto[]>([Api.Journals.getJournalIndicators,id]);
            const findIndex = tempData.findIndex(x=>x.id === data.id) ;
            tempData[findIndex].value = data.value;
            tempData[findIndex].title = data.title;
            tempData[findIndex].indicatorId = data.indicatorId;
            queryClient.setQueryData([Api.Journals.getJournalIndicators,id],tempData )
        },
        [id],
    );
    return useMutation({
        mutationFn: (data: JournalIndicatorListDto) => {
            return journalsService.editJournalIndicator(data);
        },
        onSuccess: toggleEdit
    })

}
