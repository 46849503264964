import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { SubjectsService } from "src/apis/services/subjects/subjectsService";
import { SubjectDto } from "src/data/models/subjectDtos/subjectDto.interface";

import { container } from "tsyringe";

export const useEditSubjects = () => {
    const subjectsService = container.resolve(SubjectsService);
    const queryClient = useQueryClient();
    const toggleEditSubject = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<SubjectDto[]>([Api.Subjects.getSubjects]);
            const findIndex = tempData.findIndex(x=>x.id === data.id) ;
            tempData[findIndex].name = data.name;
            queryClient.setQueryData([Api.Subjects.getSubjects],tempData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: SubjectDto) => {
            return subjectsService.editSubjects(data);
        },
        onSuccess: toggleEditSubject
    })

}
