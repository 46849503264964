import React from "react";
import {Route, Switch} from "react-router-dom";
import Indicators from "./indicators";
import Users from "./users";
import News from "./news";
import IndexTypes from "./indexTypes";
import Subjects from "./subjects";
import Setting from "./setting";
import EditorFiles from "./editorFiles";
import ProtectedRoute from "../../components/ProtectedRoute";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
const BaseData = ({match}) => (
  <Switch>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/indicators`} component={Indicators}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin , UserGroupEnum.EditorInChief]} path={`${match.url}/users`} component={Users}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/news`} component={News}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/indexTypes`} component={IndexTypes}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/subjects`} component={Subjects}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin]} path={`${match.url}/setting`} component={Setting}/>
    <ProtectedRoute  roles={[UserGroupEnum.Admin , UserGroupEnum.Assistant]} path={`${match.url}/files`} component={EditorFiles}/>
  </Switch>
);

export default BaseData;
