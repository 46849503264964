import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndicatorsService } from "src/apis/services/indicators/indicatorsService";
import { IndicatorDto } from "src/data/models/indicatorDtos/idicatorDto.interface";
import { container } from "tsyringe";

export const useEditIndicators = () => {
    const indicatorsService = container.resolve(IndicatorsService);
    const queryClient = useQueryClient();
    const toggleEditIndicator = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<IndicatorDto[]>([Api.Indicators.getIndicators]);
            const findIndex = tempData.findIndex(x=>x.id === data.id) ;
            tempData[findIndex].title = data.title;
            tempData[findIndex].description = data.description;
            queryClient.setQueryData([Api.Indicators.getIndicators],tempData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: IndicatorDto) => {
            return indicatorsService.editIndicators(data);
        },
        onSuccess: toggleEditIndicator
    })

}
