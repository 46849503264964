import { useMutation, useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { ArticlesService } from "src/apis/services/article/articlesService";

export const useGetArticles = () => {
  const articlesService = container.resolve(ArticlesService);
  return useMutation({
    mutationFn: (searchObject: any) => {
      if (typeof searchObject.page !== "number") {
        searchObject.page = 1;
      }

      return articlesService.getArticleList({
        pageNumber: searchObject.page,
        pageSize: PageSize.tables,
        status: searchObject.status,
        filter: searchObject.filter,
        authorEmail: searchObject.authorEmail,
        issueId: searchObject.issueId,
        volumeId: searchObject.volumeId,
        journalId: searchObject.journalId,
        isDeleted: searchObject.isDeleted,
      });
    },
  });
};
