import React, { useState } from "react";
import { Card } from "antd";
import List from './column/List';



const FooterPage = () => {

  return (
    <Card className="gx-card" title="Footers">
          <List  />
    </Card>
  );
};

export default FooterPage;
