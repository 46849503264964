import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { NewsService } from "src/apis/services/news/newsService";

export const useGetNews = (page = 1) => {
    const newsService = container.resolve(NewsService);
    return useQuery({
        queryKey: [Api.News.getNews,page],
        queryFn: () => {

            if (typeof page !== 'number') {
                page = 1;
            }

            return newsService.getNews({
                pageNumber: page,
                pageSize: PageSize.tables,
            });
        }
    })
}
