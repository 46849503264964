import React, { useState } from "react";
import { Switch, Table, Modal, Space, Avatar, Image } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";
import { useGetWebHomeCollections } from "src/hooks/services/baseData/useGetWebHomeCollections";
import CollectionData from '../collection';
import { useDeleteWebHomeCollection } from "src/hooks/services/baseData/useDeleteWebHomeCollection";
import { webHomeDataCollectionDto } from "src/data/models/webHomeDataDtos/webHomeDataCollectionDto.interface";
const confirm = Modal.confirm;
const { Column } = Table;

const List = (props) => {
  const { data: webHomeData, isLoading } = useGetWebHomeCollections();
  const { mutate: onDeleteWebHomeData } = useDeleteWebHomeCollection();
  const [visibleCollection, setVisibleCollection] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState(null);


  const editNews = (data) => {
    props.changeTabEvent("2", data); // raftan be tab edit
  };

  const showDeleteConfirm = (data: webHomeDataCollectionDto) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteWebHomeData(data.webHomeDataCollectionId, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err: any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() { },
    });
  };

  
  const openModal = (data, type) => {
    setSelectedModalData(data);

    switch (type) {
      case "collection":
        setVisibleCollection(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={webHomeData}
        rowKey="webHomeDataCollectionId"
      >
        <Column
          title="Icon"
          dataIndex="iconUrl"
          key="iconUrl"
          render={(text, record: any) => (
            <Avatar shape="square" size={80} src={<Image src={record.imageUrl} />} />
          )}
        />
        <Column title="Title" dataIndex="title" key="title" />

        <Column
              title="Collection"
              key="action"
              render={(text, record) => (
                <Space size="middle">
                  <a onClick={() => openModal(record, "collection")}>
                  Add to this collection
                  </a>
                </Space>
              )}
            />

        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editNews(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record: any) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
       {/* <NotificationContainer /> */}


      <Modal
        title="Collection Data"
        centered
        visible={visibleCollection}
        footer={null}
        onCancel={() => setVisibleCollection(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleCollection ? <CollectionData data={selectedModalData} /> : null}
      </Modal>


    </>
  );
};

export default List;
