import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { FooterColumnDto } from "src/data/models/footerDtos/footerColumnDto.interface";
import { container } from "tsyringe";

export const useEditFooterColumn = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const editFooterColumn = useCallback(
    (data: FooterColumnDto, variables, context) => {
      let tempData = queryClient.getQueryData<FooterColumnDto[]>([Api.BaseData.getFooterColumn]);
      const findIndex = tempData.findIndex(x => x.id === data.id);
      tempData[findIndex].id = data.id;
      tempData[findIndex].title = data.title;
      queryClient.setQueryData([Api.BaseData.getFooterColumn], tempData)
    },
    [],
  );
  return useMutation({
    mutationFn: (data: FooterColumnDto) => {
      return baseDataService.editFooterColumn(data);
    },
    onSuccess: editFooterColumn
  })

}
