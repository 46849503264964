import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";

export const useEditWebHomeData = (currentPage, collectionId) => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const editWebHomeData = useCallback(
    (data: WebHomeDataDto, variables, context) => {
      let tempData = queryClient.getQueryData<PaginatedList<WebHomeDataDto>>([Api.BaseData.getWebHomeCollectionDataList, currentPage, collectionId]);
      const newData = [...tempData.items];
      const findIndex = newData.findIndex(x => x.webHomeDataId === data.webHomeDataId);
      newData[findIndex] = data;
      tempData.items = newData;
      queryClient.setQueryData([Api.BaseData.getWebHomeCollectionDataList, currentPage, collectionId], tempData)
    },
    [currentPage, collectionId],
  );
  return useMutation({
    mutationFn: (data: WebHomeDataDto) => {
      return baseDataService.editWebHomeData(data);
    },
    onSuccess: editWebHomeData
  })

}
