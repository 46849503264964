import { useMutation, useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { JournalsService } from "src/apis/services/journals/journalsService";

export const useGetAllIssues = (journalId) => {
    const journalsService = container.resolve(JournalsService);

    return useQuery({
        queryKey: [Api.Journals.GetJournalAllIssues,journalId],
        queryFn: () => {


            return journalsService.getJournalAllIssues(journalId);
        }
    })
}
