import React, { useState } from "react";
import { Switch, Table, Modal, Space } from "antd";
import { useToggleUserStatus } from "src/hooks/services/users/useToggleUserStatus";
import { ToastContainer, toast } from "react-toastify";
import IntlMessages from "../../../../util/IntlMessages";
import { useGetUsers } from "src/hooks/services/users/useGetUsers";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import JournalList from "./JournalList";
const confirm = Modal.confirm;
const { Column } = Table;
const List = (props) => {
  const [page, setPage] = useState(1);
  const { data: usersData, isLoading } = useGetUsers(page);

  const { mutate: onToggleUserStatus } = useToggleUserStatus(page);

  const [selectedModalData, setSelectedModalData] = useState({
    show: false,
    value: null,
  });

  const changePage = (page) => {
    setPage(page);
  };

  const editUsers = (data) => {
    props.changeTabEvent("2", data, page); // raftan be tab edit
  };

  const chaneActiveSwitch = (data) => {
    var finalData = {
      userId: data.userId,
    };

    onToggleUserStatus(finalData, {
      onSuccess: (data) => {
        toast.success(<IntlMessages id="notification.successMessage" />);
      },
      onError: (err) => {
        toast.error(<IntlMessages id="notification.errorMessage" />);
      },
    });
  };

  const openModal = (data) => {
    if (data) {
      setSelectedModalData({
        show: true,
        value: { ...data },
      });
    }
  };

  const closeModal = () => {
    setSelectedModalData({
      show: false,
      value: null,
    });
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={usersData?.items}
        pagination={{
          defaultPageSize: PageSize.tables,
          total: usersData?.totalCount,
          onChange: changePage,
          showSizeChanger: false,
        }}
        rowKey="userId"
      >
        <Column title="First Name" dataIndex="firstName" key="firstName" />
        <Column title="Last Name" dataIndex="lastName" key="lastName" />
        <Column
          title="User Name"
          dataIndex="userName"
          key="userName"
          render={(text, record: any) => (
            <Space size="middle">
              <a onClick={() => openModal(record)}>{record.userName}</a>
            </Space>
          )}
        />
        <Column
          title="Active"
          key="active"
          render={(text, record: any) => (
            <Space size="middle">
              <Switch
                defaultChecked={record?.active}
                onChange={() => chaneActiveSwitch(record)}
              />
            </Space>
          )}
        />
        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editUsers(record)}>Edit</a>
            </Space>
          )}
        />
      </Table>
      <Modal
        title={selectedModalData.value?.userName}
        centered
        visible={selectedModalData.show}
        footer={null}
        onCancel={closeModal}
        width={1000}
        destroyOnClose={true}
      >
        {selectedModalData.show ? (
          <JournalList data={selectedModalData.value?.jouranls} />
        ) : null}
      </Modal>
    </>
  );
};

export default List;
