import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { UserPermissionDto } from "src/data/models/usersDtos/userPermissionDto.interface";
import { AddUserPermissionDto } from "src/data/models/usersDtos/addUserPermissionDto.interface";

export const useAddUserPermission= (id) => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();

    const toggleAdd = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<UserPermissionDto[]>([Api.Journals.getJournalUserPermissions,id]);
            const finalData = [...tempData] ;
            finalData.push(data);
            queryClient.setQueryData([Api.Journals.getJournalUserPermissions,id],finalData )
        },
        [id],
    );

    return useMutation({
        mutationFn: (data: AddUserPermissionDto) => {
            return journalsService.addUserPermission(data);
        },
        onSuccess:toggleAdd
    })

}
