import { PaginatedList } from "src/data/models/paginatedList.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { ChangePasswordDto } from "src/data/models/usersDtos/changePasswordDto.interface";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class UsersService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getUsers",
      "getAllUsers",
      "addUsers",
      "editUsers",
      "toggleUserStatus",
      "getProfile"
    ]);
    this.setMiddlware(this.handleToken, [
      "getUsers",
      "getAllUsers",
      "addUsers",
      "editUsers",
      "toggleUserStatus",
      "getProfile"
    ]);
  }

  async getUsers(params: PaginationParams): Promise<PaginatedList<UsersDto>> {
    const result = await this.repositoryWrapper.Users.getUsers(params);
    return result.result;
  }
  async getAllUsers(): Promise<UsersDto[]> {
    const result = await this.repositoryWrapper.Users.getAllUsers();
    return result.result;
  }

  async addUsers(data: UsersDto): Promise<UsersDto> {
    const result = await this.repositoryWrapper.Users.addUsers(data);
    return result.result;
  }

  async editUsers(data: UsersDto): Promise<UsersDto> {
    const result = await this.repositoryWrapper.Users.editUsers(data);
    return result.result;
  }

  async toggleUserStatus(userId): Promise<number> {
    const result = await this.repositoryWrapper.Users.toggleUserStatus(userId);
    return result.result;
  }

  async getProfile(): Promise<UsersDto> {
    const result = await this.repositoryWrapper.Users.getProfile();
    return result.result;
  }

  async editProfile(data: UsersDto): Promise<UsersDto> {
    const result = await this.repositoryWrapper.Users.editProfile(data);
    return result.result;
  }

  async changePassword(data: ChangePasswordDto): Promise<boolean> {
    const result = await this.repositoryWrapper.Users.changePassword(data);
    return result.result;
  }
}
