import React, { useEffect, useState } from "react";
import { Form, Button, Checkbox, Upload, Select } from "antd";
import {  PlusOutlined } from "@ant-design/icons";
import { toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAddEditorFiles } from "src/hooks/services/baseData/useAddEditorFiles";
const Add = ({changeTabEvent}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddEditorFiles , isLoading:loadingSave} = useAddEditorFiles();
  const [formData, setFormData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);

  const saveNews = (values) => {


    if(file === null) {
      return;
    }


    const formData = new FormData();
    formData.append("File", file);
      formData.append("Title", JSON.stringify(values));

      onAddEditorFiles(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setFileList([]);
          setFile(null);
          changeTabEvent(Math.random());
        },
        onError: (err) => {
          toast.error(
           "File extension is invalid"
          );
        },
      });
    
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setFile(file);
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      var imageFile = [{
        uid: '-2',
        name: file.name,
        status: 'done',
        url: reader.result,
      }];
      setFileList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setFileList([]);
    setFile(null);

  }

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveFiles"
        onFinish={saveNews}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        {/* <Form.Item
          name="title"
          label="title"
          rules={[
            {
              required: true,
              message: "Please input title!",
            },
          ]}
        >
          <Input />
        </Form.Item> */}

        <Form.Item
          label="File"
          required
        >
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={fileList}
            showUploadList={{ showPreviewIcon: false }}
          >
             {fileList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>
        <Form.Item>
          <Button loading = {loadingSave} type="primary" htmlType="submit">
            save
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
