import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
import { HomeDataTypeEnum } from "src/data/enums/homeDataType.enum";
import { ContentTypeEnum } from "src/data/enums/contentType.enum";

const TabPane = Tabs.TabPane;

const WebHomeCollections = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [collectionsEditData, setCollectionsEditData] = useState(null);
  const [collectionsEditFlag, setCollectionsEditFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [radioType, setRadioType] = useState(HomeDataTypeEnum.Journal);
  const callback = (key, data = null, currentPage = 1) => {
    setActiveTab(key);
    if (data && key === "2") {
      setCollectionsEditData(data);
      setCollectionsEditFlag(true);
      setCurrentPage(currentPage);

      if (data.fkContentId == null && data.fkJournalId == null) {
        setRadioType(HomeDataTypeEnum.Custom);
      }

      if (data.fkContentId != null) {
        switch (data.contentTypeId) {
          case ContentTypeEnum.Book:
            setRadioType(HomeDataTypeEnum.Book);
            break;
          case ContentTypeEnum.Conference:
            setRadioType(HomeDataTypeEnum.Conference);
            break;
          case ContentTypeEnum.Course:
            setRadioType(HomeDataTypeEnum.Course);
            break;
          default:
            break;
        }
      }

      if (data.fkJournalId != null) {
        setRadioType(HomeDataTypeEnum.Journal);
      }


    } else if (key === "2") {
      setCollectionsEditFlag(false);
      setCollectionsEditData(null);
    }
  };

  const changeRadio = (data) => {
    setRadioType(data);
  }

  return (
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="List" key="1">
            <List changeTabEvent={callback} data = {props.data}/>
          </TabPane>
          <TabPane tab="Add / Edit Collection Data" key="2">
            <Add
              fields={collectionsEditData}
              editSection={collectionsEditFlag}
              changeTabEvent={callback}
              currentPage={currentPage}
              data = {props.data}
              radioType = {radioType}
              changeRadio = {changeRadio}
            />
          </TabPane>
        </Tabs>
  );
};

export default WebHomeCollections;
