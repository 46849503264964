import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { ArticlesService } from "src/apis/services/article/articlesService";
import { AuthorDto } from "src/data/models/articleDtos/authorDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
export const useDeleteAuthor= ({page = 1 , fullName , email}) => {
    const articlesService = container.resolve(ArticlesService);
    

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (targetId) => {


            let tempObject = queryClient.getQueryData<PaginatedList<AuthorDto>>([Api.Articles.getAuthors , page , fullName , email]);
            for (let index = 1; index <= tempObject?.totalPages; index++) {
                queryClient.removeQueries([Api.Articles.getAuthors , index]);
                
            }
            queryClient.removeQueries([Api.Articles.getAllAuthors]);

        },
        [page , fullName , email],
    )

    return useMutation({
        mutationFn: (id: number) => {
            return articlesService.deleteAuthor(id);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}