import React, { useState } from "react";
import { Card, Tabs } from "antd";
import List from './list/List';



const ContactUsPage = () => {


  return (
    <Card className="gx-card" title="Contact Us">
          <List />
    </Card>
  );
};

export default ContactUsPage;
