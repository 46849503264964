import React from "react";
import { Divider, Table, Modal, Avatar, Image } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../../util/IntlMessages";
import { useDeleteFooterLink } from "src/hooks/services/baseData/useDeleteFooterLink";
import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";

const confirm = Modal.confirm;

const List = (props) => {

  const { mutate: onDeleteFooterLink } = useDeleteFooterLink();

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Link",
      dataIndex: "footerLinkUrl",
      key: "footerLinkUrl",
      render: (text) => <span><a target={'_blank'} href={text}>{text}</a></span>,
    },
    {
      title: "Type",
      dataIndex: "footerType",
      key: "footerType",
      render: (val) => <span>{val === true ? "Custom Data" : "Link"}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <span onClick={() => editData(record)} className="gx-link">Edit</span>
          <Divider type="vertical" />
          <span onClick={() => showDeleteConfirm(record)} className="gx-link">
            Delete
          </span>
        </span>
      ),
    },
  ];

  const showDeleteConfirm = (data: FooterLinkDto) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteFooterLink(data.id, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err) => {
            toast.error(
              <IntlMessages id="notification.errorMessage" />
            );
          },
        });
      },
      onCancel() { },
    });
  };

  const editData = (data) => {

    props.changeTabEvent("2", data); // raftan be tab edit

  }

  return (
    <>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={props.footerLinksData}
        rowKey="id"
        pagination={false}
      />
       {/* <NotificationContainer /> */}
    </>
  );
};

export default List;
