import React,{useState} from "react";
import {Tabs,Card} from "antd";
import MenuList from './MenuList';
import MenuAdd from './MenuAdd';
const TabPane = Tabs.TabPane;


const ContentMenuPage = (props) => {
  const [activeTab , setActiveTab] = useState("1");
  const [menuEditData , setMenuEditData] = useState(null);
  const [menuEditFlag , setMenuEditFlag] = useState(false);
  const callback = (key , data = null) => {
    setActiveTab(key);
    if(data && key === "2") {
      setMenuEditData(data);
      setMenuEditFlag(true);
    } else if(key === "2") {
      setMenuEditFlag(false);
      setMenuEditData(null);
    }
  };

  return (
    <Card className="gx-card" title="Book List Menu">
      <Tabs  activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
          <MenuList changeTabEvent = {callback}/>
        </TabPane>
        <TabPane  tab="Add / Edit " key="2">
        <MenuAdd fields = {menuEditData} editSection = {menuEditFlag} changeTabEvent = {callback} />
        </TabPane>
      </Tabs>
    </Card>

  );
};

export default ContentMenuPage;
