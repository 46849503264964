import { useMutation } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { AuthService } from "src/apis/services/auth/auth";

export const useGetBaseInfo = () => {
    const authService = container.resolve(AuthService);

    return useMutation({
        mutationFn: () => {
            return authService.getBaseInfo();
        }
    })

}
