import React, { useEffect, useState } from "react";
import { Form, Input,InputNumber, Button, Select } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAddVolumes } from "src/hooks/services/journals/volume/useAddVolumes";
import { useEditVolumes } from "src/hooks/services/journals/volume/useEditVolumes";
const { Option } = Select;
const { TextArea } = Input;

const Add = ({ fields, editSection = false ,changeTabEvent,currentPage,journals})  => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddVolumes, isLoading:loadingSave  } = useAddVolumes();
  const { mutate: onEditVolumes, isLoading:loadingEdit  } = useEditVolumes(currentPage);
  const [formData, setFormData] = useState([]);


    useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [fields, editSection]);


  const saveVolume = (values) => {
    if (editSection) {
      values.vid = fields.vid ;
      onEditVolumes(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
    else {
      onAddVolumes(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
        },
        onError: (err:any) => {
          toast.error(
            err.message
          );
        },
      });
    }

  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={saveVolume}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >

<Form.Item
          name="journalId"
          label="Journal"
          rules={[
            {
              required: true,
              message: "Please Select Journal!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Journal"
            optionFilterProp="children"
          >

           { journals.map((data) => {
             return (
            <Option  key={data.jid} value={data.jid}>{data.name}</Option>
             )
           })}
          </Select>
        </Form.Item>

        <Form.Item
          name="volumeNumber"
          label="Volume Number"
          rules={[
            {
              required: true,
              message: "Please input volume Number",
            },
          ]}
        >
          <Input placeholder="Volume Number"/>
        </Form.Item>

        <Form.Item
          name="volumeTitle"
          label="Volume Title"
        >
          <Input placeholder="Volume Title"/>
        </Form.Item>

        <Form.Item name="volumeDescription" label="description">
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
        </Form.Item>
        <Form.Item
          name="year"
          label="Year"
          rules={[
            {
              required: true,
              message: "Please input year!",
            },
          ]}
        >
          <InputNumber placeholder="year"/>
        </Form.Item>
        <Form.Item>
          <Button loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
          {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
