import { ContactUsDto } from "src/data/models/contactUsDtos/contactUsDto.interface";
import { FooterColumnDto } from "src/data/models/footerDtos/footerColumnDto.interface";
import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { HomeDataPaginationParams } from "src/data/models/params/homeDataPaginationParams.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { AboutPublisherDto } from "src/data/models/settingDtos/aboutPublisherDto.interface";
import { SettingDto } from "src/data/models/settingDtos/settingDto.interface";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";
import { webHomeDataCollectionDto } from "src/data/models/webHomeDataDtos/webHomeDataCollectionDto.interface";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";
import { editorFilesDto } from "src/data/models/editorFiles/editorFilesDto.interface";

@singleton()
export class BaseDataService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getSetting",
      "editSetting",
      "getWebHomeCollectionDataList",
      "addWebHomeData",
      "editWebHomeData",
      "deleteWebHomeData",
      "getWebHomeCollections",
      "addWebHomeCollection",
      "editWebHomeCollection",
      "deleteWebHomeCollection",
      "toggleWebHomeCollectionStatus",
      "getAboutPublisher",
      "getSliders",
      "getContactUs",
      "addSlider",
      "editSlider",
      "deleteSlider",
      "getFooterLinks",
      "getFooterColumn",
      "addFooterLink",
      "editFooterLink",
      "editFooterColumn",
      "deleteFooterLink",

      "getEditorFiles",
      "addEditorFiles",
      "deleteEditorFiles",


    ]);
    this.setMiddlware(this.handleToken, [
      "getSetting",
      "editSetting",
      "getWebHomeCollectionDataList",
      "addWebHomeData",
      "editWebHomeData",
      "deleteWebHomeData",
      "getAboutPublisher",
      "getWebHomeCollections",
      "addWebHomeCollection",
      "editWebHomeCollection",
      "deleteWebHomeCollection",
      "toggleWebHomeCollectionStatus",

      
      "getSliders",
      "getContactUs",
      "addSlider",
      "editSlider",
      "deleteSlider",
      "getFooterLinks",
      "getFooterColumn",
      "addFooterLink",
      "editFooterLink",
      "editFooterColumn",
      "deleteFooterLink",

      "getEditorFiles",
      "addEditorFiles",
      "deleteEditorFiles",

    ]);
  }

  async getSetting(): Promise<SettingDto> {
    const result = await this.repositoryWrapper.BaseData.getSetting();
    return result.result;
  }

  async editSetting(data: SettingDto): Promise<SettingDto> {
    const result = await this.repositoryWrapper.BaseData.editSetting(data);
    return result.result;
  }

  async getAboutPublisher(): Promise<string> {
    const result = await this.repositoryWrapper.BaseData.getAboutPublisher();
    return result.result;
  }

  async editAboutPublisher(data: AboutPublisherDto): Promise<AboutPublisherDto> {
    const result = await this.repositoryWrapper.BaseData.editAboutPublisher(data);
    return result.result;
  }

  async getSliders(): Promise<SliderDto[]> {
    const result = await this.repositoryWrapper.BaseData.getSliders();
    return result.result;
  }

  async getContactUs(): Promise<ContactUsDto[]> {
    const result = await this.repositoryWrapper.BaseData.getContactUs();
    return result.result;
  }

  async getSlider(sliderId: any): Promise<SliderDto> {
    const result = await this.repositoryWrapper.BaseData.getSlider(sliderId);
    return result.result;
  }

  async addSlider(data: SliderDto): Promise<SliderDto> {
    const result = await this.repositoryWrapper.BaseData.addSlider(data);
    return result.result;
  }

  async editSlider(data: SliderDto): Promise<SliderDto> {
    const result = await this.repositoryWrapper.BaseData.editSlider(data);
    return result.result;
  }

  async deleteSlider(sliderId: any): Promise<number> {
    const result = await this.repositoryWrapper.BaseData.deleteSlider(sliderId);
    return result.result;
  }

  //
  async getFooterLinks(): Promise<FooterLinkDto[]> {
    const result = await this.repositoryWrapper.BaseData.getFooterLinks();
    return result.result;
  }  
  //
  async getFooterColumn(): Promise<FooterColumnDto[]> {
    const result = await this.repositoryWrapper.BaseData.getFooterColumn();
    return result.result;
  }

  async addFooterLink(data: FooterLinkDto): Promise<FooterLinkDto> {
    const result = await this.repositoryWrapper.BaseData.addFooterLink(data);
    return result.result;
  }

  async editFooterLink(data: FooterLinkDto): Promise<FooterLinkDto> {
    const result = await this.repositoryWrapper.BaseData.editFooterLink(data);
    return result.result;
  }

  async editFooterColumn(data: FooterColumnDto): Promise<FooterColumnDto> {
    const result = await this.repositoryWrapper.BaseData.editFooterColumn(data);
    return result.result;
  }

  async deleteFooterLink(id: any): Promise<FooterLinkDto> {
    const result = await this.repositoryWrapper.BaseData.deleteFooterLink(id);
    return result.result;
  }

  //
  async getWebHomeCollectionDataList(params: HomeDataPaginationParams): Promise<PaginatedList<WebHomeDataDto>> {
    const result = await this.repositoryWrapper.BaseData.getWebHomeCollectionDataList(params);
    return result.result;
  }

  async addWebHomeData(data: WebHomeDataDto): Promise<WebHomeDataDto> {
    const result = await this.repositoryWrapper.BaseData.addWebHomeData(data);
    return result.result;
  }

  async editWebHomeData(data: WebHomeDataDto): Promise<WebHomeDataDto> {
    const result = await this.repositoryWrapper.BaseData.editWebHomeData(data);
    return result.result;
  }

  async deleteWebHomeData(id: any): Promise<number> {
    const result = await this.repositoryWrapper.BaseData.deleteWebHomeData(id);
    return result.result;
  }


    async getWebHomeCollections(): Promise<webHomeDataCollectionDto[]> {
      const result = await this.repositoryWrapper.BaseData.getWebHomeCollections();
      return result.result;
    }
  
    async addWebHomeCollection(data: webHomeDataCollectionDto): Promise<webHomeDataCollectionDto> {
      const result = await this.repositoryWrapper.BaseData.addWebHomeCollection(data);
      return result.result;
    }
  
    async editWebHomeCollection(data: webHomeDataCollectionDto): Promise<webHomeDataCollectionDto> {
      const result = await this.repositoryWrapper.BaseData.editWebHomeCollection(data);
      return result.result;
    }
  
    async deleteWebHomeCollection(id: any): Promise<number> {
      const result = await this.repositoryWrapper.BaseData.deleteWebHomeCollection(id);
      return result.result;
    }


    async toggleWebHomeCollectionStatus(id): Promise<number> {
      const result = await this.repositoryWrapper.BaseData.toggleWebHomeCollectionStatus(
        id
      );
      return result.result;
    }


    async getEditorFiles(params: PaginationParams): Promise<PaginatedList<editorFilesDto>> {
      const result = await this.repositoryWrapper.BaseData.getEditorFiles(params);
      return result.result;
    }
  
    async addEditorFiles(data: editorFilesDto): Promise<editorFilesDto> {
      const result = await this.repositoryWrapper.BaseData.addEditorFiles(data);
      return result.result;
    }
  
    async deleteEditorFiles(id: any): Promise<number> {
      const result = await this.repositoryWrapper.BaseData.deleteEditorFiles(id);
      return result.result;
    }


}
