import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { IssueDto } from "src/data/models/journalDtos/issueDto.interface";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";
export const useDeleteIssues = (currentPage,journalId , volumeId) => {
    const journalsService = container.resolve(JournalsService);

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (targetIssueId) => {

            let tempObject = queryClient.getQueryData<PaginatedList<VolumeWithIssuesDto>>([Api.Journals.GetJournalIssues , currentPage,journalId]);
            for (let index = 1; index <= tempObject?.totalPages; index++) {
                queryClient.removeQueries([Api.Journals.GetJournalIssues , index,journalId]);
                
            }
            queryClient.removeQueries([Api.Journals.GetJournalAllIssues ,journalId]);

        },
        [currentPage,journalId],
    )

    return useMutation({
        mutationFn: (id: number) => {
            return journalsService.deleteIssue(id);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}