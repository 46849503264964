import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { IssueDto } from "src/data/models/journalDtos/issueDto.interface";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";

export const useEditIssues = (currentPage, journalId, volumeId) => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();
    const toggleEdit = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<VolumeWithIssuesDto>>([Api.Journals.GetJournalIssues,currentPage,journalId]);
            const volumeIndex = tempData.items.findIndex(x=>x.vid === volumeId);
            const issueIndex = tempData.items[volumeIndex].issues.findIndex(x=>x.iid === data.iid) ;
            const volumeData = [...tempData.items[volumeIndex].issues];
            volumeData[issueIndex] = data;
            tempData.items[volumeIndex].issues = volumeData;
            queryClient.setQueryData([Api.Journals.GetJournalIssues,currentPage,journalId],tempData );

                  
    let allData = queryClient.getQueryData<VolumeWithIssuesDto[]>([
        Api.Journals.GetJournalAllIssues,
        journalId,
      ]);
      

    if (allData) {
      const volumeIndex2 = allData.findIndex((x) => x.vid === volumeId);
      const issueIndex2 = allData[volumeIndex2].issues.findIndex(x=>x.iid === data.iid) ;
      const volumeData2 = [...allData[volumeIndex2].issues];
      volumeData2[issueIndex2] = data;
      allData[volumeIndex2].issues = volumeData2;
      queryClient.setQueryData(
        [Api.Journals.GetJournalAllIssues, journalId],
        allData
      );
    }

        },
        [currentPage, journalId, volumeId],
    );
    return useMutation({
        mutationFn: (data: IssueDto) => {
            return journalsService.editIssue(data);
        },
        onSuccess: toggleEdit
    })

}
