import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndicatorDto } from "src/data/models/indicatorDtos/idicatorDto.interface";
import { container } from "tsyringe";
import { useCallback } from "react"
import { NewsService } from "src/apis/services/news/newsService";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
export const useDeleteNews = (currentPage) => {
    const newsService = container.resolve(NewsService);
    

    const queryClient = useQueryClient();

    const toggleDeleteNews = useCallback(
        (targetNewsId) => {

            let tempObject = queryClient.getQueryData<PaginatedList<NewsDto>>([Api.News.getNews , currentPage]);
            for (let index = 1; index <= tempObject?.totalPages; index++) {
                queryClient.removeQueries([Api.News.getNews , index]);
            }
        },
        [currentPage],
    )

    return useMutation({
        mutationFn: (newsId: number) => {
            return newsService.deleteNews(newsId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDeleteNews(targetId);
        }
    })
}