import React, { useEffect, useState } from "react";
import { Form, Input, Button, Radio, Card, Upload, Select } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../../util/IntlMessages";
import TextArea from "antd/lib/input/TextArea";
import { useAddFooterLink } from "src/hooks/services/baseData/useAddFooterLink";
import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";
import { useEditFooterLink } from "src/hooks/services/baseData/useEditFooterLink";
import Editor from "src/components/Editor";

const Add = ({ fields, editSection = false, changeTabEvent, columnId }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const { mutate: onAddFooterLink, isLoading: loadingSave } =
    useAddFooterLink();
  const { mutate: onEditFooterLink, isLoading: loadingEdit } =
    useEditFooterLink();
  const [descContent, setDescContent] = useState("");
  const [descContentDefault, setDescContentDefault] = useState("");
  const [footerType, setFooterType] = useState<any>(false);

  const footerTypeOptions = [
    { title: "Custom Data", value: true },
    { title: "Link", value: false },
  ];

  useEffect(() => {
    if (fields) {
      setFooterType(fields.footerType ?? false);
      if (editSection) {
        fields.footerType = fields.footerType == true ? "true" : "false";
      }
      form.setFieldsValue(fields);
      if (fields.footerContent) {
        setDescContent(fields.footerContent);
        setDescContentDefault(fields.footerContent);
      }
    }
    return () => {
      form.resetFields();
      setFormData([]);
    };
  }, [fields, editSection]);

  const saveSlider = (values: FooterLinkDto) => {
    values.footerContent = descContent;
    values.fkFooterColumnId = columnId;

    values.footerType = footerType;
    if (values.footerType === true) values.footerLinkUrl = null;
    else values.footerContent = null;

    if (editSection) {
      values.id = fields.id;

      onEditFooterLink(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      onAddFooterLink(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setDescContent("");
          setDescContentDefault("");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const footerChangeHandler = (value, e) => {
    setFooterType(value == "true" ? true : false);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveSlider}
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="Link Title"
          rules={[
            {
              required: true,
              message: "Please enter Link title!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="footerType"
          label="Footer Type"
          rules={[
            {
              required: true,
              message: "Please select footer type!",
            },
          ]}
        >
          <Select
            // showSearch
            onChange={footerChangeHandler}
            placeholder="Select Footer Type"
            optionFilterProp="children"
          >
            {footerTypeOptions.map((data) => {
              return (
                <Select.Option
                  key={data.value.toString()}
                  value={data.value.toString()}
                >
                  {data.title}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>

        {footerType == true && (
          <Form.Item
            label="Footer Content"
            rules={[
              {
                required: true,
                message: "Please enter content!",
              },
            ]}
          >
            <Editor
              value={descContentDefault}
              onChange={(data, editor) => {
                setDescContent(data);
              }}
            />
          </Form.Item>
        )}

        {footerType == false && (
          <Form.Item
            name="footerLinkUrl"
            label="Link"
            rules={[
              {
                required: true,
                message: "Please input footer link!",
              },
              { type: "url" },
            ]}
          >
            <Input />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
