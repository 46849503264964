import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { NewsService } from "src/apis/services/news/newsService";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";

export const useEditNews = (currentPage) => {
    const newsService = container.resolve(NewsService);
    const queryClient = useQueryClient();
    const toggleEditUser = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<NewsDto>>([Api.News.getNews,currentPage]);
            const newData = [...tempData.items] ;
            const findIndex = newData.findIndex(x=>x.newsId === data.newsId) ;
            newData[findIndex] = data;
            tempData.items = newData;
            queryClient.setQueryData([Api.News.getNews,currentPage],tempData )
        },
        [currentPage],
    );
    return useMutation({
        mutationFn: (data: NewsDto) => {
            return newsService.editNews(data);
        },
        onSuccess: toggleEditUser
    })

}
