import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";

export const useGetAllArticleStatusLogs = (articleId:number) => {
    const articlesService = container.resolve(ArticlesService);
    return useQuery({
        queryKey: [Api.Articles.getAllArticleStatusLogs,articleId],
        queryFn: () => {
            return articlesService.getAllArticleStatusLogs(articleId);
        }
    })
}
