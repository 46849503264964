import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { UsersService } from "src/apis/services/users/usersService";

export const useGetProfile = () => {
    const usersService = container.resolve(UsersService);
    return useQuery({
        queryKey: [Api.Users.getProfile],
        queryFn: () => {
            return usersService.getProfile();
        }
    })
}
