import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { ContentListDto } from "src/data/models/contentsDtos/contentListDto.interface";
import { JournalDto } from "src/data/models/journalDtos/journalDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";

export const useAddJournals = () => {
    const contentsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();

    const toggleAddJournals = useCallback(
        (data, variables, context) => {

            queryClient.removeQueries([Api.Journals.getJournalList]);

        },
        [],
    );
    return useMutation({
        mutationFn: (data: JournalDto) => {
            return contentsService.addJournals(data);
        },
        onSuccess: toggleAddJournals
        
    })

}
