import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { container } from "tsyringe";

export const useGetSetting = () => {
    const subjectsService = container.resolve(BaseDataService);

    return useQuery({
        queryKey: [Api.BaseData.getSetting],
        queryFn: () => {
            return subjectsService.getSetting();
        }
    })
}
