import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";

export const useGetWebHomeCollections = () => {
  const baseDataService = container.resolve(BaseDataService);

  return useQuery({
    queryKey: [Api.BaseData.getWebHomeCollections],
    queryFn: () => {

      return baseDataService.getWebHomeCollections();
    }
  })
}
