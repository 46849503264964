import { useMutation,useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { ContentsService } from "../../../../apis/services/contents/contentsService";
import { TabsListDto } from "../../../../data/models/contentsDtos/tabsListDto.interface";
export const useDeleteTab = (id,isForContent) => {
    const contentsService = container.resolve(ContentsService);

    const queryClient = useQueryClient();

    const toggleDeleteTab = useCallback(
        (menuId) => {
            let tempObject = queryClient.getQueryData<TabsListDto[]>([Api.Contents.getContentTabList,id,isForContent])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.id !== menuId)
            queryClient.setQueryData([Api.Contents.getContentTabList,id,isForContent], finalData)
        },
        [],
    )

    return useMutation({
        mutationFn: (menuId: number) => {
            return contentsService.deleteTabList(menuId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDeleteTab(targetId);
        }
    })
}