import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
const TabPane = Tabs.TabPane;

const WebHomeData = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [homeDataEditData, setHomeDataEditData] = useState(null);
  const [homeDataEditFlag, setHomeDataEditFlag] = useState(false);
  const callback = (key, data = null) => {
    setActiveTab(key);
    if (data && key === "2") {
      setHomeDataEditData(data);
      setHomeDataEditFlag(true);
    } else if (key === "2") {
      setHomeDataEditFlag(false);
      setHomeDataEditData(null);
    }
  };

  return (
    <Card className="gx-card" title="Website Hom Data list">
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="List" key="1">
            <List changeTabEvent={callback} />
          </TabPane>
          <TabPane tab="Add / Edit Homepage Data" key="2">
            <Add
              fields={homeDataEditData}
              editSection={homeDataEditFlag}
              changeTabEvent={callback}
            />
          </TabPane>
        </Tabs>
    </Card>
  );
};

export default WebHomeData;
