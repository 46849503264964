import React, { useEffect, useState } from "react";
import { Switch, Table, Modal, Space, Avatar, Image } from "antd";
import { ToastContainer, toast } from "react-toastify";
import IntlMessages from "../../../../util/IntlMessages";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetEditorFiles } from "src/hooks/services/baseData/useGetEditorFiles";
import { useDeleteEditorFiles } from "src/hooks/services/baseData/useDeleteEditorFiles";
const confirm = Modal.confirm;
const { Column } = Table;
const List = (props) => {
  const [page, setPage] = useState(1);
  const { data: filesData, isLoading, mutate: getFiles } = useGetEditorFiles();
  const { mutate: onDeleteEditorFiles } = useDeleteEditorFiles();

  useEffect(() => {
      getFiles(page);
  }, [page, getFiles, props.newFile]);

  const changePage = (page) => {
    setPage(page);
  };

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure delete this file?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteEditorFiles(data.efid, {
          onSuccess: (data) => {
            toast.success(<IntlMessages id="notification.successMessage" />);
            getFiles(page);
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      },
      onCancel() {},
    });
  };

  const copyDownload = (data) => {
    navigator?.clipboard?.writeText(data?.fileUrl);
    toast.success(<IntlMessages id="notification.copy" />);
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={filesData?.items}
        pagination={{
          defaultPageSize: PageSize.tables,
          total: filesData?.totalCount,
          onChange: changePage,
          showSizeChanger: false,
        }}
        rowKey="efid"
      >
        <Column title="Title" dataIndex="fileTitle" key="fileTitle" />
        <Column
          title="Download File"
          dataIndex="newsImfileUrlageUrl"
          key="fileUrl"
          render={(text, record: any) => <a href={record.fileUrl}>Download</a>}
        />
        <Column
          title="Copy"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => copyDownload(record)}>Copy</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
      {/* <NotificationContainer /> */}
    </>
  );
};

export default List;
