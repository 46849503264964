import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Upload,
  Select,
  InputNumber,
  Radio,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../util/IntlMessages";

import Editor from "../../../components/Editor";
import { useGetJournalUserPermissions } from "src/hooks/services/journals/journalUserPermisson/useGetJournalUserPermissions";
import { useSendMessage } from "src/hooks/services/messages/useSendMessage";
import { useGetJournals } from "src/hooks/services/journals/useGetJournals";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
const { TextArea } = Input;
const { Option } = Select;
const Add = ({ role, changeTabEvent }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [selectedJournal, setSelectedJournal] = useState(0);
  const { data: journalsData, isLoading: isLoadingJournal } = useGetJournals();
  const { data: usersData, isLoading: isLoadingUser } =
    useGetJournalUserPermissions(selectedJournal);
  const { mutate: onSendMessage, isLoading: loadingSend } = useSendMessage();
  const [formData, setFormData] = useState([]);
  const [messageText, setMessageText] = useState("");
  const [count, setCount] = React.useState(0);

  const sendMessage = (values) => {
    if (!messageText) {
      return;
    }
    values.text = messageText;
    onSendMessage(values, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />
        );
        form.resetFields();
        setMessageText("");
      },
      onError: (err: any) => {
        toast.error(err?.response.data.message);
      },
    });
  };

  const changeJournal = (journalId) => {
    setSelectedJournal(journalId);
    form.setFieldsValue({
      fkReceiverId: null,
    });
  };

  const reciverOption = () => {
    if (role === UserGroupEnum.Admin) {
      return usersData.map((data) => {
        return (
          <Option key={data.userId} value={data.userId}>
            {data.userName + " ( " + data.roleTitle + " )"}
          </Option>
        );
      });
    }

    if (role === UserGroupEnum.EditorInChief) {
      const assistantData = usersData.filter(
        (c) => c.userGroupId === UserGroupEnum.Assistant
      );
      return assistantData.map((data) => {
        return (
          <Option key={data.userId} value={data.userId}>
            {data.userName + " ( " + data.roleTitle + " )"}
          </Option>
        );
      });
    } else {
      const editorData = usersData.filter(
        (c) => c.userGroupId === UserGroupEnum.EditorInChief
      );
      return editorData.map((data) => {
        return (
          <Option key={data.userId} value={data.userId}>
            {data.userName + " ( " + data.roleTitle + " )"}
          </Option>
        );
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveMessage"
        onFinish={sendMessage}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="journalId"
          label="Journal"
          rules={[
            {
              required: true,
              message: "Please Select Journal!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Journal"
            optionFilterProp="children"
            onChange={changeJournal}
          >
            {!isLoadingJournal &&
              journalsData.map((data) => {
                return (
                  <Option key={data.jid} value={data.jid}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="fkReceiverId"
          label="Send Message To"
          rules={[
            {
              required: true,
              message: "Please Select Receiver!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Receiver"
            optionFilterProp="children"
          >
            {role !== UserGroupEnum.Admin && (
              <Option key="1" value="1">
                Admin
              </Option>
            )}
            {!isLoadingUser && reciverOption()}
          </Select>
        </Form.Item>
        <Form.Item
          name="subject"
          label="Subject"
          rules={[
            {
              required: true,
              message: "Please input subject",
            },
          ]}
        >
          <Input placeholder="Subject" />
        </Form.Item>
        <Form.Item label="Text" required>
          <Editor
            value=''
            onChange={(data, editor) => {
              setMessageText(data);
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button loading={loadingSend} type="primary" htmlType="submit">
            {"Send"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
