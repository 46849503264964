import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";
import { container } from "tsyringe";

export const useEditSlider = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const editSlider = useCallback(
    (data: SliderDto, variables, context) => {
      let tempData = queryClient.getQueryData<SliderDto[]>([Api.BaseData.getSliders]);
      const findIndex = tempData.findIndex(x => x.sliderId === data.sliderId);
      tempData[findIndex].buttonText = data.buttonText;
      tempData[findIndex].link = data.link;
      tempData[findIndex].sliderId = data.sliderId;
      tempData[findIndex].title = data.title;
      tempData[findIndex].urlImage = data.urlImage;
      tempData[findIndex].sliderInfo = data.sliderInfo;
      queryClient.setQueryData([Api.BaseData.getSliders], tempData)
    },
    [],
  );
  return useMutation({
    mutationFn: (data: SliderDto) => {
      return baseDataService.editSlider(data);
    },
    onSuccess: editSlider
  })

}
