import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { FooterColumnDto } from "src/data/models/footerDtos/footerColumnDto.interface";
import { FooterLinkDto } from "src/data/models/footerDtos/footerLinkDto.interface";
import { SliderDto } from "src/data/models/sliderDtos/sliderDto.interface";
import { container } from "tsyringe";

export const useEditFooterLink = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const editFooterLink = useCallback(
    (data: FooterLinkDto, variables, context) => {
      let tempData = queryClient.getQueryData<FooterColumnDto[]>([Api.BaseData.getFooterColumn]);
      const column = tempData.findIndex(x => x.id === data.fkFooterColumnId);
      const findIndex = tempData[column].footerLinks.findIndex(x => x.id === data.id);
      tempData[column].footerLinks[findIndex].footerContent = data.footerContent;
      tempData[column].footerLinks[findIndex].footerLinkUrl = data.footerLinkUrl;
      tempData[column].footerLinks[findIndex].footerType = data.footerType;
      tempData[column].footerLinks[findIndex].id = data.id;
      tempData[column].footerLinks[findIndex].linkColumn = data.linkColumn;
      tempData[column].footerLinks[findIndex].title = data.title;
      queryClient.setQueryData([Api.BaseData.getFooterColumn], tempData)
    },
    [],
  );
  return useMutation({
    mutationFn: (data: FooterLinkDto) => {
      return baseDataService.editFooterLink(data);
    },
    onSuccess: editFooterLink
  })

}
