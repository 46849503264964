interface IApi {
  Auth: {
    login: string;
    baseInfo: string;
  };
  Indicators: {
    getIndicators: string;
    addIndicators: string;
    editIndicators: string;
    deleteIndicators: string;
  };
  Subjects: {
    getSubjects: string;
    addSubjects: string;
    editSubjects: string;
    deleteSubjects: string;
  };
  BaseData: {
    getSetting: string;
    editSetting: string;
    getAboutPublisher: string;
    editAboutPublisher: string;
    getSliders: string;
    getContactUs: string;
    getSlider: string;
    addSlider: string;
    editSlider: string;
    deleteSlider: string;
    getFooterLinks: string;
    getFooterColumn: string;
    addFooterLink: string;
    editFooterLink: string;
    editFooterColumn: string;
    deleteFooterLink: string;
    getWebHomeCollectionDataList: string;
    addWebHomeData: string;
    editWebHomeData: string;
    deleteWebHomeData: string;

    getWebHomeCollections: string;
    addWebHomeCollection: string;
    editWebHomeCollection: string;
    deleteWebHomeCollection: string;
    toggleWebHomeCollectionStatus: string;
    getEditorFiles: string;
    addEditorFiles: string;
    deleteEditorFiles: string;
  };
  Users: {
    getUsers: string;
    getAllUsers: string;
    addUsers: string;
    editUsers: string;
    toggleUserStatus: string;
    getProfile: string;
    editProfile: string;
    changePassword: string;
  };
  News: {
    getNews: string;
    addNews: string;
    editNews: string;
    deleteNews: string;
    toggleNewsStatus: string;
  };
  IndexTypes: {
    getIndexTypes: string;
    addIndexTypes: string;
    editIndexTypes: string;
    deleteIndexTypes: string;
  };
  Contents: {
    getContentList: string;
    getContentMenuList: string;
    getContentTabList: string;
    getContentTopicsList: string;
    toggleContentStatus: string;
    addContent: string;
    editContent: string;
    deleteContent: string;
    addMenuList: string;
    addTabList: string;
    addTopicList: string;
    editMenuList: string;
    editTabList: string;
    editTopicList: string;
    deleteMenuList: string;
    deleteTabList: string;
    deleteTopicList: string;
    getAllContents: string;
    getContentUserPermissions: string;
    addUserPermission: string;
    deleteUserPermission: string;
  };
  Journals: {
    getJournalIndicators: string;
    assignIndicatorToJournal: string;
    deleteJournalIndicator: string;
    editJournalIndicator: string;
    getJournalList: string;
    addJournal: string;
    editJournal: string;
    toggleJournalActivation: string;
    getUserPermissions: string;
    getJournalIndexTypes: string;
    assignIndexTypeToJournal: string;
    deleteJournalIndexType: string;
    editJournalIndexType: string;
    getUserUserPermissions: string;
    getJournalUserPermissions: string;
    addUserPermission: string;
    deleteUserPermission: string;
    GetJournalVolumes: string;
    GetJournalIssues: string;
    GetJournalAllIssues: string;
    AddVolume: string;
    EditVolume: string;
    DeleteVolume: string;
    AddIssue: string;
    EditIssue: string;
    DeleteIssue: string;
  };
  Articles: {
    getArticleTypes: string;
    addArticleType: string;
    editArticleType: string;
    deleteArticleType: string;
    deleteArticle: string;
    getArticleList: string;
    addArticle: string;
    editArticle: string;
    editArticleContent: string;
    getArticleContent: string;
    getAllArticleStatusLogs: string;
    changeArticleStatus: string;
    toggleRecommendedArticle: string;
    toggleAllowDownloadArticle: string;
    editArticleRightAndPermisson: string;

    addAuthor: string;
    editAuthor: string;
    getAuthors: string;
    getAllAuthors: string;
    deleteAuthor: string;
  };
  Messages: {
    getMessagesList: string;
    getNewMessagesList: string;
    getSentMessagesList: string;
    sendMessage: string;
    readMessage: string;
    newMessagesCount: string;
  };
}

export const Api: Readonly<IApi> = {
  Auth: {
    login: "Authenticate/Login",
    baseInfo: "BaseData/GetBaseInfo",
  },
  Indicators: {
    getIndicators: "Indicators/GetIndicators",
    addIndicators: "Indicators/AddIndicator",
    editIndicators: "Indicators/EditIndicator",
    deleteIndicators: "Indicators/{indicatorId}",
  },
  Subjects: {
    getSubjects: "Subjects/GetSubjects",
    addSubjects: "Subjects/AddSubject",
    editSubjects: "Subjects/EditSubject",
    deleteSubjects: "Subjects/{subjectId}",
  },
  BaseData: {
    getSetting: "BaseData/GetSetting",
    editSetting: "BaseData/EditSetting",
    getAboutPublisher: "BaseData/GetAboutPublisher",
    editAboutPublisher: "BaseData/UpdateAboutPublisher",
    getSliders: "BaseData/GetSliders",
    getContactUs: "BaseData/GetContactUs",
    getSlider: "BaseData/GetSlider",
    addSlider: "BaseData/AddSlider",
    editSlider: "BaseData/EditSlider",
    deleteSlider: "BaseData/DeleteSlider/{sliderId}",
    getFooterLinks: "BaseData/GetFooterLinks",
    getFooterColumn: "BaseData/GetFooterColumns",
    addFooterLink: "BaseData/AddFooterLink",
    editFooterLink: "BaseData/EditFooterLink",
    editFooterColumn: "BaseData/EditFooterColumn",
    deleteFooterLink: "BaseData/DeleteFooterLink/{id}",
    getWebHomeCollectionDataList: "BaseData/getWebHomeCollectionDataList",
    addWebHomeData: "BaseData/AddWebHomeData",
    editWebHomeData: "BaseData/EditWebHomeData",
    deleteWebHomeData: "BaseData/DeleteWebHomeData/{id}",

    getWebHomeCollections: "BaseData/getWebHomeCollections",
    addWebHomeCollection: "BaseData/addWebHomeCollection",
    editWebHomeCollection: "BaseData/editWebHomeCollection",
    deleteWebHomeCollection: "BaseData/deleteWebHomeCollection/{id}",
    toggleWebHomeCollectionStatus: "BaseData/toggleWebHomeCollectionStatus",

    getEditorFiles: "BaseData/GetEditorFiles",
    addEditorFiles: "BaseData/AddEditorFiles",
    deleteEditorFiles: "BaseData/DeleteEditorFiles/{id}",
  },
  Users: {
    getUsers: "Users/GetUsers",
    getAllUsers: "Users/GetAllUsers",
    addUsers: "Users/AddUser",
    editUsers: "Users/EditUser",
    toggleUserStatus: "Users/ToggleUserStatus",
    getProfile: "Users/GetProfile",
    editProfile: "Users/EditProfile",
    changePassword: "Users/ChangePassword",
  },
  News: {
    getNews: "News/GetNewsList",
    addNews: "News/AddNews",
    editNews: "News/EditNews",
    deleteNews: "News/{newsId}",
    toggleNewsStatus: "News/ToggleShowInHome",
  },
  IndexTypes: {
    getIndexTypes: "IndexTypes/GetIndextTypes",
    addIndexTypes: "IndexTypes/AddIndexType",
    editIndexTypes: "IndexTypes/EditIndexType",
    deleteIndexTypes: "IndexTypes/{indexTypeId}",
  },
  Contents: {
    getContentList: "Contents/GetContentList",
    getContentMenuList: "Contents/GetContentMenuList",
    getContentTabList: "Contents/GetContentTabList",
    getContentTopicsList: "Contents/GetContentTopicsList",
    toggleContentStatus: "Contents/ToggleContentActivationCommand",
    addContent: "Contents/AddContent",
    editContent: "Contents/EditContent",
    deleteContent: "Contents/DeleteContent/{id}",
    addMenuList: "Contents/AddMenuList",
    addTabList: "Contents/AddTabList",
    addTopicList: "Contents/AddTopicList",
    editMenuList: "Contents/EditMenuList",
    editTabList: "Contents/EditTabList",
    editTopicList: "Contents/EditTopicList",
    deleteMenuList: "Contents/DeleteMenuList/{id}",
    deleteTabList: "Contents/DeleteTabList/{id}",
    deleteTopicList: "Contents/DeleteTopicList/{id}",
    getAllContents: "Contents/GetAllContents",
    getContentUserPermissions: "Contents/getContentUserPermissions",
    addUserPermission: "Contents/AddUserPermission",
    deleteUserPermission: "Contents/DeleteUserPermission/{userPermissinId}",
  },
  Journals: {
    getJournalIndicators: "Journals/GetJournalIndicators",
    assignIndicatorToJournal: "Journals/AssignIndicatorToJournal",
    deleteJournalIndicator:
      "Journals/DeleteJournalIndicator/{journalIndicatorId}",
    editJournalIndicator: "Journals/EditJournalIndicator",
    getJournalList: "Journals/GetAllJournalList",
    addJournal: "Journals/AddJournal",
    editJournal: "Journals/EditJournal",
    toggleJournalActivation: "Journals/ToggleJournalActivation",
    getUserPermissions: "Journals/GetUserPermissions",
    getJournalIndexTypes: "Journals/GetJournalIndexTypes",
    assignIndexTypeToJournal: "Journals/AssignIndexTypeToJournal",
    deleteJournalIndexType: "Journals/DeleteJournalIndexType/{journalIndexId}",
    editJournalIndexType: "Journals/EditJournalIndexType",
    getUserUserPermissions: "Journals/GetUserUserPermissions",
    getJournalUserPermissions: "Journals/GetJournalUserPermissions",
    addUserPermission: "Journals/AddUserPermission",
    deleteUserPermission: "Journals/DeleteUserPermission/{userPermissinId}",

    GetJournalVolumes: "Journals/GetJournalVolumes",
    GetJournalIssues: "Journals/GetJournalIssues",
    GetJournalAllIssues: "Journals/GetJournalAllIssues",
    AddVolume: "Journals/AddVolume",
    EditVolume: "Journals/EditVolume",
    DeleteVolume: "Journals/DeleteVolume/{id}",
    AddIssue: "Journals/AddIssue",
    EditIssue: "Journals/EditIssue",
    DeleteIssue: "Journals/DeleteIssue/{id}",
  },
  Articles: {
    getArticleTypes: "Articles/GetArticleTypes",
    addArticleType: "Articles/AddArticleType",
    editArticleType: "Articles/EditArticleType",
    deleteArticleType: "Articles/DeleteArticleType/{id}",
    deleteArticle: "Articles/DeleteArticle",
    getArticleList: "Articles/GetArticleList",
    addArticle: "Articles/AddArticle",
    editArticle: "Articles/EditArticle",
    editArticleContent: "Articles/EditArticleContent",
    getArticleContent: "Articles/GetArticleContent",
    getAllArticleStatusLogs: "Articles/GetAllArticleStatusLogs",
    changeArticleStatus: "Articles/ChangeArticleStatus",
    toggleRecommendedArticle: "Articles/EditArticleRecommended",
    toggleAllowDownloadArticle: "Articles/EditArticleAllowDownload",
    editArticleRightAndPermisson: "Articles/EditArticleRightAndPermisson",
    getAuthors: "Articles/GetAuthors",
    getAllAuthors: "Articles/GetAllAuthors",
    addAuthor: "Articles/AddAuthor",
    editAuthor: "Articles/EditAuthor",
    deleteAuthor: "Articles/DeleteAuthor/{id}",
  },
  Messages: {
    getMessagesList: "Messages/GetMessagesList",
    getSentMessagesList: "Messages/GetSentMessagesList",
    getNewMessagesList: "Messages/GetNewMessagesList",
    sendMessage: "Messages/SendMessage",
    readMessage: "Messages/ReadMessage",
    newMessagesCount: "Messages/GetNewMessagesCount",
  },
};
