import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { JournalIndicatorListDto } from "src/data/models/indicatorDtos/journalIndicatorListDto.interface";

export const useAssignIndicatorToJournal = (id) => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();

    const toggleAdd = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<JournalIndicatorListDto[]>([Api.Journals.getJournalIndicators,id]);
            const finalData = [...tempData] ;
            finalData.push(data);
            queryClient.setQueryData([Api.Journals.getJournalIndicators,id],finalData )
        },
        [id],
    );

    return useMutation({
        mutationFn: (data: JournalIndicatorListDto) => {
            return journalsService.assignIndicatorToJournal(data);
        },
        onSuccess:toggleAdd
    })

}
