import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { ArticlesService } from "src/apis/services/article/articlesService";

export const useGetAllAuthors = () => {
    const articlesService = container.resolve(ArticlesService);
    return useQuery({
        queryKey: [Api.Articles.getAllAuthors],
        queryFn: () => {

            return articlesService.getAllAuthors();
        }
    })
}
