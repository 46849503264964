import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { AddArticleStatusLogDto } from "src/data/models/articleDtos/addArticleStatusLogDto.interface";
import { ArticleListDto } from "src/data/models/articleDtos/articleListDto.interface";
import { ArticleStatusLogDto } from "src/data/models/articleDtos/articleStatusLogDto.interface";
import { ArticlesTypeDto } from "src/data/models/articleDtos/articlesTypeDto.interface";
import { AuthorDto } from "src/data/models/articleDtos/authorDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { AuthorsPaginationParams } from "src/data/models/params/authorsPaginationParams.interface";
import { PaginationParamsWithFilter } from "src/data/models/params/paginationParamsWithFilter.interface";
import BaseRepository from "../Base/BaseRepository";
import IArticlesRepository from "./IArticlesRepository";
import { ArticleContentDto } from "src/data/models/articleDtos/articleContentDto.interface";

export default class ArticlesRepository
  extends BaseRepository
  implements IArticlesRepository
{
  async getArticleTypes(): Promise<IGenericResponse<ArticlesTypeDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .setUrl(Api.Articles.getArticleTypes);

    return await request.GetResultAsync();
  }

  async getJournalArticleTypes(journalId): Promise<IGenericResponse<ArticlesTypeDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .addQueryParam("JournalId" , journalId)
      .setUrl(Api.Articles.getArticleTypes);

    return await request.GetResultAsync();
  }

  async toggleRecommendedArticle(id): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(id)
      .setUrl(Api.Articles.toggleRecommendedArticle)

    return await request
      .GetResultAsync() ;
  }

  async toggleAllowDownloadArticle(id): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(id)
      .setUrl(Api.Articles.toggleAllowDownloadArticle)

    return await request
      .GetResultAsync() ;
  }


  async changeRightAndPermissonArticle(articleId,rightsAndPermissions): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody({articleId , rightAndPermisson:rightsAndPermissions})
      .setUrl(Api.Articles.editArticleRightAndPermisson)

    return await request
      .GetResultAsync() ;
  }


  async addArticleType(
    body: ArticlesTypeDto
  ): Promise<IGenericResponse<ArticlesTypeDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Articles.addArticleType);

    return await request.GetResultAsync();
  }

  async editArticleType(
    body: ArticlesTypeDto
  ): Promise<IGenericResponse<ArticlesTypeDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Articles.editArticleType);

    return await request.GetResultAsync();
  }
  async deleteArticleType(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setUrl(Api.Articles.deleteArticleType, { id });

    return await request.GetResultAsync();
  }


  async deleteArticle(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setBody({ArticleId:id})
      .setUrl(Api.Articles.deleteArticle);

    return await request.GetResultAsync();
  }

  async getArticleList(
    params: PaginationParamsWithFilter
  ): Promise<IGenericResponse<PaginatedList<ArticleListDto>>> {
    const result = this.CreateRequestBuilder();

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value);
    }

    const request = result.setMethod("get").setUrl(Api.Articles.getArticleList);

    return await request.GetResultAsync();
  }


  
  async addArticle(
    body: ArticleListDto
  ): Promise<IGenericResponse<ArticleListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Articles.addArticle);

    return await request.GetResultAsync();
  }

  async editArticle(
    body: ArticleListDto
  ): Promise<IGenericResponse<ArticleListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Articles.editArticle);

    return await request.GetResultAsync();
  }

  async changeArticleStatus(
    body: AddArticleStatusLogDto
  ): Promise<IGenericResponse<ArticleListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Articles.changeArticleStatus);

    return await request.GetResultAsync();
  }

  async getAllArticleStatusLogs(
    articleId: number
  ): Promise<IGenericResponse<ArticleStatusLogDto[]>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .addQueryParam("ArticleId" , articleId)
      .setUrl(Api.Articles.getAllArticleStatusLogs);

    return await request.GetResultAsync();
  }


  
  async getAuthors(
    params: AuthorsPaginationParams
  ): Promise<IGenericResponse<PaginatedList<AuthorDto>>> {
    const result = this.CreateRequestBuilder();

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value);
    }

    const request = result.setMethod("get").setUrl(Api.Articles.getAuthors);

    return await request.GetResultAsync();
  }
  
  
  async getAllAuthors(
  ): Promise<IGenericResponse<AuthorDto[]>> {
    const result = this.CreateRequestBuilder();


    const request = result.setMethod("get").setUrl(Api.Articles.getAllAuthors);

    return await request.GetResultAsync();
  }
  
  async addAuthor(
    body: AuthorDto
  ): Promise<IGenericResponse<AuthorDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("post")
      .setBody(body)
      .setUrl(Api.Articles.addAuthor);

    return await request.GetResultAsync();
  }

  async editAuthor(
    body: AuthorDto
  ): Promise<IGenericResponse<AuthorDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Articles.editAuthor);

    return await request.GetResultAsync();
  }
  async deleteAuthor(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod("delete")
      .setUrl(Api.Articles.deleteAuthor, { id });

    return await request.GetResultAsync();
  }

  async editArticleContent(
    body: ArticleContentDto
  ): Promise<IGenericResponse<boolean>> {
    const request = this.CreateRequestBuilder()
      .setMethod("put")
      .setBody(body)
      .setUrl(Api.Articles.editArticleContent);

    return await request.GetResultAsync();
  }

  async getArticleContent(articleId): Promise<IGenericResponse<string>> {
    const request = this.CreateRequestBuilder()
      .setMethod("get")
      .addQueryParam("articleId" , articleId)
      .setUrl(Api.Articles.getArticleContent);

    return await request.GetResultAsync();
  }

}
