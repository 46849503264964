import { useMutation, useQueryClient } from "react-query";
import { container } from "tsyringe";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { useCallback } from "react";
import { Api } from "src/apis/helpers/configs/api";

export const useEditArticleContent = (articleId)  => {
    const articlesService = container.resolve(ArticlesService);
    const queryClient = useQueryClient();
    const editContent = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData([Api.Articles.getArticleContent,articleId]);
            tempData = variables.articleContent;
            queryClient.setQueryData([Api.Articles.getArticleContent,articleId],tempData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: any) => {
            return articlesService.editArticleContent(data);
        },
        onSuccess: editContent

    })

}
