import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
import { useAuth } from "../../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const TabPane = Tabs.TabPane;

const ArticleListPage = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [articleEditData, setArticleEditData] = useState(null);
  const [articleEditFlag, setArticleEditFlag] = useState(false);
  const [searchData, setSearchData] = useState({
    page: 1,
    status: "",
    filter: "",
    authorEmail: "",
    isDeleted: false,
    issueId: "",
    volumeId: "",
    journalId: "",
  });
  const { authUser, isLoadingUser } = useAuth();
  const callback = (key, data = null, searchData) => {
    setActiveTab(key);
    if (data && key === "5") {
      setArticleEditData(data);
      setArticleEditFlag(true);
      setSearchData(searchData);
    } else if (key === "5") {
      setArticleEditFlag(false);
      setArticleEditData(null);
    }
  };
  return (
    <Card className="gx-card" title="Article Section">
      {authUser?.userInfo.role === UserGroupEnum.Assistant ? (
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="List of articles" key="1">
            <List
              changeTabEvent={callback}
              activeTab={activeTab}
              userRole={authUser?.userInfo.role}
            />
          </TabPane>

          <TabPane tab="Add new articles" key="5">
            <Add
              fields={articleEditData}
              editSection={articleEditFlag}
              changeTabEvent={callback}
              searchData={searchData}
            />
          </TabPane>
        </Tabs>
      ) : (
        <List activeTab={activeTab} userRole={authUser?.userInfo.role} />
      )}
    </Card>
  );
};

export default ArticleListPage;
