import React from "react";
import {Route, Switch} from "react-router-dom";
import Volumes from "./volume";
import ProtectedRoute from "../../components/ProtectedRoute";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";

const VolumeAndIssue = ({match}) => (
  <Switch>
    <ProtectedRoute  roles={[UserGroupEnum.Assistant]} path={`${match.url}/volumes`} component={Volumes}/>
  </Switch>
);

export default VolumeAndIssue;
