import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { MessagesService } from "src/apis/services/messages/messagesService";
import { MessagesDto } from "src/data/models/messagesDtos/MessagesDto.interface";

export const useSendMessage = () => {
    const messagesService = container.resolve(MessagesService);
    const queryClient = useQueryClient();

    const toggleSendMessage = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<MessagesDto>>([Api.Messages.getSentMessagesList , 1]);
            if(tempData) {
            let lastData = queryClient.getQueryData<PaginatedList<MessagesDto>>([Api.Messages.getSentMessagesList , tempData.totalPages]);
            const finalData = [...lastData.items] ;
            finalData.push(data);
            lastData.items = finalData ;
            queryClient.setQueryData([Api.Messages.getSentMessagesList,tempData.totalPages],lastData )
           }

        },
        [],
    );
    return useMutation({
        mutationFn: (data: MessagesDto) => {
            return messagesService.sendMessage(data);
        },
        onSuccess: toggleSendMessage
    })

}
