import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { ContentsService } from "../../../../apis/services/contents/contentsService";
import { TopicsListDto } from "../../../../data/models/contentsDtos/topicsListDto.interface";

import { container } from "tsyringe";

export const useEditTopic = (id,isForContent) => {
    const contentsService = container.resolve(ContentsService);
    const queryClient = useQueryClient();
    const toggleEditTopic = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<TopicsListDto[]>([Api.Contents.getContentTopicsList,id,isForContent]);
            const newData = [...tempData] ;
            const findIndex = newData.findIndex(x=>x.id === data.id) ;
            newData[findIndex] = data;
            queryClient.setQueryData([Api.Contents.getContentTopicsList,id,isForContent],newData )
        },
        [id,isForContent],
    );
    return useMutation({
        mutationFn: (data: TopicsListDto) => {
            return contentsService.editTopicList(data);
        },
        onSuccess: toggleEditTopic
    })

}
