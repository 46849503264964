import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { MessagesService } from "src/apis/services/messages/messagesService";

export const useGetNewMessagesCount = () => {
    const messagesService = container.resolve(MessagesService);
    return useQuery({
        queryKey: [Api.Messages.newMessagesCount],
        queryFn: () => {
            return messagesService.getNewMessagesCount();
        }
    })
}
