import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { ContentsService } from "../../../../apis/services/contents/contentsService";
import { MenuListDto } from "../../../../data/models/contentsDtos/menuListDto.interface";
import { container } from "tsyringe";

export const useAddMenu = (id,isForContent,type=0) => {
    const contentsService = container.resolve(ContentsService);
    const queryClient = useQueryClient();

    const toggleAddMenu = useCallback(
        (data, variables, context) => {
            queryClient.removeQueries([Api.Contents.getContentMenuList,id,isForContent,type]);
        },
        [isForContent,type],
    );

    return useMutation({
        mutationFn: (data: MenuListDto) => {
            return contentsService.addMenuList(data);
        },
        onSuccess:toggleAddMenu
    })

}
