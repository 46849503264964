import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { TokenRequestDto } from "src/data/models/login/tokenRequestModel.interface";
import { TokenResponse, UserInfo } from "src/data/models/login/tokenResponse.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class AuthService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      'login',
      'getBaseInfo'

    ])
    this.setMiddlware(this.handleToken, [
      'login',
      'getBaseInfo'
    ])
  }

  async login(body: TokenRequestDto): Promise<IGenericResponse<TokenResponse>> {
    const result = await this.repositoryWrapper.Authenticate.login(body);

    return result;
  }

  async getBaseInfo(): Promise<IGenericResponse<UserInfo>> {
    const result = await this.repositoryWrapper.Authenticate.getBaseInfo();
    return result;
  }

}
