import { dehydrate, QueryClient } from "react-query";
import { PaginatedList } from "../../../data/models/paginatedList.interface";

export const reactQueryConfig = {
  defaultOptions: {
    queries: {
      retryOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  },
};

export const dehydrateData = (queryClient: QueryClient) => {
  const _dehydrate = dehydrate(queryClient);
  return {
    dehydratedState: _dehydrate
  }
}

export const getNextPage = (res: PaginatedList<any>): number | boolean => {
  const currentPage = res.pageNumber || 0;
  const totalPages = res.totalPages;
  if (totalPages > currentPage) return currentPage + 1;
  return false;
}
