import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { webHomeDataCollectionDto } from "src/data/models/webHomeDataDtos/webHomeDataCollectionDto.interface";

export const useEditWebHomeCollection = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const editData = useCallback(
    (data: webHomeDataCollectionDto, variables, context) => {
      let tempData = queryClient.getQueryData<webHomeDataCollectionDto[]>([Api.BaseData.getWebHomeCollections]);
      const newData = [...tempData];
      const findIndex = newData.findIndex(x => x.webHomeDataCollectionId === data.webHomeDataCollectionId);
      newData[findIndex] = data;
      tempData = newData;
      queryClient.setQueryData([Api.BaseData.getWebHomeCollections], tempData)
    },
    [],
  );
  return useMutation({
    mutationFn: (data: webHomeDataCollectionDto) => {
      return baseDataService.editWebHomeCollection(data);
    },
    onSuccess: editData
  })

}
