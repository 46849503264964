import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { ArticlesService } from "src/apis/services/article/articlesService";
import { ArticlesTypeDto } from "src/data/models/articleDtos/articlesTypeDto.interface";
export const useDeleteArticleType = () => {
    const articlesService = container.resolve(ArticlesService);
    

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (targetId) => {
            let tempObject = queryClient.getQueryData<ArticlesTypeDto[]>([Api.Articles.getArticleTypes])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.id !== targetId)
            queryClient.setQueryData([Api.Articles.getArticleTypes], finalData)
        },
        [],
    )

    return useMutation({
        mutationFn: (id: number) => {
            return articlesService.deleteArticleType(id);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}