import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { VolumeDto } from "src/data/models/journalDtos/volumeDto.interface";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";
export const useDeleteVolumes = (currentPage,journalId) => {
    const journalsService = container.resolve(JournalsService);

    const queryClient = useQueryClient();

    const toggleDelete = useCallback(
        (targetVolumeId) => {
            let lastData = queryClient.getQueryData<PaginatedList<VolumeWithIssuesDto>>([Api.Journals.GetJournalIssues , currentPage,journalId]);

            if (lastData === undefined) return

            lastData.items = lastData.items.filter(item => item.vid !== targetVolumeId);
             
            queryClient.setQueryData([Api.Journals.GetJournalIssues,currentPage,journalId], lastData);

            let allData = queryClient.getQueryData<VolumeWithIssuesDto[]>([
                Api.Journals.GetJournalAllIssues,
                journalId,
              ]);
              
        
            if (allData) {
                allData = allData.filter(item => item.vid !== targetVolumeId);
              queryClient.setQueryData(
                [Api.Journals.GetJournalAllIssues, journalId],
                allData
              );
            }

        },
        [currentPage,journalId],
    )

    return useMutation({
        mutationFn: (id: number) => {
            return journalsService.deleteVolume(id);
        },
        onSuccess: (data, targetId, context) => {
            toggleDelete(targetId);
        }
    })
}