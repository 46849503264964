import {Card} from "antd";
import Add from './add/Add';


const AboutPublisherPage = () => {
 
  return (
    <Card className="gx-card" title="About Publisher">
        <Add />
    </Card>
  );
};

export default AboutPublisherPage;
