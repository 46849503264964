import React, { useState } from "react";
import { Tabs ,Card} from "antd";
import TopicList from "./TopicList";
import TopicAdd from "./TopicAdd";
const TabPane = Tabs.TabPane;

const JournalsTopicPage = (props) => {
  const [activeTopic, setActiveTopic] = useState("1");
  const [topicEditData, setTopicEditData] = useState(null);
  const [topicEditFlag, setTopicEditFlag] = useState(false);
  const callback = (key, data = null) => {
    setActiveTopic(key);
    if (data && key === "2") {
      setTopicEditData(data);
      setTopicEditFlag(true);
    } else if (key === "2") {
      setTopicEditFlag(false);
      setTopicEditData(null);
    }
  };

  return (
    <Card className="gx-card" title="Journal List Topics">

    <Tabs activeKey={activeTopic} onChange={callback}>
      <TabPane tab="List" key="1">
        <TopicList changeTopicEvent={callback}  />
      </TabPane>
      <TabPane tab="Add / Edit" key="2">
        <TopicAdd
          fields={topicEditData}
          editSection={topicEditFlag}
          changeTopicEvent={callback}
        />
      </TabPane>
    </Tabs>
    </Card>

  );
};

export default JournalsTopicPage;
