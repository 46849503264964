import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Upload,
  Select,
  InputNumber,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import Editor from "src/components/Editor";
import { environment } from "src/environment/environment";
import { useAddContents } from "src/hooks/services/contents/useAddContents";
import { useEditContents } from "src/hooks/services/contents/useEditContents";
const { TextArea } = Input;
const Add = ({
  fields,
  editSection = false,
  changeTabEvent,
  currentPage,
  contentType,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddContents, isLoading: loadingSave } = useAddContents(
    contentType.id
  );
  const { mutate: onEditContents, isLoading: loadingEdit } = useEditContents(
    currentPage,
    contentType.id
  );
  const [formData, setFormData] = useState([]);
  const [contentDescription, setContentDescription] = useState("");
  const [contentDescriptionDefault, setContentDescriptionDefault] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);
  useEffect(() => {
    if (fields) {
      const finalData = [];
      for (var key in fields) {
        if (fields.hasOwnProperty(key)) {
          let data = {
            name: [],
            value: "",
          };
          data.name.push(key);
          if (key === "contentKeywords" && fields[key]) {
            data.value = fields[key].map((x) => x.keywords);
          } else {
            data.value = fields[key];
          }
          finalData.push(data);
        }
      }
      setFormData(finalData);
      setContentDescription(fields.description);
      setContentDescriptionDefault(fields.description);
      var imageFile = [
        {
          uid: "-1",
          name: "image",
          status: "done",
          url: fields.imageUrl,
        },
      ];
      setImageList(imageFile);
    }
    return () => {
      form.resetFields();
      setFormData([]);
      setContentDescription("");
      setContentDescriptionDefault("");
      setImageFile(null);
      setImageList([]);
    };
  }, [fields, editSection, form]);

  const saveContents = (values) => {
    if (!contentDescription) {
      return;
    }
    if (imageFile === null && !editSection) {
      return;
    }
    values.description = contentDescription;
    if (values.contentKeywords) {
      var allKeywords = [];
      values.contentKeywords.forEach((element) => {
        var keywords = {
          contentKeywordId: 0,
          keywords: element,
        };
        allKeywords.push(keywords);
      });
    }

    values.contentKeywords = allKeywords;

    const formData = new FormData();
    values.contentTypeId = contentType.id;
    formData.append("Image", imageFile);
    if (editSection) {
      values.id = fields.id;
      formData.append("Content", JSON.stringify(values));
      onEditContents(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      formData.append("Content", JSON.stringify(values));

      onAddContents(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setContentDescription("");
          setContentDescriptionDefault("");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var imageFile = [
        {
          uid: "-2",
          name: "image",
          status: "done",
          url: reader.result,
        },
      ];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveContents"
        onFinish={saveContents}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input Title!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="subTitile"
          label="Sub Titile"
          rules={[
            {
              required: true,
              message: "Please input sub titile!",
            },
          ]}
        >
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={150} />
        </Form.Item>

        <Form.Item label="Description" required>
          <Editor
            value={contentDescriptionDefault}
            onChange={(data, editor) => {
              setContentDescription(data);
            }}
          />
        </Form.Item>

        <Form.Item label="Keywords" name="contentKeywords">
          <Select mode="tags" style={{ width: "100%" }} open={false}></Select>
        </Form.Item>
        <Form.Item
          name="priority"
          label="Priority"
          rules={[
            {
              required: true,
              message: "Please input Priority!",
            },
          ]}
        >
          <InputNumber />
        </Form.Item>
        <Form.Item label="Image" required>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={imageList}
            accept=".png, .jpg, .jpeg"
            showUploadList={{ showPreviewIcon: false }}
          >
            {imageList.length === 1 ? null : uploadButton}
          </Upload>
        </Form.Item>

        <Form.Item name="active" valuePropName="checked">
          <Checkbox>active</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
