import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { ContentsService } from "src/apis/services/contents/contentsService";
import { ContentListDto } from "src/data/models/contentsDtos/contentListDto.interface";
export const useDeleteContents = (currentPage , contentType) => {
    const contentsService = container.resolve(ContentsService);
    

    const queryClient = useQueryClient();

    const toggleDeleteContents = useCallback(
        (targetContentsId) => {
            let tempData = queryClient.getQueryData<PaginatedList<ContentListDto>>([Api.Contents.getContentList , 1,contentType]);
            for (let index = 1; index <= tempData?.totalPages; index++) {
                queryClient.removeQueries([Api.Contents.getContentList , index,contentType]);
                
            }
            queryClient.removeQueries([Api.Contents.getAllContents,contentType]);

        },
        [contentType],
    )

    return useMutation({
        mutationFn: (contentsId: number) => {
            return contentsService.deleteContents(contentsId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDeleteContents(targetId);
        }
    })
}