import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndicatorsService } from "src/apis/services/indicators/indicatorsService";
import { container } from "tsyringe";

export const useGetIndicators = () => {
    const indicatorsService = container.resolve(IndicatorsService);

    return useQuery({
        queryKey: [Api.Indicators.getIndicators],
        queryFn: () => {
            return indicatorsService.getIndicators();
        }
    })
}
