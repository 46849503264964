import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { ContentsService } from "../../../../apis/services/contents/contentsService";
import { TabsListDto } from "../../../../data/models/contentsDtos/tabsListDto.interface";

import { container } from "tsyringe";

export const useEditTab = (id,isForContent) => {
    const contentsService = container.resolve(ContentsService);
    const queryClient = useQueryClient();
    const toggleEditTab = useCallback(
        (data, variables, context) => {
            queryClient.removeQueries([Api.Contents.getContentTabList,id,isForContent]);
        },
        [id,isForContent],
    );
    return useMutation({
        mutationFn: (data: TabsListDto) => {
            return contentsService.editTabList(data);
        },
        onSuccess: toggleEditTab
    })

}
