import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";

import { container } from "tsyringe";
import { useCallback } from "react"
import { SubjectsService } from "src/apis/services/subjects/subjectsService";
import { SubjectDto } from "src/data/models/subjectDtos/subjectDto.interface";
export const useDeleteSubjects = () => {
    const subjectsService = container.resolve(SubjectsService);
    

    const queryClient = useQueryClient();

    const toggleDeleteSubject = useCallback(
        (targetSubjectId) => {
            let tempObject = queryClient.getQueryData<SubjectDto[]>([Api.Subjects.getSubjects])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.id !== targetSubjectId)
            queryClient.setQueryData([Api.Subjects.getSubjects], finalData)
        },
        [],
    )

    return useMutation({
        mutationFn: (indicatorId: number) => {
            return subjectsService.deleteSubjects(indicatorId);
        },
        onSuccess: (data, targetSubjectId, context) => {
            toggleDeleteSubject(targetSubjectId);
        }
    })
}