import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { ArticlesService } from "src/apis/services/article/articlesService";
import { container } from "tsyringe";

export const useGetJournalArticleType = (journalId) => {
    const articlesService = container.resolve(ArticlesService);

    return useQuery({
        queryKey: [Api.Articles.getArticleTypes,journalId],
        queryFn: () => {
            return articlesService.getJournalArticleTypes(journalId);
        }
    })
}
