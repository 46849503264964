import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { ContentsService } from "src/apis/services/contents/contentsService";

export const useGetContents = (page = 1 , contentType) => {
    const contentsService = container.resolve(ContentsService);
    return useQuery({
        queryKey: [Api.Contents.getContentList,page,contentType],
        queryFn: () => {

            if (typeof page !== 'number') {
                page = 1;
            }

            return contentsService.getContentList({
                pageNumber: page,
                pageSize: PageSize.tables,
                contentType: contentType
            });
        }
    })
}
