import React, { useState } from "react";
import { Switch, Table, Modal, Space,Avatar, Image  } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetIndexTypes } from "src/hooks/services/indexTypes/useGetIndexTypes";
import { useDeleteIndexTypes } from "src/hooks/services/indexTypes/useDeleteIndexTypes";

const confirm = Modal.confirm;
const { Column } = Table;
const List = (props) => {
  const { data: indexTypesData, isLoading } = useGetIndexTypes();
  const { mutate: onDeleteIndexTypes } = useDeleteIndexTypes();

  const editIndexTypes = (data) => {
    props.changeTabEvent("2", data); // raftan be tab edit
  };


  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure delete this index Types?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteIndexTypes(data.id, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err:any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={indexTypesData}
        rowKey="id"
      >
        <Column
          title="Image"
          dataIndex="indexTypesImageUrl"
          key="indexTypesImageUrl"
          render={(text, record: any) => (
            <Avatar shape="square" size={80} src={<Image src={record.imageUrl}  />} />
          )}
        />
        <Column title="Title" dataIndex="title" key="title" />
        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editIndexTypes(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
       {/* <NotificationContainer /> */}
    </>
  );
};

export default List;
