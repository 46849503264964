import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuth } from "../../authentication";

function ProtectedRoute({ component: Component, ...restOfProps  }) {
    const { authUser, isLoadingUser } = useAuth();
    const {roles} = restOfProps ;

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        roles.includes(authUser?.userInfo.role) ? <Component {...props} /> : <Redirect to="/main/dashboard" />
      }
    />
  );
}

export default ProtectedRoute;