import React from "react";
import { Avatar, Popover } from "antd";
import { useAuth } from "../../authentication";
import { useHistory } from "react-router-dom";
import {Link} from "react-router-dom";
import { setInitUrl } from "../../appRedux/actions";
import { useDispatch } from "react-redux";

const UserInfo = () => {
  const { userSignOut } = useAuth();
  const history = useHistory();
  const dispatch = useDispatch();

  const onLogoutClick = () => {
    dispatch(setInitUrl('/'));
    userSignOut(() => {
      history.push('/');
    });
  }

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <Link to="/profile/edit">
        <li>My Account</li>
      </Link>
      <Link to="/profile/changepassword">
        <li>Change Password</li>
      </Link>
      <li onClick={onLogoutClick}>Logout
      </li>
    </ul>
  );

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} trigger="click">
      <Avatar src="/assets/images/user-icon.png" className="gx-avatar gx-pointer" alt="" />
    </Popover>
  );
};

export default UserInfo;
