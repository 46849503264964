import React, { useState } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
import { useGetJournals } from "src/hooks/services/journals/useGetJournals";
const TabPane = Tabs.TabPane;

const VolumePage = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [volumeEditData, setVolumeEditData] = useState(null);
  const [volumeEditFlag, setVolumeEditFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const { data: journalsData, isLoading: isLoadingJournal } = useGetJournals();
  const callback = (key, data = null, currentPage = 1) => {
    setActiveTab(key);
    if (data && key === "2") {
      setVolumeEditData(data);
      setVolumeEditFlag(true);
      setCurrentPage(currentPage);
    } else if (key === "2") {
      setVolumeEditFlag(false);
      setVolumeEditData(null);
    }
  };

  return (
    <Card className="gx-card" title="Volume">
      {!isLoadingJournal ? (
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="List" key="1">
            <List journals={journalsData} changeTabEvent={callback} />
          </TabPane>
          <TabPane tab="Add / Edit Volume" key="2">
            <Add
              fields={volumeEditData}
              editSection={volumeEditFlag}
              changeTabEvent={callback}
              currentPage={currentPage}
              journals={journalsData}
            />
          </TabPane>
        </Tabs>
      ) : null}
    </Card>
  );
};

export default VolumePage;
