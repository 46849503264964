import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { VolumeDto } from "src/data/models/journalDtos/volumeDto.interface";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";

export const useEditVolumes = (currentPage) => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();
    const toggleEdit = useCallback(
        (data:VolumeWithIssuesDto, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<VolumeWithIssuesDto>>([Api.Journals.GetJournalIssues,currentPage,data.journalId]);
            for (let index = 1; index <= tempData?.totalPages; index++) {
              queryClient.removeQueries([Api.Journals.GetJournalIssues , index,data.journalId]);
            }
            
            let allData = queryClient.getQueryData<VolumeWithIssuesDto[]>([
                Api.Journals.GetJournalAllIssues,
                data.journalId,
              ]);
              
        
            if (allData) {
            const volumeData2 = [...allData] ;
            const findIndex2 = volumeData2.findIndex(x=>x.vid === data.vid) ;
            volumeData2[findIndex2] = data;
            allData = volumeData2;
              queryClient.setQueryData(
                [Api.Journals.GetJournalAllIssues, data.journalId],
                allData
              );
            }
        },
        [currentPage],
    );
    return useMutation({
        mutationFn: (data: VolumeDto) => {
            return journalsService.editVolume(data);
        },
        onSuccess: toggleEdit
    })

}
