import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, Card } from "antd";
import { ToastContainer, toast } from "react-toastify";
import IntlMessages from "../../../../util/IntlMessages";
import { useChangeArticleStatus } from "src/hooks/services/articles/useChangeArticleStatus";
import { ArticleStatusEnum } from "src/data/enums/articleStatusEnum";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
import Editor from "src/components/Editor";
const { TextArea } = Input;
const { Option } = Select;

const ChangeArticleStatus = (props) => {
  const [form] = Form.useForm();
  const [comment, setComment] = useState("");
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onChangeArticleStatus, isLoading: loadingSave } =
    useChangeArticleStatus();

  useEffect(() => {
    form.resetFields();
  }, [form]);

  const editStatus = (values) => {
    values.articleId = props.data.articleId;
    values.comment = comment;
    onChangeArticleStatus(values, {
      onSuccess: (data) => {
        toast.success(<IntlMessages id="notification.successMessage" />);
        props.closeModal();
        props.sucessSubmit();
      },
      onError: (err) => {
        toast.error(<IntlMessages id="notification.errorMessage" />);
      },
    });
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        name="changeStatus"
        onFinish={editStatus}
        autoComplete="off"
      >
        <Form.Item
          name="status"
          label="Status"
          rules={[
            {
              required: true,
              message: "Please Select status!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select status"
            optionFilterProp="children"
          >
            {props.userRole === UserGroupEnum.EditorInChief ? (
              <>
                <Option
                  key={ArticleStatusEnum.Accepted}
                  value={ArticleStatusEnum.Accepted}
                >
                  Assign to volume
                </Option>
                <Option
                  key={ArticleStatusEnum.Revise}
                  value={ArticleStatusEnum.Revise}
                >
                  Needs to be revised
                </Option>
                <Option
                  key={ArticleStatusEnum.Rejected}
                  value={ArticleStatusEnum.Rejected}
                >
                  Rejected
                </Option>
              </>
            ) : (
              <>
                <Option
                  key={ArticleStatusEnum.NewArticle}
                  value={ArticleStatusEnum.NewArticle}
                >
                  Send to Editor in chief
                </Option>
                <Option
                  key={ArticleStatusEnum.ReviseAuthorArticle}
                  value={ArticleStatusEnum.ReviseAuthorArticle}
                >
                  Returned to the author to make changes
                </Option>
              </>
            )}
          </Select>
        </Form.Item>
        <Form.Item label="Comment">
          <Editor
            value=''
            onChange={(data, editor) => {
              setComment(data);
            }}
          />
        </Form.Item>

        <Form.Item>
          <Button loading={loadingSave} type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
      {/* <NotificationContainer /> */}
    </>
  );
};

export default ChangeArticleStatus;
