import { useQuery } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { container } from "tsyringe";
import { ContentsService } from "src/apis/services/contents/contentsService";

export const useGetConferenceUserPermissions = (contentId) => {
    const contentsService = container.resolve(ContentsService);
    return useQuery({
        queryKey: [Api.Contents.getContentUserPermissions,contentId],
        queryFn: () => {
            return contentsService.getContentUserPermissions(contentId);
        }
    })
}
