import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { container } from "tsyringe";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { AddJouranlIndexTypeDto } from "src/data/models/indexTypesDtos/addJouranlIndexTypeDto.interface";
import { JournalIndexTypeListDto } from "src/data/models/indexTypesDtos/journalIndexTypeListDto.interface";

export const useAssignIndexTypeToJournal = (id) => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();

    const toggleAdd = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<JournalIndexTypeListDto[]>([Api.Journals.getJournalIndexTypes,id]);
            const finalData = [...tempData] ;
            finalData.push(data);
            queryClient.setQueryData([Api.Journals.getJournalIndexTypes,id],finalData )
        },
        [id],
    );

    return useMutation({
        mutationFn: (data: AddJouranlIndexTypeDto) => {
            return journalsService.assignIndexTypeToJournal(data);
        },
        onSuccess:toggleAdd
    })

}
