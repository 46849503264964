import RequestBuilder from './RequestBuilder';
import IBaseRepository from './IBaseRepository';

export default class BaseRepository implements IBaseRepository {
    private baseUrl = '';
    private defualtHeader = <object>{};
    private controller = '';

    constructor(baseUrl: string, defualtHeader?: object, controller?: string) {
        this.baseUrl = baseUrl;

        if (defualtHeader) {
            this.defualtHeader = defualtHeader;
        }

        if (controller) {
            this.controller = controller;
        }
    }

    protected CreateRequestBuilder(): RequestBuilder {

      return new RequestBuilder()
            .setBaseUrl(this.baseUrl)
            .SetHeader(this.defualtHeader);
    }

}
