import { useCallback } from "react"
import { useQueryClient } from "react-query"

export const useBaseQuery = () => {


    const queryClient = useQueryClient()

    const resetQuery = useCallback(
        (key, exact = false) => {
            queryClient.resetQueries({
                queryKey: key,
                exact: exact
            })

        },
        [queryClient],
    )

    const removeQuery = useCallback(
        (key, exact = false) => {
            queryClient.removeQueries({
                queryKey: key,
                exact: exact
            })

        },
        [queryClient],
    )

    const clearQueries = useCallback(
        () => {
            queryClient.clear()
        },
        [queryClient],
    )

    const resetToInitData = useCallback(
        (key, init = undefined) => {
            queryClient.setQueryData(key, init)
        },
        [queryClient],
    )

    return {
        resetQuery,
        removeQuery,
        clearQueries,
        resetToInitData
    }

}