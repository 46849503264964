import { useMutation, useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { JournalsService } from "src/apis/services/journals/journalsService";

export const useGetIssues = (page = 1 , journalId) => {
    const journalsService = container.resolve(JournalsService);

    return useQuery({
        queryKey: [Api.Journals.GetJournalIssues,page,journalId],
        queryFn: () => {

            if (typeof page !== 'number') {
                page = 1;
            }

            return journalsService.getJournalIssues({
                pageNumber: page,
                pageSize: PageSize.tables,
                journalId:journalId
            });
        }
    })
}
