import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { MessagesDto } from "src/data/models/messagesDtos/MessagesDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import BaseRepository from "../Base/BaseRepository";
import IMessagesRepository from "./IMessagesRepository";

export default class MessagesRepository extends BaseRepository implements IMessagesRepository {

  async getMessagesList(params: PaginationParams): Promise<IGenericResponse<PaginatedList<MessagesDto>>> {

    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value)
    }

    const request = result
      .setMethod('get')
      .setUrl(Api.Messages.getMessagesList)

    return await request
      .GetResultAsync();
  }


  async getSentMessagesList(params: PaginationParams): Promise<IGenericResponse<PaginatedList<MessagesDto>>> {

    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value)
    }

    const request = result
      .setMethod('get')
      .setUrl(Api.Messages.getSentMessagesList)

    return await request
      .GetResultAsync();
  }

  async getNewMessagesList(): Promise<IGenericResponse<MessagesDto[]>> {

    const result = this.CreateRequestBuilder()

    const request = result
      .setMethod('get')
      .setUrl(Api.Messages.getNewMessagesList)

    return await request
      .GetResultAsync();
  }


  async getNewMessagesCount(): Promise<IGenericResponse<number>> {

    const result = this.CreateRequestBuilder()

    const request = result
      .setMethod('get')
      .setUrl(Api.Messages.newMessagesCount)

    return await request
      .GetResultAsync();
  }
  
  async sendMessage(body: MessagesDto): Promise<IGenericResponse<MessagesDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Messages.sendMessage)

    return await request
      .GetResultAsync()
  }

  async readMessage(messageId): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(messageId)
      .setUrl(Api.Messages.readMessage)

    return await request
      .GetResultAsync();
  }




}
