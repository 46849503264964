import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { VolumeDto } from "src/data/models/journalDtos/volumeDto.interface";
import { JournalsService } from "src/apis/services/journals/journalsService";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";

export const useAddVolumes = () => {
    const journalsService = container.resolve(JournalsService);
    const queryClient = useQueryClient();

    const toggleAdd = useCallback(
        (data:VolumeWithIssuesDto, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<VolumeWithIssuesDto>>([Api.Journals.GetJournalIssues , 1,data.journalId]);
            for (let index = 1; index <= tempData?.totalPages; index++) {
              queryClient.removeQueries([Api.Journals.GetJournalIssues , index,data.journalId]);
            }
            let allData = queryClient.getQueryData<VolumeWithIssuesDto[]>([
                Api.Journals.GetJournalAllIssues,
                data.journalId,
              ]);
              
        
            if (allData) {
              const finalData2 = [...allData];
              finalData2.push(data);
              queryClient.setQueryData(
                [Api.Journals.GetJournalAllIssues, data.journalId],
                finalData2
              );
            }
        },
        [],
    );
    return useMutation({
        mutationFn: (data: VolumeDto) => {
            return journalsService.addVolume(data);
        },
        onSuccess: toggleAdd
    })

}
