import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import BaseRepository from "../Base/BaseRepository";
import IIndexTypesRepository from "./IInexTypesRepository";

export default class IndexTypesRepository extends BaseRepository implements IIndexTypesRepository {

  async getIndexTypes(): Promise<IGenericResponse<IndexTypesDto[]>> {

    const result = this.CreateRequestBuilder()


    const request = result
    .setMethod('get')
    .setUrl(Api.IndexTypes.getIndexTypes)

  return await request
    .GetResultAsync();
  }
  async addIndexTypes(body: IndexTypesDto): Promise<IGenericResponse<IndexTypesDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.IndexTypes.addIndexTypes)

    return await request
      .GetResultAsync()
  }
  async editIndexTypes(body: IndexTypesDto): Promise<IGenericResponse<IndexTypesDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.IndexTypes.editIndexTypes)

    return await request
      .GetResultAsync() ;
  }

  async deleteIndexTypes(indexTypeId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.IndexTypes.deleteIndexTypes, { indexTypeId })

    return await request
      .GetResultAsync()
  }
  

 
}
