import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { ContentsService } from "src/apis/services/contents/contentsService";
import { ContentListDto } from "src/data/models/contentsDtos/contentListDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";

export const useAddContents = (contentType) => {
    const contentsService = container.resolve(ContentsService);
    const queryClient = useQueryClient();
    const toggleAddContents = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<ContentListDto>>([Api.Contents.getContentList , 1,contentType]);
            for (let index = 1; index <= tempData?.totalPages; index++) {
                queryClient.removeQueries([Api.Contents.getContentList , index,contentType]);
            }
            queryClient.removeQueries([Api.Contents.getAllContents,contentType]);

        },
        [contentType],
    );
    return useMutation({
        mutationFn: (data: ContentListDto) => {
            return contentsService.addContents(data);
        },
        onSuccess: toggleAddContents
    })

}
