import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";

export const useAddWebHomeData = (collectionId) => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const addWebHomeData = useCallback(
    (data: WebHomeDataDto, variables, context) => {
      let tempData = queryClient.getQueryData<PaginatedList<WebHomeDataDto>>([Api.BaseData.getWebHomeCollectionDataList, 1,collectionId]);
      let lastData = queryClient.getQueryData<PaginatedList<WebHomeDataDto>>([Api.BaseData.getWebHomeCollectionDataList, tempData.totalPages,collectionId]);
      const finalData = [...lastData.items];
      finalData.push(data);
      lastData.items = finalData;
      queryClient.setQueryData([Api.BaseData.getWebHomeCollectionDataList, tempData.totalPages,collectionId], lastData)
    },
    [collectionId],
  );
  return useMutation({
    mutationFn: (data: WebHomeDataDto) => {
      return baseDataService.addWebHomeData(data);
    },
    onSuccess: addWebHomeData
  })

}
