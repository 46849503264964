import { IndicatorDto } from "src/data/models/indicatorDtos/idicatorDto.interface";
import { singleton } from "tsyringe";
import BaseService from "../Base/BaseService";

@singleton()
export class IndicatorsService extends BaseService {
  constructor() {
    super();

    this.setMiddlware(this.errorCatcher, [
      "getIndicators",
      "addIndicators",
      "editIndicators",
      "deleteIndicators",
    ]);
    this.setMiddlware(this.handleToken, [
      "getIndicators",
      "addIndicators",
      "editIndicators",
      "deleteIndicators",
    ]);
  }

  async getIndicators(): Promise<IndicatorDto[]> {
    const result = await this.repositoryWrapper.Indicators.getIndicators();
    return result.result;
  }

  async addIndicators(data: IndicatorDto): Promise<IndicatorDto> {
    const result = await this.repositoryWrapper.Indicators.addIndicators(data);
    return result.result;
  }

  async editIndicators(data: IndicatorDto): Promise<IndicatorDto> {
    const result = await this.repositoryWrapper.Indicators.editIndicators(data);
    return result.result;
  }

  async deleteIndicators(indicatorId: number): Promise<number> {
    const result = await this.repositoryWrapper.Indicators.deleteIndicators(indicatorId);
    return result.result;
  }
}
