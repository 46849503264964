import React, { useEffect, useState } from "react";
import { Form, Input, Button, Checkbox, Card } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../util/IntlMessages";
import { useGetProfile } from "src/hooks/services/users/useGetProfile";
import { useEditProfile } from "src/hooks/services/users/useEditProfile";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import { useChangePassword } from "src/hooks/services/users/useChangePassword";
import { ChangePasswordDto } from "src/data/models/usersDtos/changePasswordDto.interface";
import { IErrorResult } from "src/apis/helpers/baseModels/IErrorMessageHandler";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onChangePassword, isLoading:loadingSave  } = useChangePassword();
  const [formData, setFormData] = useState([]);

  const { data: profileData, isLoading } = useGetProfile();


  useEffect(() => {
    if (!isLoading) {
      form.setFieldsValue(profileData);
    }
  }, [profileData]);


  const changePasswordSubmit = (values: ChangePasswordDto) => {
    onChangePassword(values, {
      onSuccess: (data) => {
        toast.success(
          <IntlMessages id="notification.successMessage" />,
        );
      },
      onError: (err: any) => {
        toast.error(
          <IntlMessages id="notification.errorMessage" />,
          err?.response?.data?.message
        );
      },
    });
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 14 },
        },
      }
      : null;

  return (
    <Card className="gx-card" title="Edit Profile">
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={changePasswordSubmit}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >

        <Form.Item
          name="currentPassword"
          label="Current Password"
          rules={[
            {
              required: true,
              message: "Please input your current password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="newPassword"
          label="New Password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
          hasFeedback
        >
          <Input.Password />
        </Form.Item>

        <Form.Item
          name="confirmNewPassword"
          label="Confirm New Password"
          dependencies={["newPassword"]}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  "The two passwords that you entered do not match!"
                );
              },
            }),
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button loading = {loadingSave} type="primary" htmlType="submit">Submit</Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </Card>
  );
};

export default ChangePassword;
