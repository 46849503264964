import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Select,
  Tooltip,
  Space,
  Modal,
} from "antd";
import { ToastContainer, toast } from "react-toastify";
import IntlMessages from "../../../../util/IntlMessages";
import AddAuthor from "../../authors/add/Add";

import { UploadOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { useAddArticles } from "src/hooks/services/articles/useAddArticles";
import { useEditArticles } from "src/hooks/services/articles/useEditArticles";
import { useGetJournals } from "src/hooks/services/journals/useGetJournals";
import { ArticleStatusEnum } from "src/data/enums/articleStatusEnum";
import { useGetAllAuthors } from "src/hooks/services/articles/author/useGetAllAuthors";
import Editor from "src/components/Editor";
import { useGetJournalArticleType } from "src/hooks/services/articles/type/useGetJournalArticleType";

const { TextArea } = Input;
const { Option } = Select;

const Add = ({ fields, editSection = false, changeTabEvent, searchData }) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddArticles, isLoading: loadingSave } = useAddArticles();
  const { mutate: onEditArticles, isLoading: loadingEdit } = useEditArticles();
  const { data: authorData, isLoading: isLoadingAuthors } = useGetAllAuthors();
  const [formData, setFormData] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [file, setFile] = useState(null);
  const { data: journalsData, isLoading: isLoadingJournal } = useGetJournals();
  const [visibleAuthor, setVisibleAuthor] = useState(false);
  const [abstract, setAbstract] = useState("");
  const [abstractDefault, setAbstractDefault] = useState("");
  const [journalId, setJournalId] = useState(-1);
  const [selectedAuthorList, setSelectedAuthorList] = useState([]);

  const { data: articleTypesData, isLoading: loadingType } =
    useGetJournalArticleType(journalId);

  useEffect(() => {
    if (fields) {
      if (fields.articleFile) {
        var file = [
          {
            uid: "-1",
            name: fields.articleFile.fileTitle,
            status: "done",
            url: fields.fileUrl,
          },
        ];
        setFileList(file);
        setFile(null);
      }
      setJournalId(fields.journalId);

      if (fields.authors) {
        if (fields.authors.length > 0) {
          var data = [];
          let allData = [];
          fields.authors.forEach((author) => {
            data.push(author.id);
            allData.push(author);
            if (author.coAuthor) {
              fields.coAuthor = author.id;
            }
          });
          fields.authorsList = data;
          setSelectedAuthorList(allData);
        }
      }

      form.setFieldsValue(fields);
      setAbstractDefault(fields.abstract);
      setAbstract(fields.abstract);
    }
    return () => {
      form.resetFields();
      setFileList([]);
      setFileList(null);
    };
  }, [fields, editSection, form]);

  const saveArticles = (values) => {
    if ((!file && !editSection) || !abstract) {
      return;
    }
    if (!values.authorsList) {
      return;
    }
    values.abstract = abstract;
    const formData = new FormData();
    if (values.authorsList) {
      values.articleAuthors = [];
      values.authorsList.forEach((element) => {
        let data = {
          id: 0,
          articleId: 0,
          authorId: element,
          coAuthor: values.coAuthor === element ? true : false,
        };
        values.articleAuthors.push(data);
      });
    }

    values.status = ArticleStatusEnum.NewArticle;
    if (editSection) {
      values.articleId = fields.articleId;
      if (file) {
        formData.append("File", file);
      }
      formData.append("Article", JSON.stringify(values));
      onEditArticles(formData, {
        onSuccess: (data) => {
          toast.success(<IntlMessages id="notification.successMessage" />);
          form.resetFields();
          setAbstract("");
          setAbstractDefault("");
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(<IntlMessages id="notification.errorMessage" />);
        },
      });
    } else {
      formData.append("File", file);
      formData.append("Article", JSON.stringify(values));

      onAddArticles(formData, {
        onSuccess: (data) => {
          toast.success(<IntlMessages id="notification.successMessage" />);
          form.resetFields();
          setAbstract("");
          setAbstractDefault("");
          setFileList([]);
        },
        onError: (err) => {
          toast.error(<IntlMessages id="notification.errorMessage" />);
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section

  const beforeUpload = (fileSelected) => {
    setFile(fileSelected);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var file = [
        {
          uid: "-2",
          name: fileSelected.name,
          status: "done",
          url: reader.result,
        },
      ];
      setFileList(file);
    });

    reader.readAsDataURL(fileSelected);
    return false;
  };

  const removeUpload = () => {
    setFileList([]);
    setFile(null);
  };

  const afterSaveAuthor = (data) => {
    setVisibleAuthor(false);
  };

  const selectJournal = (data) => {
    setJournalId(data);
  };

  const handlerSelectedAuthor = (authors) => {
    const selectedValue = [];
    for (let index = 0; index < authors.length; index++) {
      selectedValue.push(authorData.find((c) => c.id === authors[index]));
    }
    setSelectedAuthorList(selectedValue);
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveArticles"
        onFinish={saveArticles}
        fields={formData}
        scrollToFirstError
        autoComplete="off"
      >
        <Form.Item
          name="journalId"
          label="Journal"
          rules={[
            {
              required: true,
              message: "Please Select Journal!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Journal"
            optionFilterProp="children"
            onChange={selectJournal}
          >
            {!isLoadingJournal &&
              journalsData.map((data) => {
                return (
                  <Option key={data.jid} value={data.jid}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="title"
          label="Title"
          rules={[
            {
              required: true,
              message: "Please input title",
            },
          ]}
        >
          <Input placeholder="title" />
        </Form.Item>

        <Form.Item label="Abstract" required>
          <Editor
            value={abstractDefault}
            onChange={(data, editor) => {
              setAbstract(data);
            }}
          />
        </Form.Item>

        <Form.Item
          name="articleTypeId"
          label="Article Type"
          rules={[
            {
              required: true,
              message: "Please Select Article Type!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select Article Type"
            optionFilterProp="children"
          >
            {!loadingType &&
              articleTypesData.map((data) => {
                return (
                  <Option key={data.id} value={data.id}>
                    {data.name}
                  </Option>
                );
              })}
          </Select>
        </Form.Item>

        <Form.Item name="description" label="Description For Editor In Chief">
          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} maxLength={150} />
        </Form.Item>

        <Form.Item label="Author list" required>
          <Form.Item name="authorsList" noStyle>
            <Select
              showSearch
              placeholder="Select Authors"
              mode="multiple"
              style={{ width: "85%" }}
              optionFilterProp="children"
              onChange={handlerSelectedAuthor}
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {authorData?.map((data) => {
                return (
                  <Option key={data.id} value={data.id}>
                    {`${data.fullName} - ${data.email}`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          <Tooltip title="add new author">
            <Button
              style={{ width: "10%", margin: "0 10px 2px" }}
              onClick={() => setVisibleAuthor(true)}
              icon={<PlusCircleOutlined />}
            />
          </Tooltip>
        </Form.Item>

        <Form.Item name="coAuthor" label="Corresponding Author">
          <Select
            showSearch
            placeholder="Select Corresponding Author"
            style={{ width: "85%" }}
            optionFilterProp="children"
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {selectedAuthorList?.map((data) => {
              return (
                <Option key={data.id} value={data.id}>
                  {data.fullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>

        <Form.Item label="File" required>
          <Upload
            name="avatar"
            beforeUpload={beforeUpload}
            onRemove={removeUpload}
            maxCount={1}
            fileList={fileList}
            accept=".pdf, .doc, .docx"
            showUploadList={{ showPreviewIcon: false }}
          >
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Send"}
          </Button>
        </Form.Item>
      </Form>

      {/* <NotificationContainer /> */}

      <Modal
        title="Add new author"
        centered
        visible={visibleAuthor}
        footer={null}
        onCancel={() => setVisibleAuthor(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleAuthor ? (
          <AddAuthor
            fields
            editSection={false}
            changeTabEvent={afterSaveAuthor}
            searchData={{ page: 1, fullName: "", email: "" }}
            articleSection={true}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default Add;
