import React from "react";
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from "react-router-dom";
import "assets/vendors/style";
import "styles/wieldy.less";

import configureStore, { history } from './appRedux/store';
import App from "./containers/App/index";
import { AuthProvider } from "./authentication";
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { reactQueryConfig } from 'src/apis/helpers/configs/react-query';

const store = configureStore();

const NextApp = () => {

  const [queryClient] = React.useState(() => new QueryClient(reactQueryConfig))

  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <AuthProvider>
            <Switch>
              <Route path="/" component={App} />
            </Switch>
          </AuthProvider>
        </ConnectedRouter>
      </Provider>
    </QueryClientProvider>
  )
}


export default NextApp;
