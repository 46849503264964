import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { SettingDto } from "src/data/models/settingDtos/settingDto.interface";

import { container } from "tsyringe";

export const useEditSetting = () => {
    const settingService = container.resolve(BaseDataService);
    const queryClient = useQueryClient();
    const toggleEditSetting = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<SettingDto>([Api.BaseData.getSetting]);
            tempData.InstagramAccountUsername = data.InstagramAccountUsername;
            tempData.LinkedinAccountUsername = data.LinkedinAccountUsername;
            queryClient.setQueryData([Api.BaseData.getSetting],tempData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: SettingDto) => {
            return settingService.editSetting(data);
        },
        onSuccess: toggleEditSetting
    })

}
