import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { NewsService } from "src/apis/services/news/newsService";

export const useAddNews = () => {
    const newsService = container.resolve(NewsService);
    const queryClient = useQueryClient();

    const toggleAddNews = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<PaginatedList<NewsDto>>([Api.News.getNews , 1]);
            let lastData = queryClient.getQueryData<PaginatedList<NewsDto>>([Api.News.getNews , tempData.totalPages]);
            const finalData = [...lastData.items] ;
            finalData.push(data);
            lastData.items = finalData ;
            queryClient.setQueryData([Api.News.getNews,tempData.totalPages],lastData )
        },
        [],
    );
    return useMutation({
        mutationFn: (data: NewsDto) => {
            return newsService.addNews(data);
        },
        onSuccess: toggleAddNews
    })

}
