import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { AddJouranlIndexTypeDto } from "src/data/models/indexTypesDtos/addJouranlIndexTypeDto.interface";
import { IndexTypesDto } from "src/data/models/indexTypesDtos/indexTypesDto.interface";
import { JournalIndexTypeListDto } from "src/data/models/indexTypesDtos/journalIndexTypeListDto.interface";
import { JournalIndicatorListDto } from "src/data/models/indicatorDtos/journalIndicatorListDto.interface";
import { IssueDto } from "src/data/models/journalDtos/issueDto.interface";
import { JournalDto } from "src/data/models/journalDtos/journalDto.interface";
import { VolumeDto } from "src/data/models/journalDtos/volumeDto.interface";
import { VolumeWithIssuesDto } from "src/data/models/journalDtos/volumeWithIssuesDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { journalsPaginationParams } from "src/data/models/params/journalsPaginationParams.interface";
import { AddUserPermissionDto } from "src/data/models/usersDtos/addUserPermissionDto.interface";
import { UserPermissionDto } from "src/data/models/usersDtos/userPermissionDto.interface";

import BaseRepository from "../Base/BaseRepository";
import IJournalsRepository from "./IJournalsRepository";

export default class JournalsRepository extends BaseRepository implements IJournalsRepository {

  async getJournalList():  Promise<IGenericResponse<JournalDto[]>> {
   
    const result = this.CreateRequestBuilder()

    const request = result
    .setMethod('get')
    .setUrl(Api.Journals.getJournalList)

  return await request
    .GetResultAsync();
  }
  async addJournals(body: JournalDto): Promise<IGenericResponse<JournalDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Journals.addJournal)

    return await request
      .GetResultAsync()
  }
  async editJournals(body: JournalDto): Promise<IGenericResponse<JournalDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Journals.editJournal)

    return await request
      .GetResultAsync() ;
  }

  async toggleJournalActivation(id): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(id)
      .setUrl(Api.Journals.toggleJournalActivation)

    return await request
      .GetResultAsync() ;
  }

  
  async getJournalIndicators(jouranlId:number): Promise<IGenericResponse<JournalIndicatorListDto[]>> {

    const result = this.CreateRequestBuilder()

    const request = result
    .setMethod('get')
    .addQueryParam("JournalId" , jouranlId)
    .setUrl(Api.Journals.getJournalIndicators)

  return await request
    .GetResultAsync();
  }

  async assignIndicatorToJournal(body: JournalIndicatorListDto): Promise<IGenericResponse<JournalIndicatorListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Journals.assignIndicatorToJournal)

    return await request
      .GetResultAsync()
  }

  async editJournalIndicator(body: JournalIndicatorListDto): Promise<IGenericResponse<JournalIndicatorListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Journals.editJournalIndicator)

    return await request
      .GetResultAsync()
  }
  async deleteJournalIndicator(journalIndicatorId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.Journals.deleteJournalIndicator, { journalIndicatorId })

    return await request
      .GetResultAsync()
  }

  
  async getJournalIndexTypes(jouranlId:number): Promise<IGenericResponse<JournalIndexTypeListDto[]>> {

    const result = this.CreateRequestBuilder()

    const request = result
    .setMethod('get')
    .addQueryParam("JournalId" , jouranlId)
    .setUrl(Api.Journals.getJournalIndexTypes)

  return await request
    .GetResultAsync();
  }

  async assignIndexTypeToJournal(body: AddJouranlIndexTypeDto): Promise<IGenericResponse<JournalIndexTypeListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Journals.assignIndexTypeToJournal)

    return await request
      .GetResultAsync()
  }

  async editJournalIndexType(body: AddJouranlIndexTypeDto): Promise<IGenericResponse<JournalIndexTypeListDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Journals.editJournalIndexType)

    return await request
      .GetResultAsync()
  }
  async deleteJournalIndexType(journalIndexId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.Journals.deleteJournalIndexType, { journalIndexId })

    return await request
      .GetResultAsync()
  }

   
  async getJournalUserPermissions(jouranlId:number): Promise<IGenericResponse<UserPermissionDto[]>> {

    const result = this.CreateRequestBuilder()

    const request = result
    .setMethod('get')
    .addQueryParam("JournalId" , jouranlId)
    .setUrl(Api.Journals.getJournalUserPermissions)

  return await request
    .GetResultAsync();
  }

  async addUserPermission(body: AddUserPermissionDto): Promise<IGenericResponse<UserPermissionDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Journals.addUserPermission)

    return await request
      .GetResultAsync()
  }

  async deleteUserPermission(userPermissinId: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.Journals.deleteUserPermission, { userPermissinId })

    return await request
      .GetResultAsync()
  }




  
  async getJournalVolumes(params: journalsPaginationParams): Promise<IGenericResponse<PaginatedList<VolumeDto>>> {

    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
        result.addQueryParam(key, value)
    }

    const request = result
    .setMethod('get')
    .setUrl(Api.Journals.GetJournalVolumes)

  return await request
    .GetResultAsync();
  }
  
  async getJournalIssues(params: journalsPaginationParams): Promise<IGenericResponse<PaginatedList<VolumeWithIssuesDto>>> {

    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
        result.addQueryParam(key, value)
    }

    const request = result
    .setMethod('get')
    .setUrl(Api.Journals.GetJournalIssues)

  return await request
    .GetResultAsync();
  }  

  async getJournalAllIssues(journalId): Promise<IGenericResponse<VolumeWithIssuesDto[]>> {

    const result = this.CreateRequestBuilder();
    result.addQueryParam("JournalId", journalId);
    const request = result
    .setMethod('get')
    .setUrl(Api.Journals.GetJournalAllIssues)

  return await request
    .GetResultAsync();
  }

  async addVolume(body: VolumeDto): Promise<IGenericResponse<VolumeDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Journals.AddVolume)

    return await request
      .GetResultAsync()
  }
  
  async addIssue(body: IssueDto): Promise<IGenericResponse<IssueDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Journals.AddIssue)

    return await request
      .GetResultAsync()
  }

  async editVolume(body: VolumeDto): Promise<IGenericResponse<VolumeDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Journals.EditVolume)

    return await request
      .GetResultAsync() ;
  }

  async editIssue(body: IssueDto): Promise<IGenericResponse<IssueDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Journals.EditIssue)

    return await request
      .GetResultAsync() ;
  }

  async deleteVolume(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.Journals.DeleteVolume, { id })

    return await request
      .GetResultAsync()
  }
  
  async deleteIssue(id: number): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('delete')
      .setUrl(Api.Journals.DeleteIssue, { id })

    return await request
      .GetResultAsync()
  }
  


 
}
