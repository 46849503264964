import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { SubjectsService } from "src/apis/services/subjects/subjectsService";
import { container } from "tsyringe";

export const useGetSubjects = () => {
    const subjectsService = container.resolve(SubjectsService);

    return useQuery({
        queryKey: [Api.Subjects.getSubjects],
        queryFn: () => {
            return subjectsService.getSubjects();
        }
    })
}
