import React, { useState } from "react";
import { Modal, Table, Button, Space, Form, Select, Input } from "antd";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetIssues } from "src/hooks/services/journals/issue/useGetIssues";
import { useDeleteIssues } from "src/hooks/services/journals/issue/useDeleteIssues";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../../util/IntlMessages";
import { CheckCircleOutlined,CloseCircleOutlined } from '@ant-design/icons';
const { Column } = Table;
const { Option } = Select;
const confirm = Modal.confirm;
const List = (props) => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const {  mutate: onDeleteIssues } = useDeleteIssues(page, props.journalsData.jid , props.volumeId);

  const changePage = (page) => {
    setPage(page);
  };

  const editIssues = (data) => {
    props.changeTabEvent("2", data, page); // raftan be tab edit
  };

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteIssues(data.iid, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err:any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() {},
    });
  };


  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={props.issueList}
        rowKey="iid"
      >
        <Column
          title="Issue Number"
          dataIndex="issueNumber"
          key="issueNumber"
        />
        <Column title="title" dataIndex="title" key="title" />
        <Column title="special issue" dataIndex="isSpecialIssue" key="isSpecialIssue" 
        render={(text, record:any)=> (
            <Space size="middle" >
                 {record.isSpecialIssue ? (
                   <span>
                     yes
                   </span>
                 ) : (
                   <span>
                     no
                   </span>
                 )}
            </Space>
           )}
        />
        <Column title="published date" dataIndex="publishedDate" key="publishedDate" />
        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editIssues(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default List;
