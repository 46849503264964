import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select,InputNumber } from "antd";

import { toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useAssignIndicatorToJournal } from "src/hooks/services/journals/journalIndicators/useAssignIndicatorToJournal";
import { useEditJournalIndicator } from "src/hooks/services/journals/journalIndicators/useEditJournalIndicator";
import { useGetIndicators } from "src/hooks/services/indicators/useGetIndicators";
const { Option } = Select;

const IndicatorAssignAdd = ({
  fields,
  editSection = false,
  changeTabEvent,
  journalData,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const { mutate: onAddIndicatorAssign, isLoading:loadingSave  } = useAssignIndicatorToJournal(
    journalData.jid
  );
  const { mutate: onEditIndicatorAssign, isLoading:loadingEdit } = useEditJournalIndicator(
    journalData.jid
  );
  const { data: indicatorsData, isLoading:isIndicatorsLoading } = useGetIndicators();

  useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [fields, editSection]);

  const saveIndicatorAssign = (values) => {
    values.journalId =  journalData.jid;
    if (editSection) {
      values.id = fields.id;
      onEditIndicatorAssign(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          changeTabEvent("1");
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      onAddIndicatorAssign(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveIndicatorAssign}
        autoComplete="off"
      >
        <Form.Item
          name="indicatorId"
          label="Indicator"
          rules={[
            {
              required: true,
              message: "Please select indicator!",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select indicator"
            optionFilterProp="children"
          >
            {!isIndicatorsLoading ? indicatorsData.map((data) => {
              return <Option key={data.id} value={data.id}>{data.title}</Option>;
            }) : null}
          </Select>
        </Form.Item>

        <Form.Item
          label="Value"
          name="value"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber stringMode/>
        </Form.Item>
        <Form.Item>
          <Button loading = {loadingSave || loadingEdit} type="primary" htmlType="submit">
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default IndicatorAssignAdd;
