import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { MessagesService } from "src/apis/services/messages/messagesService";

export const useGetSentMessagesList = (page = 1) => {
    const messagesService = container.resolve(MessagesService);
    return useQuery({
        queryKey: [Api.Messages.getSentMessagesList,page],
        queryFn: () => {

            if (typeof page !== 'number') {
                page = 1;
            }

            return messagesService.getSentMessagesList({
                pageNumber: page,
                pageSize: PageSize.tables,
            });
        }
    })
}
