import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Upload,
  Select,
  InputNumber,
  Radio,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../../util/IntlMessages";

import Editor from "src/components/Editor";
import { useGetSubjects } from "src/hooks/services/subjects/useGetSubjects";
import { useAddWebHomeData } from "src/hooks/services/baseData/useAddWebHomeData";
import { useEditWebHomeData } from "src/hooks/services/baseData/useEditWebHomeData";
import { useGetAllContents } from "src/hooks/services/contents/useGetAllContents";
import { ContentTypeEnum } from "src/data/enums/contentType.enum";
import { HomeDataTypeEnum } from "src/data/enums/homeDataType.enum";
import { useGetJournals } from "src/hooks/services/journals/useGetJournals";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";
const { Option } = Select;
const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;

const Add = ({
  fields,
  editSection = false,
  changeTabEvent,
  currentPage,
  data,
  radioType,
  changeRadio,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const { mutate: onAddWebHomeData, isLoading: loadingSave } =
    useAddWebHomeData(data.webHomeDataCollectionId);
  const { mutate: onEditWebHomeData, isLoading: loadingEdit } =
    useEditWebHomeData(currentPage, data.webHomeDataCollectionId);

  const { data: books, isLoading: isLoadingBooks } = useGetAllContents(
    ContentTypeEnum.Book
  );
  const { data: conferences, isLoading: isLoadingConferences } =
    useGetAllContents(ContentTypeEnum.Conference);
  const { data: courses, isLoading: isLoadingCourses } = useGetAllContents(
    ContentTypeEnum.Course
  );
  const { data: journals, isLoading: isLoadingJournals } = useGetJournals();

  const [formData, setFormData] = useState([]);
  const [description, setDescription] = useState("");
  const [descriptionDefault, setDescriptionDefault] = useState("");
  const [imageList, setImageList] = useState([]);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    if (fields) {
      setDescription(fields.description);
      setDescriptionDefault(fields.description);
      var imageFile = [
        {
          uid: "-1",
          name: "image",
          status: "done",
          url: fields.imageUrl,
        },
      ];
      setImageList(imageFile);
      if (editSection) {
        if (fields.fkContentId != null) {
          switch (fields.contentTypeId) {
            case ContentTypeEnum.Book:
              fields.book = fields.fkContentId;
              break;
            case ContentTypeEnum.Conference:
              fields.conference = fields.fkContentId;
              break;
            case ContentTypeEnum.Course:
              fields.course = fields.fkContentId;
              break;
            default:
              break;
          }
        }
      }
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
      setDescription("");
      setDescriptionDefault("");
      setImageList([]);
      setImageFile(null);
    };
  }, [fields, editSection, form]);

  const saveWebHomeData = (values: any) => {
    if (!description && radioType == HomeDataTypeEnum.Custom) {
      return;
    }

    if (
      imageFile === null &&
      !editSection &&
      radioType == HomeDataTypeEnum.Custom
    ) {
      return;
    }

    if (values.book) {
      values.fkContentId = values.book;
    }
    if (values.conference) {
      values.fkContentId = values.conference;
    }
    if (values.course) {
      values.fkContentId = values.course;
    }
    values.description = description;
    values.fkWebHomeDataCollectionId = data.webHomeDataCollectionId;
    const formData = new FormData();
    formData.append("Image", imageFile);
    if (editSection) {
      values.webHomeDataId = fields.webHomeDataId;
      formData.append("HomeData", JSON.stringify(values));
      onEditWebHomeData(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          changeTabEvent("1");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    } else {
      formData.append("HomeData", JSON.stringify(values));

      onAddWebHomeData(formData, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
          setDescription("");
          setDescriptionDefault("");
          setImageList([]);
          setImageFile(null);
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    }
  };

  const onChangeHomeDataType = (val) => {
    changeRadio(val.target.value);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  // uploads section
  const beforeUpload = (file) => {
    setImageFile(file);
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      var imageFile = [
        {
          uid: "-2",
          name: "image",
          status: "done",
          url: reader.result,
        },
      ];
      setImageList(imageFile);
    });
    reader.readAsDataURL(file);
    return false;
  };

  const removeUpload = () => {
    setImageList([]);
    setImageFile(null);
  };

  const uploadButton = (
    <div>
      {<PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="saveWebHomeData"
        onFinish={saveWebHomeData}
        fields={formData}
        scrollToFirstError
        initialValues={{ ["type"]: radioType }}
        autoComplete="off"
      >
        <Form.Item name="type" label="Type">
          <RadioGroup onChange={onChangeHomeDataType}>
            <RadioButton value={HomeDataTypeEnum.Journal}>Journal</RadioButton>
            <RadioButton value={HomeDataTypeEnum.Book}>Book</RadioButton>
            <RadioButton value={HomeDataTypeEnum.Conference}>
              Conference
            </RadioButton>
            <RadioButton value={HomeDataTypeEnum.Course}>Course</RadioButton>
            <RadioButton value={HomeDataTypeEnum.Custom}>Custom</RadioButton>
          </RadioGroup>
        </Form.Item>

        {radioType == HomeDataTypeEnum.Custom && (
          <>
            <Form.Item
              name="title"
              label="Title"
              rules={[
                {
                  required: true,
                  message: "Please Enter Title!",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Description" required>
              <Editor
                value={descriptionDefault}
                onChange={(data, editor) => {
                  setDescription(data);
                }}
              />
            </Form.Item>

            <Form.Item label="Image" required>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                beforeUpload={beforeUpload}
                onRemove={removeUpload}
                maxCount={1}
                fileList={imageList}
                accept=".png, .jpg, .jpeg"
                showUploadList={{ showPreviewIcon: false }}
              >
                {imageList.length === 1 ? null : uploadButton}
              </Upload>
            </Form.Item>
          </>
        )}

        {radioType == HomeDataTypeEnum.Journal && (
          <Form.Item
            name="fkJournalId"
            label="Journal"
            rules={[
              {
                required: true,
                message: "Please Select Journal!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select Journal"
              optionFilterProp="children"
            >
              {!isLoadingJournals
                ? journals.map((data) => {
                    return (
                      <Option key={data.jid} value={data.jid}>
                        {data.name}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        )}

        {radioType == HomeDataTypeEnum.Book && (
          <Form.Item
            name="book"
            label="Book"
            rules={[
              {
                required: true,
                message: "Please Select Book!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select Book"
              optionFilterProp="children"
            >
              {!isLoadingBooks
                ? books.map((data) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.title}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        )}

        {radioType == HomeDataTypeEnum.Course && (
          <Form.Item
            name="course"
            label="Course"
            rules={[
              {
                required: true,
                message: "Please Select Course!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select Course"
              optionFilterProp="children"
            >
              {!isLoadingCourses
                ? courses.map((data) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.title}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        )}

        {radioType == HomeDataTypeEnum.Conference && (
          <Form.Item
            name="conference"
            label="Conference"
            rules={[
              {
                required: true,
                message: "Please Select Conference!",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select Conference"
              optionFilterProp="children"
            >
              {!isLoadingConferences
                ? conferences.map((data) => {
                    return (
                      <Option key={data.id} value={data.id}>
                        {data.title}
                      </Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
        )}

        <Form.Item>
          <Button
            loading={loadingSave || loadingEdit}
            type="primary"
            htmlType="submit"
          >
            {editSection ? "Edit" : "Save"}
          </Button>
        </Form.Item>
      </Form>

       {/* <NotificationContainer /> */}
    </>
  );
};

export default Add;
