import { useCallback } from "react";
import { useMutation ,useQueryClient} from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndicatorsService } from "src/apis/services/indicators/indicatorsService";
import { IndicatorDto } from "src/data/models/indicatorDtos/idicatorDto.interface";
import { container } from "tsyringe";

export const useAddIndicators = () => {
    const indicatorsService = container.resolve(IndicatorsService);
    const queryClient = useQueryClient();

    const toggleAddIndicator = useCallback(
        (data, variables, context) => {
            let tempData = queryClient.getQueryData<IndicatorDto[]>([Api.Indicators.getIndicators]);
            const finalData = [...tempData] ;
            finalData.push(data);
            queryClient.setQueryData([Api.Indicators.getIndicators],finalData )
        },
        [],
    );

    return useMutation({
        mutationFn: (data: IndicatorDto) => {
            return indicatorsService.addIndicators(data);
        },
        onSuccess:toggleAddIndicator
    })

}
