import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { MessagesService } from "src/apis/services/messages/messagesService";
import { MessagesDto } from "src/data/models/messagesDtos/MessagesDto.interface";

export const useReadMessage = (currentPage) => {
    const messagesService = container.resolve(MessagesService);
    
    const queryClient = useQueryClient()

    const toggleReadMessage = useCallback(
        (targetMessage) => {
            let tempObject = queryClient.getQueryData<PaginatedList<MessagesDto>>([Api.Messages.getMessagesList,currentPage])

            if (tempObject === undefined) return

            const findIndex = tempObject.items.findIndex(item => item.messageId === targetMessage.id);
            tempObject.items[findIndex].readMessage = !tempObject.items[findIndex].readMessage ;
            queryClient.setQueryData([Api.Messages.getMessagesList,currentPage], tempObject)
        },
        [currentPage],
    )

    return useMutation({
        mutationFn: (messageId) => {
            return messagesService.readMessage(messageId);
        },
        onMutate: toggleReadMessage,
        onError: (err) => {
        }
    })
}