import { useCallback } from "react";
import { useMutation , useQueryClient } from "react-query";
import { Api } from "../../../../apis/helpers/configs/api";
import { ContentsService } from "../../../../apis/services/contents/contentsService";
import { MenuListDto } from "../../../../data/models/contentsDtos/menuListDto.interface";

import { container } from "tsyringe";

export const useEditMenu = (id,isForContent,type=0) => {
    const contentsService = container.resolve(ContentsService);
    const queryClient = useQueryClient();
    const toggleEditMenu = useCallback(
        (data, variables, context) => {
            queryClient.removeQueries([Api.Contents.getContentMenuList,id,isForContent,type]);
        },
        [id,isForContent,type],
    );
    return useMutation({
        mutationFn: (data: MenuListDto) => {
            return contentsService.editMenuList(data);
        },
        onSuccess: toggleEditMenu
    })

}
