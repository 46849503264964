import { useQuery } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";

export const useGetWebHomeCollectionDataList = (page = 1 , collectionId) => {
  const baseDataService = container.resolve(BaseDataService);

  return useQuery({
    queryKey: [Api.BaseData.getWebHomeCollectionDataList, page,collectionId],
    queryFn: () => {

      if (typeof page !== 'number') {
        page = 1;
      }

      return baseDataService.getWebHomeCollectionDataList({
        pageNumber: page,
        pageSize: PageSize.tables,
        collectionId: collectionId,
      });
    }
  })
}
