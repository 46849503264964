import { useMutation,useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { IndicatorsService } from "src/apis/services/indicators/indicatorsService";
import { IndicatorDto } from "src/data/models/indicatorDtos/idicatorDto.interface";
import { container } from "tsyringe";
import { useCallback } from "react"
export const useDeleteIndicators = () => {
    const indicatorsService = container.resolve(IndicatorsService);
    

    const queryClient = useQueryClient();

    const toggleDeleteIndicator = useCallback(
        (targetIndicatorId) => {
            let tempObject = queryClient.getQueryData<IndicatorDto[]>([Api.Indicators.getIndicators])

            if (tempObject === undefined) return

            const finalData = tempObject.filter(item => item.id !== targetIndicatorId)
            queryClient.setQueryData([Api.Indicators.getIndicators], finalData)
        },
        [],
    )

    return useMutation({
        mutationFn: (indicatorId: number) => {
            return indicatorsService.deleteIndicators(indicatorId);
        },
        onSuccess: (data, targetId, context) => {
            toggleDeleteIndicator(targetId);
        }
    })
}