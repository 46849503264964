import React from "react";
import { Col, Row } from "antd";
import IconWithTextCard from "components/Metrics/IconWithTextCard";
import IntlMessages from "util/IntlMessages";
import { useAuth } from "../../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
const DashboardPage = () => {
  const { authUser, isLoadingUser } = useAuth();
  return (
    <div>
      {authUser?.userInfo.role === UserGroupEnum.Admin && (
      <Row>
      {/* <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
          <IconWithTextCard icon="feedback" iconColor="geekblue" title="2,380" subTitle="Journal Count"/>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
          <IconWithTextCard icon="ckeditor" iconColor="geekblue" title="2,380" subTitle="Confrence Count"/>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
          <IconWithTextCard icon="calendar" iconColor="geekblue" title="2,380" subTitle="Book Count"/>
        </Col>
        <Col xl={6} lg={12} md={12} sm={12} xs={12} className="gx-col-full">
          <IconWithTextCard icon="auth-screen" iconColor="geekblue" title="2,380" subTitle="Course Count"/>
        </Col>
      */}
      </Row>
      )}

    </div>
  );
};

export default DashboardPage;
