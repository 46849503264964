import React , {useState} from "react";
import {Card, Tabs} from "antd";
import List from './list/List';
import Add from './add/Add';
import AddAssistant from './add-assistant/Add';
import { useAuth } from "../../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
const TabPane = Tabs.TabPane;


const UsersPage = () => {
  const [activeTab , setActiveTab] = useState("1");
  const [userEditData , setUserEditData] = useState(null);
  const [userEditFlag , setUserEditFlag] = useState(false);
  const [currentPage , setCurrentPage] = useState(1);
  const { authUser, isLoadingUser } = useAuth();
  const callback = (key , data = null , currentPage=1) => {
    setActiveTab(key);
    if(data && key === "2") {
      setUserEditData(data);
      setUserEditFlag(true);
      setCurrentPage(currentPage);
    } else if(key === "2") {
      setUserEditFlag(false);
      setUserEditData(null);
    }
  };

  return (
    <Card className="gx-card" 
    title={authUser?.userInfo.role === UserGroupEnum.Admin ? "Users" : "Assistant Users"}>
      <Tabs activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
          <List changeTabEvent = {callback}/>
        </TabPane>
        <TabPane tab="Add / Edit User" key="2">
        {
          authUser?.userInfo.role === UserGroupEnum.Admin ? (
            <Add fields = {userEditData} editSection = {userEditFlag} changeTabEvent = {callback} currentPage = {currentPage}/>
          ) : 
          (
            <AddAssistant fields = {userEditData} editSection = {userEditFlag} changeTabEvent = {callback} currentPage = {currentPage}/>
          )
          
        }
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default UsersPage;
