import React, { useState, useEffect } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
import { useAuth } from "../../../authentication";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
const TabPane = Tabs.TabPane;

const AuthorsPage = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [authorsEditData, setAuthorsEditData] = useState(null);
  const [authorsEditFlag, setAuthorsEditFlag] = useState(false);
  const [searchData, setSearchData] = useState({
    page:1 ,
    fullName : "",
    email : ""
  });
  const { authUser, isLoadingUser } = useAuth();
  const callback = (key, data = null, searchData) => {
    setActiveTab(key);
    if (data && key === "2") {
      setAuthorsEditData(data);
      setAuthorsEditFlag(true);
      setSearchData(searchData);
    } else if (key === "2") {
      setAuthorsEditFlag(false);
      setAuthorsEditData(null);
    }
  };

  return (
    <Card className="gx-card" title="Authors list">
       {authUser?.userInfo.role === UserGroupEnum.Assistant ? (
      <Tabs activeKey={activeTab} onChange={callback}>
        <TabPane tab="List" key="1">
            <List changeTabEvent={callback}  role = {authUser?.userInfo.role}/>
        </TabPane>
        <TabPane tab="Add / Edit Authors" key="2">
            <Add
              fields={authorsEditData}
              editSection={authorsEditFlag}
              changeTabEvent={callback}
              searchData={searchData}
            />
        </TabPane>
      </Tabs>
       ):
       (
        <List changeTabEvent={callback}  />
       )
       }
    </Card>
  );
};

export default AuthorsPage;
