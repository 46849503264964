import React, { useState } from "react";
import { Card, Tabs } from "antd";
import List from "./list/List";
import Add from "./add/Add";
const TabPane = Tabs.TabPane;

const IssuePage = (props) => {
  const [activeTab, setActiveTab] = useState("1");
  const [issueEditData, setIssueEditData] = useState(null);
  const [issueEditFlag, setIssueEditFlag] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const callback = (key, data = null, currentPage = 1) => {
    setActiveTab(key);
    if (data && key === "2") {
      setIssueEditData(data);
      setIssueEditFlag(true);
      setCurrentPage(currentPage);
    } else if (key === "2") {
      setIssueEditFlag(false);
      setIssueEditData(null);
    }
  };

  return (
        <Tabs activeKey={activeTab} onChange={callback}>
          <TabPane tab="List" key="1">
            <List volumeId={props.volume.vid} issueList={props.volume.issues} journalsData={props.journalsData}  changeTabEvent={callback} />
          </TabPane>
          <TabPane tab="Add / Edit Issue" key="2">
            <Add
              fields={issueEditData}
              editSection={issueEditFlag}
              changeTabEvent={callback}
              currentPage={currentPage}
              journals={props.journalsData}
              volumeId={props.volume.vid}
            />
          </TabPane>
        </Tabs>
    
  );
};

export default IssuePage;
