import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { container } from "tsyringe";
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { webHomeDataCollectionDto } from "src/data/models/webHomeDataDtos/webHomeDataCollectionDto.interface";

export const useAddWebHomeCollection = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const addData = useCallback(
    (data: webHomeDataCollectionDto, variables, context) => {
      let lastData = queryClient.getQueryData<webHomeDataCollectionDto[]>([Api.BaseData.getWebHomeCollections]);
      const finalData = [...lastData];
      finalData.push(data);
      lastData = finalData;
      queryClient.setQueryData([Api.BaseData.getWebHomeCollections], lastData)
    },
    [],
  );
  return useMutation({
    mutationFn: (data: webHomeDataCollectionDto) => {
      return baseDataService.addWebHomeCollection(data);
    },
    onSuccess: addData
  })

}
