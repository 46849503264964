import React, { useState } from "react";
import { Card, Tabs } from "antd";
import List from './list/List';
import Add from './add/Add';
const TabPane = Tabs.TabPane;


const EditorFilesPage = () => {
  const [newFile, setNewFile] = useState("");
  const callback = (key) => {
    setNewFile(key);
  };

  return (
    <Card className="gx-card" title="Files list">
      <Tabs>
        <TabPane tab="List" key="1">
          <List newFile={newFile}/>
        </TabPane>
        <TabPane tab="Add Files" key="2">
          <Add changeTabEvent={callback} />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default EditorFilesPage;
