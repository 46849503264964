import React, { useEffect, useState } from "react";
import { Form, Input, Button, Select, InputNumber } from "antd";

import { toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useGetAllUsers } from "src/hooks/services/users/useGetAllUsers";
import { AddUserPermissionDto } from "src/data/models/usersDtos/addUserPermissionDto.interface";
import { UserGroupEnum } from "src/data/enums/userGroup.enum";
import { useAddUserPermission } from "src/hooks/services/contents/conferenceUserPermisson/useAddUserPermission";

const { Option } = Select;

const PermissionAdd = ({
  userInfo,
  fields,
  ConferenceData,
}) => {
  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [formData, setFormData] = useState([]);
  const { mutate: onAddUserPermission, isLoading:loadingSave  } = useAddUserPermission(ConferenceData.id);
  const { data: allUsersData, isLoading: isAllUsersLoading } = useGetAllUsers();

  useEffect(() => {
    if (fields) {
      form.setFieldsValue(fields);
    }
    return () => {
      form.resetFields();
    };
  }, [fields]);

  const saveUserPermission = (values:AddUserPermissionDto) => {
    values.fkConferenceId = ConferenceData.id;
    values.fkUserGroupId = parseInt(values.fkUserGroupId.toString());
      onAddUserPermission(values, {
        onSuccess: (data) => {
          toast.success(
            <IntlMessages id="notification.successMessage" />
          );
          form.resetFields();
        },
        onError: (err) => {
          toast.error(
            <IntlMessages id="notification.errorMessage" />
          );
        },
      });
    
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            xs: { span: 24 },
            sm: { span: 6 },
          },
          wrapperCol: {
            xs: { span: 24 },
            sm: { span: 14 },
          },
        }
      : null;

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{
          layout: formLayout,
        }}
        fields={formData}
        onFinish={saveUserPermission}
        autoComplete="off"
      >
        <Form.Item
          name="fkUserId"
          label="User list"
          rules={[
            {
              required: true,
              message: "Please select User !",
            },
          ]}
        >
          <Select
            showSearch
            placeholder="Select User"
            optionFilterProp="children"
          >
            {!isAllUsersLoading
              ? allUsersData.map((data) => {
                  return (
                    <Option key={data.userId} value={data.userId}>
                      {data.userName}
                    </Option>
                  );
                })
              : null}
          </Select>
        </Form.Item>

        <Form.Item
          name="fkUserGroupId"
          label="Role list"
          rules={[
            {
              required: true,
              message: "Please select role !",
            },
          ]}
        >
          <Select
            placeholder="Select a role"
          >
            {userInfo.role === UserGroupEnum.Admin && (
            <Option value="5">
            <IntlMessages id="app.userRole.ConferenceEditor" />
          </Option>
            )}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button loading = {loadingSave} type="primary" htmlType="submit">
            save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PermissionAdd;
