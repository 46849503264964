import React, { useState } from "react";
import { Modal, Table, Button, Space, Form, Select, Input } from "antd";
import { PageSize } from "src/apis/helpers/configs/page-sizes";
import { useGetVolumes } from "src/hooks/services/journals/volume/useGetVolumes";
import { useDeleteVolumes } from "src/hooks/services/journals/volume/useDeleteVolumes";
import IssueList from "../issue";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useGetIssues } from "src/hooks/services/journals/issue/useGetIssues";
const { Column } = Table;
const { Option } = Select;
const confirm = Modal.confirm;
const List = (props) => {
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [selectedJournal, setSelectedJournal] = useState(props.journals[0]);
  const { data: volumesData, isLoading } = useGetIssues(
    page,
    selectedJournal.jid
  );
  const { mutate: onDeleteVolumes } = useDeleteVolumes(
    page,
    selectedJournal.jid
  );
  const [selectedModalData, setSelectedModalData] = useState(null);
  const [visibleIssue, setVisibleIssue] = useState(false);

  const changePage = (page) => {
    setPage(page);
  };

  const editVolumes = (data) => {
    props.changeTabEvent("2", data, page); // raftan be tab edit
  };

  const searchVolumes = (value) => {
    if (value) {
      const journal = props.journals.find(j=>j.jid === value);
      setSelectedJournal(journal);
    }
  };

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteVolumes(data.vid, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err: any) => {
            toast.error(err.message);
          },
        });
      },
      onCancel() {},
    });
  };

  const openModal = (data, type) => {
    setSelectedModalData(data);

    switch (type) {
      case "issue":
        setVisibleIssue(true);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <Form
        layout="inline"
        form={form}
        initialValues={{ layout: "inline", ["journalId"]: selectedJournal.jid }}
        autoComplete="off"
      >
        <Form.Item
          name="journalId"
          label="Journal"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            onChange={searchVolumes}
            placeholder="Select Journal "
            optionFilterProp="children"
          >
            {props.journals.map((data) => {
              return (
                <Option key={data.jid} value={data.jid}>
                  {data.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </Form>

      <Table
        className="gx-table-responsive"
        dataSource={volumesData?.items}
        pagination={{
          defaultPageSize: PageSize.tables,
          total: volumesData?.totalCount,
          onChange: changePage,
          showSizeChanger: false,
        }}
        rowKey="vid"
      >
        <Column
          title="Volume Number"
          dataIndex="volumeNumber"
          key="volumeNumber"
        />

        <Column
          title="Issue List"
          key="action"
          render={(text, record: any) => (
            <Space size="middle">
              {record.hasIssue ? (
                <a onClick={() => openModal(record, "issue")}>Issues</a>
              ) : (
                <span>-</span>
              )}
            </Space>
          )}
        />

        <Column
          title="Volume Title"
          dataIndex="volumeTitle"
          key="volumeTitle"
        />

        <Column title="Year" dataIndex="year" key="year" />

        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editVolumes(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
       {/* <NotificationContainer /> */}

      {/* issue list */}
      <Modal
        title={
          "Volume " +
          (selectedModalData ? selectedModalData.volumeNumber : "") +
          " , Issue List"
        }
        centered
        visible={visibleIssue}
        footer={null}
        onCancel={() => setVisibleIssue(false)}
        width={1000}
        destroyOnClose={true}
      >
        {visibleIssue ? (
          <IssueList
            volume={selectedModalData}
            journalsData={selectedJournal}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default List;
