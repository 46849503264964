import { useMutation, useQueryClient } from "react-query";
import { Api } from "src/apis/helpers/configs/api";
import { container } from "tsyringe";
import { useCallback } from "react"
import { NewsDto } from "src/data/models/newsDtos/newsDto.interface";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { BaseDataService } from "src/apis/services/baseData/baseDataService";
import { WebHomeDataDto } from "src/data/models/webHomeDataDtos/webHomeDataDto.interface";
import { webHomeDataCollectionDto } from "src/data/models/webHomeDataDtos/webHomeDataCollectionDto.interface";

export const useDeleteWebHomeCollection = () => {
  const baseDataService = container.resolve(BaseDataService);
  const queryClient = useQueryClient();

  const toggleDelete = useCallback(
    (targetId) => {
      let lastData = queryClient.getQueryData<webHomeDataCollectionDto[]>([Api.BaseData.getWebHomeCollections]);

      if (lastData === undefined) return

      lastData = lastData.filter(item => item.webHomeDataCollectionId !== targetId);

      queryClient.setQueryData([Api.BaseData.getWebHomeCollections], lastData)
    },
    [],
  )

  return useMutation({
    mutationFn: (id: number) => {
      return baseDataService.deleteWebHomeCollection(id);
    },
    onSuccess: (data, targetId, context) => {
      toggleDelete(targetId);
    }
  })
}
