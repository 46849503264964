import React from "react";
import { Divider, Table, Modal } from "antd";

import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useDeleteSubjects } from "src/hooks/services/subjects/useDeleteSubjects";
import { useGetSubjects } from "src/hooks/services/subjects/useGetSubjects";
const confirm = Modal.confirm;

const List = (props) => {
  const { data: subjectsData, isLoading } = useGetSubjects();

  const { mutate: onDeleteSubjects } = useDeleteSubjects();

  const columns = [
    {
      title: "name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <span onClick={() => editData(record)} className="gx-link">Edit</span>
          <Divider type="vertical" />
          <span onClick={() => showDeleteConfirm(record)} className="gx-link">
            Delete
          </span>
        </span>
      ),
    },
  ];

  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteSubjects(data.id, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
            
          },
          onError: (err:any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const editData = (data) => {

    props.changeTabEvent("2" ,data ); // raftan be tab edit

  }

  return (
    <>
      <Table
        className="gx-table-responsive"
        columns={columns}
        dataSource={subjectsData}
        rowKey="id"
      />
       {/* <NotificationContainer /> */}
    </>
  );
};

export default List;
