import React, { useState } from "react";
import { Switch, Table, Modal, Space, Avatar, Image } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import IntlMessages from "../../../../util/IntlMessages";
import { useGetMenu } from "src/hooks/services/contents/menu/useGetMenu";
import { useDeleteMenu } from "src/hooks/services/contents/menu/useDeleteMenu";

const confirm = Modal.confirm;
const { Column } = Table;
const MenuList = (props) => {

  const { data: menuData, isLoading } = useGetMenu(props.contentData.id,true);
  const { mutate: onDeleteContents } = useDeleteMenu(props.contentData.id,true);



  const showDeleteConfirm = (data) => {
    confirm({
      title: "Are you sure ?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        onDeleteContents(data.id, {
          onSuccess: (data) => {
            toast.success(
              <IntlMessages id="notification.successMessage" />
            );
          },
          onError: (err:any) => {
            toast.error(
              err.message
            );
          },
        });
      },
      onCancel() {},
    });
  };

  
  const editData = (data) => {

    props.changeTabEvent("2" ,data ); // raftan be tab edit

  }

  return (
    <>
      <Table
        className="gx-table-responsive"
        dataSource={menuData}
        rowKey="id"
      >

        <Column title="Title" dataIndex="menuTitle" key="menuTitle" />
        <Column title="Priority" dataIndex="priority" key="priority" />

        <Column
          title="Edit"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => editData(record)}>Edit</a>
            </Space>
          )}
        />
        <Column
          title="Delete"
          key="action"
          render={(text, record) => (
            <Space size="middle">
              <a onClick={() => showDeleteConfirm(record)}>Delete</a>
            </Space>
          )}
        />
      </Table>
    </>
  );
};

export default MenuList;
