import { IGenericResponse } from "src/apis/helpers/baseModels/IGenericResponse";
import { Api } from "src/apis/helpers/configs/api";
import { PaginatedList } from "src/data/models/paginatedList.interface";
import { PaginationParams } from "src/data/models/params/paginationParams.interface";
import { ChangePasswordDto } from "src/data/models/usersDtos/changePasswordDto.interface";
import { UsersDto } from "src/data/models/usersDtos/usersDto.interface";
import BaseRepository from "../Base/BaseRepository";
import IUsersRepository from "./IUsersRepository";

export default class UsersRepository extends BaseRepository implements IUsersRepository {

  async getUsers(params: PaginationParams): Promise<IGenericResponse<PaginatedList<UsersDto>>> {

    const result = this.CreateRequestBuilder()

    for (const [key, value] of Object.entries(params)) {
      result.addQueryParam(key, value)
    }

    const request = result
      .setMethod('get')
      .setUrl(Api.Users.getUsers)

    return await request
      .GetResultAsync();
  }

  async getAllUsers(): Promise<IGenericResponse<UsersDto[]>> {

    const result = this.CreateRequestBuilder()
    const request = result
      .setMethod('get')
      .setUrl(Api.Users.getAllUsers)

    return await request
      .GetResultAsync();
  }
  async addUsers(body: UsersDto): Promise<IGenericResponse<UsersDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('post')
      .setBody(body)
      .setUrl(Api.Users.addUsers)

    return await request
      .GetResultAsync()
  }
  async editUsers(body: UsersDto): Promise<IGenericResponse<UsersDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Users.editUsers)

    return await request
      .GetResultAsync();
  }

  async toggleUserStatus(userId): Promise<IGenericResponse<number>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(userId)
      .setUrl(Api.Users.toggleUserStatus)

    return await request
      .GetResultAsync();
  }

  async getProfile(): Promise<IGenericResponse<UsersDto>> {

    const result = this.CreateRequestBuilder()
    const request = result
      .setMethod('get')
      .setUrl(Api.Users.getProfile)

    return await request
      .GetResultAsync();
  }

  async editProfile(body: UsersDto): Promise<IGenericResponse<UsersDto>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Users.editProfile)

    return await request
      .GetResultAsync();
  }

  async changePassword(body: ChangePasswordDto): Promise<IGenericResponse<boolean>> {
    const request = this.CreateRequestBuilder()
      .setMethod('put')
      .setBody(body)
      .setUrl(Api.Users.changePassword)

    return await request
      .GetResultAsync();
  }
}
